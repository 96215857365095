import { createAction, handleActions } from 'redux-actions';
import { api } from "api";

const LOADER = 'LOADER_DASHBOARD';
const ITEM_DATA = 'ITEM_DASHBOARD';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});


// ------------------------------------
// Actions
// ------------------------------------

const listarGraph = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('dashboard_garage/reportDashboard').then((response) => {
        dispatch(setItem(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const actions = {
    listarGraph,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
};

export const initialState = {
    loader: false,
    item: {},
};

export default handleActions(reducers, initialState);
