import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm, change, destroy } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { ACTIVE_CLIENT } from '../../../utility/constants';

const LOADER = 'LOADER_CLIENTES';
const LOADER_FATHER = 'LOADER_CLIENTES_FATHER';
const DATA = 'DATA_CLIENTES';
const ITEM_DATA = 'ITEM_CLIENTES';
const PAGE = 'PAGE_CLIENTES';
const ORDERING = 'ORDERING_CLIENTES';
const SEARCH = 'SEARCH_CLIENTES';
const ESTADO_CLIENTE_TAB = 'ESTADO_CLIENTE_TAB';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderFather = loadingFather => ({
    type: LOADER_FATHER,
    loadingFather,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setEstadoClienteTab = estado_cliente_tab => ({
    type: ESTADO_CLIENTE_TAB,
    estado_cliente_tab,
});
// ------------------------------------
// Actions
// ------------------------------------
const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('client', data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(destroyForm());
        dispatch(push('/clients'));
    }).catch((error) => {
        let mensaje = 'Error en la creación, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'client'}/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('ClienteForm', response));
    }).catch(() => {
        dispatch(push('/'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().clientes;
    const params = { page, type_client: resource.estado_cliente_tab };

    if (resource.ordering)
        params.ordering = resource.ordering;
    if (resource.search)
        params.search = resource.search;

    dispatch(setLoader(true));
    api.get('client', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const estadoClienteTab = estado => (dispatch) => {
    dispatch(setEstadoClienteTab(estado));
    dispatch(listar());
};

const change_order_state = data => (dispatch) => {
    dispatch(setLoader(true));
    api.put('work_orders/change_order_state', data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
    }).catch((error) => {
        let mensaje = 'Error en la petición, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        dispatch(push('/'));
    });
}

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`client/${id}`, data)
        .then(() => {
            NotificationManager.success('Registro actualizado', 'Éxito', 3000);
            dispatch(leer(id))
            dispatch(push('/clients'));
        })
        .catch((error) => {
            let mensaje = 'Error en la edición, vuelve a intentarlo';
            if (error) {
                if (error.detail)
                    mensaje = error.detail;
            }
            NotificationManager.error(mensaje, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};


const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`client/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        let mensaje = 'Error en la eliminación, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const destroyForm = () => (dispatch) => {
    dispatch(destroy('ClienteForm'));
};

const addCarForm = (new_car) => (dispatch, getStore) => {
    const cars = new_car.cars ? _.cloneDeep(new_car.cars) : [];
    dispatch(change("ClienteForm", "cars", [...cars]));
};

const deleteCarForm = (id) => (dispatch, getStore) => {
    const FORM_PRINCIPAL = getStore().form.ClienteForm.values;
    let form_principal = FORM_PRINCIPAL ? _.cloneDeep(FORM_PRINCIPAL) : {};
    const const_cars = form_principal.cars || [];
    let new_cars = [];
    let new_id = 0
    for (let i = 0; i < const_cars.length; i++) {
        var item = const_cars[i];
        if (item.id != id) {
            item.id = new_id
            new_cars.push(item)
            new_id = new_id + 1
        }
    }
    dispatch(change("ClienteForm", "cars", new_cars));
};

const clearSelect = (id, select) => (dispatch, getStore) => {
    const FORM_PRINCIPAL = getStore().form.ClienteTallerForm.values;
    let form_principal = FORM_PRINCIPAL ? _.cloneDeep(FORM_PRINCIPAL) : {};
    const const_cars = form_principal.cars || [];
    let new_cars = [];
    for (let i = 0; i < const_cars.length; i++) {
        var item = const_cars[i];
        if (item.id != id) {
            new_cars.push(item)
        } else {
            if (select == 'model')
                delete item.model

            if (select == 'insurance_carrier')
                delete item.insurance_carrier

            new_cars.push(item)
        }
    }
    dispatch(change("ClienteTallerForm", "cars", new_cars));
}

const importExcel = (data = {}, closeModal = null) => (dispatch, getStore) => {
    dispatch(setLoaderFather(true));
    api.post("client/import_clients", data)
        .then((response) => {
            NotificationManager.success(
                "Clientes registrado correctamente.",
                "Éxito",
                3000
            );
            closeModal && closeModal()
            dispatch(listar());
        })
        .catch((error) => {
            let msj = "Error al intentar leer el archivo excel, verifique que llene todos los campos obligatorios.";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 6000);
        })
        .finally(() => {
            dispatch(setLoaderFather(false));
        });
};

const searchChange = search => (dispatch, getStore) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};



export const actions = {
    crear,
    listar,
    leer,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    destroyForm,
    estadoClienteTab,
    change_order_state,
    addCarForm,
    deleteCarForm,
    clearSelect,
    importExcel,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [ESTADO_CLIENTE_TAB]: (state, { estado_cliente_tab }) => {
        return {
            ...state,
            estado_cliente_tab,
        };
    },
    [LOADER_FATHER]: (state, { loadingFather }) => {
        return {
            ...state,
            loadingFather,
        };
    },
};

export const initialState = {
    loader: false,
    loadingFather: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    estado_cliente_tab: ACTIVE_CLIENT,
};

export default handleActions(reducers, initialState);
