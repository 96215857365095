import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import profile from './modules/cuenta/profile';
import usuarios from './modules/usuarios';
import notificaciones from './modules/notificaciones/notificaciones';
import roles from './modules/roles'
import planes from './modules/planes';
import dashboard from './modules/dashboard';
import notificacionesVencidas from './modules/notificacionesVencidas/notificacionesVencidas';
// * * * * * * * * * * * * * * *
import clientes from './modules/clientes'
import aseguradoras from './modules/aseguradoras';
import carros from './modules/carros';
import configuraciones from './modules/configuraciones';
import sistemasCarro from './modules/sistemasCarro'
import sucursales from './modules/sucursales';
import adminMecanicas from './modules/ordenesTrabajoAdmin/mecanica'
import adminAvaluos from './modules/ordenesTrabajoAdmin/avaluo'
import adminEnderezadosPinturas from './modules/ordenesTrabajoAdmin/enderezadoPintura'

import operadorMecanicas from './modules/ordenesTrabajoOperador/mecanica'
import operadorAvaluos from './modules/ordenesTrabajoOperador/avaluo'
import operadorEnderezadosPinturas from './modules/ordenesTrabajoOperador/enderezadoPintura'
import operadorMisOrdenes from './modules/ordenesTrabajoOperador/misOrdenes'
import dashboardWorkOrders from './modules/ordenesTrabajoAdmin'
import ordenesIncompletas from './modules/ordenesIncompletas';

import cotizaciones from './modules/cotizaciones';
import colaboradores from './modules/colaboradores';
import colaboradorOrdenes from './modules/colaboradorOrdenes';

import bitacora from './modules/bitacora';

export default combineReducers({
    form: formReducer,
    login,
    register,
    profile,
    usuarios,
    routing,
    notificaciones,
    roles,
    dashboard,
    // * * * * * * * * * * * * * * *
    planes,
    clientes,
    aseguradoras,
    carros,
    configuraciones,
    sistemasCarro,
    sucursales,

    adminMecanicas,
    adminAvaluos,
    adminEnderezadosPinturas,

    operadorMecanicas,
    operadorAvaluos,
    operadorEnderezadosPinturas,
    operadorMisOrdenes,
    dashboardWorkOrders,
    ordenesIncompletas,

    cotizaciones,
    colaboradores,
    colaboradorOrdenes,
    notificacionesVencidas,

    bitacora,
});
