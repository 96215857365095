import { object } from 'prop-types';
import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import { array } from 'validate-redux-form';
import Formulario from './Formulario';

export default class Crear extends Component {
    state = {
        editar: false,
    };

    componentWillMount() {
        const { id = 0, idClient = 0 } = this.props.match.params
        const { leer, leer_cliente, datos_cliente } = this.props;
        if (id) {
            leer(id, idClient);
            this.setState({ editar: true });
        }
        if (Object.keys(datos_cliente.item).length === 0) {
            leer_cliente(idClient)
        }
    }


    update = (_data) => {
        let data = JSON.parse(JSON.stringify(_data));
        data.client = this.props.match.params.idClient
        this.props.editar(this.props.match.params.id, data)
    };
    create = (_data) => {
        let data = JSON.parse(JSON.stringify(_data));
        data.client = this.props.match.params.idClient
        this.props.crear(data);
    };

    render() {
        //Value
        const { loader, item, datos_cliente } = this.props;
        const { editar } = this.state
        const rutaEditar = `/client/${this.props.match.params.idClient}/cars`

        return (
            <CardForm titulo={editar ? "EDITAR VEHÍCULO" : "NUEVA VEHÍCULO"}>
                <LoadMask loading={loader} blur>
                    {
                        editar ?
                            <Formulario
                                onSubmit={this.update}
                                editando
                                backUrl={rutaEditar}
                                nombre_cliente={datos_cliente.item.name}
                                item={item}
                            />
                            :
                            <Formulario
                                onSubmit={this.create}
                                backUrl={rutaEditar}
                                nombre_cliente={datos_cliente.item.name}
                            />
                    }
                </LoadMask>
            </CardForm>
        )
    }
}
