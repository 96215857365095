import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Card from '../../../Utils/Cards/Card'
import { HeaderBtnSearch, HeaderSearch } from '../../../Utils/Header'
import { ROLE_MANAGEMENT_GARAGE } from '../../../../../utility/constants';
import { ButtonSalir } from '../../../Utils/Button';



export default class Listar extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page, me } = this.props; //Constantes
        return (
            <Card
                titulo="Vehiculos con ordenes incompletas"
                header={
                    <HeaderSearch {...this.props} />
                }
            >
                <Grid hover striped data={data} loading={loader} onPageChange={listar} onSortChange={onSortChange} page={page}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width='10%'
                        dataFormat={standardActions({ incomplete_order: true })}
                    />
                    <TableHeaderColumn
                        dataField="car_registration"
                    >
                        PLACA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="vin"
                    >
                        VIN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="brand"
                        dataFormat={(cell) => {
                            if (cell) {
                                return cell.name
                            }

                        }}
                    >
                        MARCA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="client"
                        dataFormat={(cell) => {
                            if (cell) {
                                return cell.name
                            }

                        }}
                    >
                        NOMBRE PROPIETARIO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="client"
                        dataFormat={(cell) => {
                            if (cell) {
                                return cell.phone_number
                            }

                        }}
                    >
                        TELÉFONO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="client"
                        dataFormat={(cell) => {
                            if (cell) {
                                return cell.email
                            }

                        }}
                    >
                        EMAIL
                    </TableHeaderColumn>
                </Grid>
            </Card>
        )
    }
}
