import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';

const LoginForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form
            name="loginForm"
            className="form-validate mb-lg text-center"
            onSubmit={handleSubmit}
        >
            <div className="form-group has-feedback">
                <label className="bold" htmlFor="username">USUARIO</label>
                <Field
                    name="username"
                    label="Usuario"
                    component={renderField}
                    type="text"
                    className="form-control"
                />
            </div>
            <div className="form-group has-feedback">
                <label className="bold" htmlFor="password">CONTRASEÑA</label>
                <Field
                    name="password"
                    label="Contraseña"
                    component={renderField}
                    type="password"
                    className="form-control"
                />
            </div>
            <div className="buttons-box mt-4 mb-5">
                <button
                    type="submit"
                    className="btn btn-primary text-18 btn-block m-1 align-self-center"
                >
                    INGRESAR
                </button>
            </div>
            <span className="text-14 bold">¿Olvide mi contraseña?&nbsp;
                <Link className="link text-14 bold" to="/forgot-password">RECUPERAR</Link>
            </span>
            <div className="mt-2">
                <span className="text-14 bold">¿No tengo cuenta?&nbsp;
                    <Link className="link text-14 bold" to="/suscriptions">REGISTRARME</Link>
                </span>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'login', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            username: validators.exists()('Este campo es requerido'),
            password: validators.exists()('Este campo es requerido'),
        });
    },
})(LoginForm);
