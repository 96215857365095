export const COUNTRIES = [
    { "name": "Andorra", "id": "AD" },
    { "name": "Emiratos Árabes Unidos", "id": "AE" },
    { "name": "Afganistán", "id": "AF" },
    { "name": "Antigua y Barbuda", "id": "AG" },
    { "name": "Anguila", "id": "AI" },
    { "name": "Albania", "id": "AL" },
    { "name": "Armenia", "id": "AM" },
    { "name": "Antillas Neerlandesas", "id": "AN" },
    { "name": "Angola", "id": "AO" },
    { "name": "Antártida", "id": "AQ" },
    { "name": "Argentina", "id": "AR" },
    { "name": "Samoa Americana", "id": "AS" },
    { "name": "Austria", "id": "AT" },
    { "name": "Australia", "id": "AU" },
    { "name": "Aruba", "id": "AW" },
    { "name": "Islas Áland", "id": "AX" },
    { "name": "Azerbaiyán", "id": "AZ" },
    { "name": "Bosnia y Herzegovina", "id": "BA" },
    { "name": "Barbados", "id": "BB" },
    { "name": "Bangladesh", "id": "BD" },
    { "name": "Bélgica", "id": "BE" },
    { "name": "Burkina Faso", "id": "BF" },
    { "name": "Bulgaria", "id": "BG" },
    { "name": "Bahréin", "id": "BH" },
    { "name": "Burundi", "id": "BI" },
    { "name": "Benin", "id": "BJ" },
    { "name": "San Bartolomé", "id": "BL" },
    { "name": "Bermudas", "id": "BM" },
    { "name": "Brunéi", "id": "BN" },
    { "name": "Bolivia", "id": "BO" },
    { "name": "Brasil", "id": "BR" },
    { "name": "Bahamas", "id": "BS" },
    { "name": "Bhután", "id": "BT" },
    { "name": "Isla Bouvet", "id": "BV" },
    { "name": "Botsuana", "id": "BW" },
    { "name": "Belarús", "id": "BY" },
    { "name": "Belice", "id": "BZ" },
    { "name": "Canadá", "id": "CA" },
    { "name": "Islas Cocos", "id": "CC" },
    { "name": "República Centro-Africana", "id": "CF" },
    { "name": "Congo", "id": "CG" },
    { "name": "Suiza", "id": "CH" },
    { "name": "Costa de Marfil", "id": "CI" },
    { "name": "Islas Cook", "id": "CK" },
    { "name": "Chile", "id": "CL" },
    { "name": "Camerún", "id": "CM" },
    { "name": "China", "id": "CN" },
    { "name": "Colombia", "id": "CO" },
    { "name": "Costa Rica", "id": "CR" },
    { "name": "Cuba", "id": "CU" },
    { "name": "Cabo Verde", "id": "CV" },
    { "name": "Islas Christmas", "id": "CX" },
    { "name": "Chipre", "id": "CY" },
    { "name": "República Checa", "id": "CZ" },
    { "name": "Alemania", "id": "DE" },
    { "name": "Yibuti", "id": "DJ" },
    { "name": "Dinamarca", "id": "DK" },
    { "name": "Domínica", "id": "DM" },
    { "name": "República Dominicana", "id": "DO" },
    { "name": "Argel", "id": "DZ" },
    { "name": "Ecuador", "id": "EC" },
    { "name": "Estonia", "id": "EE" },
    { "name": "Egipto", "id": "EG" },
    { "name": "Sahara Occidental", "id": "EH" },
    { "name": "Eritrea", "id": "ER" },
    { "name": "España", "id": "ES" },
    { "name": "Etiopía", "id": "ET" },
    { "name": "Finlandia", "id": "FI" },
    { "name": "Fiji", "id": "FJ" },
    { "name": "Islas Malvinas", "id": "FK" },
    { "name": "Micronesia", "id": "FM" },
    { "name": "Islas Faroe", "id": "FO" },
    { "name": "Francia", "id": "FR" },
    { "name": "Gabón", "id": "GA" },
    { "name": "Reino Unido", "id": "GB" },
    { "name": "Granada", "id": "GD" },
    { "name": "Georgia", "id": "GE" },
    { "name": "Guayana Francesa", "id": "GF" },
    { "name": "Guernsey", "id": "GG" },
    { "name": "Ghana", "id": "GH" },
    { "name": "Gibraltar", "id": "GI" },
    { "name": "Groenlandia", "id": "GL" },
    { "name": "Gambia", "id": "GM" },
    { "name": "Guinea", "id": "GN" },
    { "name": "Guadalupe", "id": "GP" },
    { "name": "Guinea Ecuatorial", "id": "GQ" },
    { "name": "Grecia", "id": "GR" },
    { "name": "Georgia del Sur e Islas Sandwich del Sur", "id": "GS" },
    { "name": "Guatemala", "id": "GT" },
    { "name": "Guam", "id": "GU" },
    { "name": "Guinea-Bissau", "id": "GW" },
    { "name": "Guayana", "id": "GY" },
    { "name": "Hong Kong", "id": "HK" },
    { "name": "Islas Heard y McDonald", "id": "HM" },
    { "name": "Honduras", "id": "HN" },
    { "name": "Croacia", "id": "HR" },
    { "name": "Haití", "id": "HT" },
    { "name": "Hungría", "id": "HU" },
    { "name": "Indonesia", "id": "ID" },
    { "name": "Irlanda", "id": "IE" },
    { "name": "Israel", "id": "IL" },
    { "name": "Isla de Man", "id": "IM" },
    { "name": "India", "id": "IN" },
    { "name": "Territorio Británico del Océano Índico", "id": "IO" },
    { "name": "Irak", "id": "IQ" },
    { "name": "Irán", "id": "IR" },
    { "name": "Islandia", "id": "IS" },
    { "name": "Italia", "id": "IT" },
    { "name": "Jersey", "id": "JE" },
    { "name": "Jamaica", "id": "JM" },
    { "name": "Jordania", "id": "JO" },
    { "name": "Japón", "id": "JP" },
    { "name": "Kenia", "id": "KE" },
    { "name": "Kirguistán", "id": "KG" },
    { "name": "Camboya", "id": "KH" },
    { "name": "Kiribati", "id": "KI" },
    { "name": "Comoros", "id": "KM" },
    { "name": "San Cristóbal y Nieves", "id": "KN" },
    { "name": "Corea del Norte", "id": "KP" },
    { "name": "Corea del Sur", "id": "KR" },
    { "name": "Kuwait", "id": "KW" },
    { "name": "Islas Caimán", "id": "KY" },
    { "name": "Kazajstán", "id": "KZ" },
    { "name": "Laos", "id": "LA" },
    { "name": "Líbano", "id": "LB" },
    { "name": "Santa Lucía", "id": "LC" },
    { "name": "Liechtenstein", "id": "LI" },
    { "name": "Sri Lanka", "id": "LK" },
    { "name": "Liberia", "id": "LR" },
    { "name": "Lesotho", "id": "LS" },
    { "name": "Lituania", "id": "LT" },
    { "name": "Luxemburgo", "id": "LU" },
    { "name": "Letonia", "id": "LV" },
    { "name": "Libia", "id": "LY" },
    { "name": "Marruecos", "id": "MA" },
    { "name": "Mónaco", "id": "MC" },
    { "name": "Moldova", "id": "MD" },
    { "name": "Montenegro", "id": "ME" },
    { "name": "Madagascar", "id": "MG" },
    { "name": "Islas Marshall", "id": "MH" },
    { "name": "Macedonia", "id": "MK" },
    { "name": "Mali", "id": "ML" },
    { "name": "Myanmar", "id": "MM" },
    { "name": "Mongolia", "id": "MN" },
    { "name": "Macao", "id": "MO" },
    { "name": "Martinica", "id": "MQ" },
    { "name": "Mauritania", "id": "MR" },
    { "name": "Montserrat", "id": "MS" },
    { "name": "Malta", "id": "MT" },
    { "name": "Mauricio", "id": "MU" },
    { "name": "Maldivas", "id": "MV" },
    { "name": "Malawi", "id": "MW" },
    { "name": "México", "id": "MX" },
    { "name": "Malasia", "id": "MY" },
    { "name": "Mozambique", "id": "MZ" },
    { "name": "Namibia", "id": "NA" },
    { "name": "Nueva Caledonia", "id": "NC" },
    { "name": "Níger", "id": "NE" },
    { "name": "Islas Norkfolk", "id": "NF" },
    { "name": "Nigeria", "id": "NG" },
    { "name": "Nicaragua", "id": "NI" },
    { "name": "Países Bajos", "id": "NL" },
    { "name": "Noruega", "id": "NO" },
    { "name": "Nepal", "id": "NP" },
    { "name": "Nauru", "id": "NR" },
    { "name": "Niue", "id": "NU" },
    { "name": "Nueva Zelanda", "id": "NZ" },
    { "name": "Omán", "id": "OM" },
    { "name": "Panamá", "id": "PA" },
    { "name": "Perú", "id": "PE" },
    { "name": "Polinesia Francesa", "id": "PF" },
    { "name": "Papúa Nueva Guinea", "id": "PG" },
    { "name": "Filipinas", "id": "PH" },
    { "name": "Pakistán", "id": "PK" },
    { "name": "Polonia", "id": "PL" },
    { "name": "San Pedro y Miquelón", "id": "PM" },
    { "name": "Islas Pitcairn", "id": "PN" },
    { "name": "Puerto Rico", "id": "PR" },
    { "name": "Palestina", "id": "PS" },
    { "name": "Portugal", "id": "PT" },
    { "name": "Islas Palaos", "id": "PW" },
    { "name": "Paraguay", "id": "PY" },
    { "name": "Qatar", "id": "QA" },
    { "name": "Reunión", "id": "RE" },
    { "name": "Rumanía", "id": "RO" },
    { "name": "Serbia y Montenegro", "id": "RS" },
    { "name": "Rusia", "id": "RU" },
    { "name": "Ruanda", "id": "RW" },
    { "name": "Arabia Saudita", "id": "SA" },
    { "name": "Islas Solomón", "id": "SB" },
    { "name": "Seychelles", "id": "SC" },
    { "name": "Sudán", "id": "SD" },
    { "name": "Suecia", "id": "SE" },
    { "name": "Singapur", "id": "SG" },
    { "name": "Santa Elena", "id": "SH" },
    { "name": "Eslovenia", "id": "SI" },
    { "name": "Islas Svalbard y Jan Mayen", "id": "SJ" },
    { "name": "Eslovaquia", "id": "SK" },
    { "name": "Sierra Leona", "id": "SL" },
    { "name": "San Marino", "id": "SM" },
    { "name": "Senegal", "id": "SN" },
    { "name": "Somalia", "id": "SO" },
    { "name": "Surinam", "id": "SR" },
    { "name": "Santo Tomé y Príncipe", "id": "ST" },
    { "name": "El Salvador", "id": "SV" },
    { "name": "Siria", "id": "SY" },
    { "name": "Suazilandia", "id": "SZ" },
    { "name": "Islas Turcas y Caicos", "id": "TC" },
    { "name": "Chad", "id": "TD" },
    { "name": "Territorios Australes Franceses", "id": "TF" },
    { "name": "Togo", "id": "TG" },
    { "name": "Tailandia", "id": "TH" },
    { "name": "Tanzania", "id": "TZ" },
    { "name": "Tayikistán", "id": "TJ" },
    { "name": "Tokelau", "id": "TK" },
    { "name": "Timor-Leste", "id": "TL" },
    { "name": "Turkmenistán", "id": "TM" },
    { "name": "Túnez", "id": "TN" },
    { "name": "Tonga", "id": "TO" },
    { "name": "Turquía", "id": "TR" },
    { "name": "Trinidad y Tobago", "id": "TT" },
    { "name": "Tuvalu", "id": "TV" },
    { "name": "Taiwán", "id": "TW" },
    { "name": "Ucrania", "id": "UA" },
    { "name": "Uganda", "id": "UG" },
    { "name": "Estados Unidos de América", "id": "US" },
    { "name": "Uruguay", "id": "UY" },
    { "name": "Uzbekistán", "id": "UZ" },
    { "name": "Ciudad del Vaticano", "id": "VA" },
    { "name": "San Vicente y las Granadinas", "id": "VC" },
    { "name": "Venezuela", "id": "VE" },
    { "name": "Islas Vírgenes Británicas", "id": "VG" },
    { "name": "Islas Vírgenes de los Estados Unidos de América", "id": "VI" },
    { "name": "Vietnam", "id": "VN" },
    { "name": "Vanuatu", "id": "VU" },
    { "name": "Wallis y Futuna", "id": "WF" },
    { "name": "Samoa", "id": "WS" },
    { "name": "Yemen", "id": "YE" },
    { "name": "Mayotte", "id": "YT" },
    { "name": "Sudáfrica", "id": "ZA" },
];
