import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/ordenesTrabajoAdmin/enderezadoPintura';
import Listar from './Tabs';


const ms2p = (state) => {
    return {
        ...state.adminEnderezadosPinturas,
        form_factura: state.form.WorkOrdersForm,
        tax_percentage_garage: state.login.me.tax_percentage_garage
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Listar);
