import React, { Component, Fragment } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Card from '../../../Utils/Cards/Card'
import { HeaderBtnSearch } from '../../../Utils/Header'
export default class Listar extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { onSortChange, listar } = this.props; //Funciones
        const { data, loader, page = false } = this.props; //Constantes
        return (
            <Fragment>
                <Card
                    sinFondo
                    header={
                        <HeaderBtnSearch
                            link="garage_branches/create"
                            textBtn="Crear sucursal"
                            {...this.props}
                        />
                    }
                >
                    <Grid hover striped data={data} loading={loader} onPageChange={listar} onSortChange={onSortChange} page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            width='150PX'
                            dataFormat={(cell, row) => row.approved ? standardActions({ editar: "garage_branches" })(cell, row) : <span className="rojo bold">Sin aprobar</span>}
                        />
                        <TableHeaderColumn
                            dataField="name"
                            dataSort
                        >
                            SUCURSAL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="address"
                        >
                            DIRECCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="phone_number"
                            dataSort
                        >
                            TELÉFONO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="receipt_info"
                            dataFormat={(cell) => cell ? `${cell.name}` : ""}
                        >
                            ENCARGADO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </Fragment>
        )
    }
}
