import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/carros';
import { actions as actions_cliente } from '../../../../../redux/modules/clientes';

import Listar from './Listar';


const ms2p = (state) => {
    return {
        ...state.carros,
        datos_cliente: { ...state.clientes }
    };
};

const md2p = {
    ...actions,
    leer_cliente: actions_cliente.leer
};

export default connect(ms2p, md2p)(Listar);
