import React, { Component } from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import SendRecovery from './SendRecovery';
import LoadMask from "Utils/LoadMask/LoadMask";
const logo = require('assets/img/logo.png');

class ForgotPassword extends Component {
    render() {
        const { sendRecover, loader } = this.props;
        let correo_enviado = "";
        if (this.props.match && this.props.match.params && this.props.match.params.email) {
            correo_enviado = this.props.match.params.email;
        }
        return (
            <div className="blue-gradient-bg d-flex align-items-center">
                <div className="login-wrapper flex-1 px-sm-5 px-md-0">
                    <div className="card-login ml-md-auto">
                        <div className="d-flex justify-content-center flex-column align-items-center">
                            <img src={logo} alt="Alerta" height={50} className="img-fluid" />
                        </div>
                        <div className="mx-5 my-5" style={{ borderTop: "solid 2px #323232" }} />
                        <LoadMask loading={loader} light>
                            {correo_enviado ?
                                <SendRecovery correo={correo_enviado} />
                                :
                                <ForgotPasswordForm onSubmit={sendRecover} />

                            }
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;



