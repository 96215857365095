import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Card from '../../../Utils/Cards/Card'
import { HeaderBtnSearch } from '../../../Utils/Header'
const enderezado_y_pintura_svg = require("assets/img/orden_trabajo/enderezado_y_pintura.svg");
const evaluo_svg = require("assets/img/orden_trabajo/evaluo.svg");
const mecanica_svg = require("assets/img/orden_trabajo/mecanica.svg");

export default class Home extends Component {
    componentWillMount() {
        const { getDashboard } = this.props;
        getDashboard();
    }

    render() {
        const { data, loader, me = {} } = this.props;
        const { permissions = [] } = me || {}
        const mecanica = [
            {
                "name": "No asignadas:",
                "count": data.status_work_orders_mechanic.not_assigned
            },
            {
                "name": "Diagnostico:",
                "count": data.status_work_orders_mechanic.diagnostic
            },
            {
                "name": "Por cotizar:",
                "count": data.status_work_orders_mechanic.quotation
            },
            {
                "name": "En progreso:",
                "count": data.status_work_orders_mechanic.in_progress
            },
            {
                "name": "Control de calidad:",
                "count": data.status_work_orders_mechanic.quality_control
            },
            {
                "name": "Por facturar:",
                "count": data.status_work_orders_mechanic.to_check_in
            },
            {
                "name": "Finalizadas:",
                "count": data.status_work_orders_mechanic.finalized
            }
        ]
        const enderezado = [
            {
                "name": "No asignadas:",
                "count": data.status_work_orders_body_shop.not_assigned
            },
            {
                "name": "Diagnostico",
                "count": data.status_work_orders_body_shop.diagnostic
            },
            {
                "name": "Por cotizar",
                "count": data.status_work_orders_body_shop.quotation
            },
            {
                "name": "Desarmado",
                "count": data.status_work_orders_body_shop.disarmed
            },
            {
                "name": "Enderezado",
                "count": data.status_work_orders_body_shop.straightened
            },
            {
                "name": "Alistado",
                "count": data.status_work_orders_body_shop.enlisted
            },
            {
                "name": "Pintura",
                "count": data.status_work_orders_body_shop.painting
            },
            {
                "name": "Pulido",
                "count": data.status_work_orders_body_shop.polished
            },
            {
                "name": "Armado",
                "count": data.status_work_orders_body_shop.armed
            },
            {
                "name": "Control de calidad",
                "count": data.status_work_orders_body_shop.quality_control
            },
            {
                "name": "Lavado del vehículo",
                "count": data.status_work_orders_body_shop.car_wash
            },
            {
                "name": "Por facturar",
                "count": data.status_work_orders_body_shop.to_check_in
            },
            {
                "name": "Finalizados",
                "count": data.status_work_orders_body_shop.finalized
            },
        ]
        const evaluo = [
            {
                "name": "No asignadas:",
                "count": data.status_work_orders_appraisal.not_assigned
            },
            {
                "name": "En proceso:",
                "count": data.status_work_orders_appraisal.in_proggres
            },
            {
                "name": "Por facturar:",
                "count": data.status_work_orders_appraisal.to_check_in
            },
            {
                "name": "Finalizadas:",
                "count": data.status_work_orders_appraisal.finalized
            }
        ]
        return (
            <Card
                sinFondo
                titulo="Ordenes de trabajo"
                header={
                    <HeaderBtnSearch
                        link="work-order/select"
                        textBtn="Crear orden"
                    />
                }
            >
                <div className="w-100 d-flex justify-content-around flex-column flex-lg-row">
                    {permissions.includes('work_order_management_mechanic') &&
                        <div className="px-0 d-flex flex-1 mx-0 mx-sm-4 mx-md-4 flex-column" style={{ maxWidth: 450 }} >
                            <span className="text-25 negro">MECANICAS</span>

                            <div className="px-4 d-flex bg-blanco my-3 py-3" style={{ boxShadow: "0px 0px 7px #00000066", borderRadius: 15 }}>
                                <img src={mecanica_svg} style={{ width: 45 }} className="my-auto" alt="" />
                                <span className="text-18 negro mt-auto my-auto px-3">Ordenes activas: </span>
                                <span className="text-45 bold ml-auto">{data.total_work_orders_mechanic}</span>
                            </div>

                            <div className="px-4 d-flex bg-blanco flex-column my-3 py-3" style={{ boxShadow: "0px 0px 7px #00000066", borderRadius: 15 }}>
                                <div className="d-flex">
                                    <img src={mecanica_svg} style={{ width: 45 }} className="mt-3 mb-auto" alt="" />
                                    <div className="d-flex flex-column px-3 w-100" style={{ maxWidth: 300 }}>
                                        {mecanica.map((item, index) => (
                                            <div className="d-flex align-items-center" key={index}>
                                                <span className="text-18 negro flex-1">{item.name}</span>
                                                <span className="ml-4 text-25 negro">{item.count}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="mx-auto my-4 ">
                                    <Link to="/mechanic_orders" className={`btn btn-primary text-18 bold${true !== undefined ? "btn-flex3" : "btn-flex2"}`}>Ver ordenes</Link>
                                </div>
                            </div>
                        </div>
                    }
                    {permissions.includes('work_order_management_body_shop') &&
                        <div className="px-0 d-flex flex-1 mx-0 mx-sm-4 mx-md-4 flex-column" style={{ maxWidth: 450 }} >
                            <span className="text-25 negro">ENDEREZADO Y PINTURA</span>

                            <div className="px-4 d-flex bg-blanco my-3 py-3" style={{ boxShadow: "0px 0px 7px #00000066", borderRadius: 15 }}>
                                <img src={enderezado_y_pintura_svg} style={{ width: 45 }} className="my-auto" alt="" />
                                <span className="text-18 negro mt-auto my-auto px-3">Ordenes activas: </span>
                                <span className="text-45 bold ml-auto">{data.total_work_orders_body_shop}</span>
                            </div>

                            <div className="px-4 d-flex bg-blanco flex-column my-3 py-3" style={{ boxShadow: "0px 0px 7px #00000066", borderRadius: 15 }}>
                                <div className="d-flex">
                                    <img src={enderezado_y_pintura_svg} style={{ width: 45 }} className="mt-3 mb-auto" alt="" />
                                    <div className="d-flex flex-column px-3 w-100" style={{ maxWidth: 300 }}>
                                        {enderezado.map((item, index) => (
                                            <div className="d-flex align-items-center" key={index}>
                                                <span className="text-18 negro flex-1">{item.name}</span>
                                                <span className="ml-4 text-25 negro">{item.count}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="mx-auto my-4 ">
                                    <Link to="/body-shop-orders" className={`btn btn-primary text-18 bold${true !== undefined ? "btn-flex3" : "btn-flex2"}`}>Ver ordenes</Link>
                                </div>
                            </div>
                        </div>
                    }
                    {permissions.includes('work_order_management_appraisal') &&
                        <div className="px-0 d-flex flex-1 mx-0 mx-sm-4 mx-md-4 flex-column" style={{ maxWidth: 450 }} >
                            <span className="text-25 negro">AVALUO</span>

                            <div className="px-4 d-flex bg-blanco my-3 py-3" style={{ boxShadow: "0px 0px 7px #00000066", borderRadius: 15 }}>
                                <img src={evaluo_svg} style={{ width: 45 }} className="my-auto" alt="" />
                                <span className="text-18 negro mt-auto my-auto px-3">Ordenes activas: </span>
                                <span className="text-45 bold ml-auto">{data.total_work_orders_appraisal}</span>
                            </div>

                            <div className="px-4 d-flex bg-blanco flex-column my-3 py-3" style={{ boxShadow: "0px 0px 7px #00000066", borderRadius: 15 }}>
                                <div className="d-flex">
                                    <img src={evaluo_svg} style={{ width: 45 }} className="mt-3 mb-auto" alt="" />
                                    <div className="d-flex flex-column px-3 w-100" style={{ maxWidth: 300 }}>
                                        {evaluo.map((item, index) => (
                                            <div className="d-flex align-items-center" key={index}>
                                                <span className="text-18 negro flex-1">{item.name}</span>
                                                <span className="ml-4 text-25 negro">{item.count}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="mx-auto my-4 ">
                                    <Link to="/appraisal-orders" className={`btn btn-primary text-18 bold${true !== undefined ? "btn-flex3" : "btn-flex2"}`}>Ver ordenes</Link>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Card>
        )
    }
}
