import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    validate,
} from 'validate-redux-form';
import { renderNumber, renderField, AsyncSelectField2, SelectField2, SelectField, renderFieldCheck } from '../../../Utils/renderField';
import { ButtonSalirGuardar } from '../../../Utils/Button'
import { validationCar } from '../../../../../utility/validationForm'
import { connect } from 'react-redux'
import { TRANSMISSIONS } from '../../../../../utility/constants';
import { api } from "api";

const getAseguradoras = (search) => {
    return api.get("insurance_carrier", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};
const getCarType = (search) => {
    return api.get("car_type", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getBrand = (search) => {
    return api.get("brand_and_model", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

let ModalCardForm = (props) => {
    const { handleSubmit, showModal, indexArray, clearSelect, aseguradora_activo } = props;
    const [models, setModels] = useState([])
    useEffect(() => {
        const { cars = false } = props.initialValues
        if (cars) {
            const result = cars[indexArray]
            if (result.brand) {
                setModels(result.brand.models)
            }
        }
    }, [])
    return (
        <form onSubmit={handleSubmit} >
            <div className="row card-form">
                <div className="col-6 padding-custom mb-3">
                    <label htmlFor="brand">Marca*</label>
                    <Field
                        name={`cars.${indexArray}.brand`}
                        component={AsyncSelectField2}
                        loadOptions={getBrand}
                        placeholder="Marca"
                        onCambio={(e) => {
                            setModels(e.models)
                            clearSelect(indexArray, 'model')
                        }}
                    />
                </div>
                <div className="col-6 padding-custom mb-3">
                    <label htmlFor="model">Modelo*</label>
                    <Field
                        name={`cars.${indexArray}.model`}
                        options={models}
                        component={SelectField2}
                        placeholder="Modelo"
                    />
                </div>
                <div className="col-6 padding-custom mb-3">
                    <label htmlFor="car_type">Tipo*</label>
                    <Field
                        name={`cars.${indexArray}.car_type`}
                        component={AsyncSelectField2}
                        loadOptions={getCarType}
                        placeholder="Tipo"
                    />
                </div>
                <div className="col-6 padding-custom mb-3">
                    <label htmlFor="transmission">Transmisión*</label>
                    <Field
                        name={`cars.${indexArray}.transmission`}
                        options={TRANSMISSIONS}
                        component={SelectField}
                        placeholder="Transmisión"
                    />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="vin">VIN*</label>
                    <Field name={`cars.${indexArray}.vin`} component={renderField} type="text" className="form-control" maxLength={validationCar.vin.MaxLength} />
                </div>
                <div className="col-6 padding-custom mb-3">
                    <label htmlFor="color">Color*</label>
                    <Field name={`cars.${indexArray}.color`} component={renderField} type="text" className="form-control" maxLength={validationCar.color.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="car_registration">Matricula*</label>
                    <Field name={`cars.${indexArray}.car_registration`} component={renderField} type="text" className="form-control" maxLength={validationCar.car_registration.MaxLength} />
                </div>
                <div className="col-6 padding-custom mb-3">
                    <label htmlFor="year">Año*</label>
                    <Field name={`cars.${indexArray}.year`} component={renderNumber} className="form-control" maxLength={validationCar.year.MaxLength} />
                </div>
                <div className="col-6 padding-custom mb-3">
                    <label htmlFor="insured_car">¿Asegurado?</label>
                    <Field
                        name={`cars.${indexArray}.insured_car`}
                        // label="Default"
                        onCambio={(e) => {
                            if (!e)
                                clearSelect(indexArray, 'insurance_carrier')
                        }}
                        component={renderFieldCheck}
                    />
                </div>
                {aseguradora_activo &&
                    <div className="col-6 padding-custom mb-3">
                        <label htmlFor="insurance_carrier">Aseguradora</label>
                        <Field
                            isClearable
                            name={`cars.${indexArray}.insurance_carrier`}
                            component={AsyncSelectField2}
                            loadOptions={getAseguradoras}
                            placeholder="No definida"
                        />
                    </div>
                }
                <div className="col-12 padding-custom mb-3">
                    <p className="text-right azul text-12 bold">* Campos requeridos</p>
                </div>
            </div>
            <ButtonSalirGuardar btnText1="Cancelar"
                link={showModal}
                btnText2="Guardar"
            />
        </form>
    );
};
ModalCardForm = reduxForm({
    form: 'ClienteTallerForm', // a unique identifier for this form
    validate: (data) => {
        let errors = {};
        let DetalleArrayErrors = [];
        if (data && data.cars && data.cars.length) {
            data.cars.forEach((detalle, detalleIndex) => {
                DetalleArrayErrors[detalleIndex] = validate(detalle, {
                    vin: validationCar.vin.Combine,
                    color: validationCar.color.Combine,
                    car_registration: validationCar.car_registration.Combine,
                    year: validationCar.year.Combine,
                    car_type: validationCar.car_type.Combine,
                    model: validationCar.model.Combine,
                    brand: validationCar.brand.Combine,
                    transmission: validationCar.brand.Combine,
                })
            });
        }
        errors.cars = DetalleArrayErrors
        return { ...errors }
    },
})(ModalCardForm);
import { actions } from '../../../../../redux/modules/clientes';
const md2p = { clearSelect: actions.clearSelect };
export default ModalCardForm = connect(
    (state, props) => {
        let cars = state.form.ClienteForm.values.cars ? [...state.form.ClienteForm.values.cars] : []
        cars[props.indexArray] = {
            id: props.indexArray,
            ...cars[props.indexArray]
        }

        const form = state.form.ClienteTallerForm;
        let aseguradora_activo = false;
        if (form && form.values) {
            const values = form.values;
            if (values.cars) {
                if (values.cars[props.indexArray]) {
                    aseguradora_activo = values.cars[props.indexArray]
                    aseguradora_activo = aseguradora_activo.insured_car == true
                }

            }
        }

        return ({
            initialValues: {
                cars: [...cars]
            },
            aseguradora_activo
        })
    }, md2p
)(ModalCardForm)
