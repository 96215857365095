import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Card from '../../../Utils/Cards/Card'
import { HeaderBtnSearch, HeaderBtnSearch1 } from '../../../Utils/Header'

export default class Listar extends Component {
    componentWillMount() {
        const { listar, listar_EP, page } = this.props;
        listar(page);
        listar_EP(page)
    }

    render() {
        const { listar, listar_EP, onSortChange, onSortChangeEP, eliminar, eliminarEP } = this.props; //Funciones
        const { data, data_ep, loader, page, pageEP } = this.props; //Constantes
    
        return (
            <React.Fragment>
                <Card
                    titulo="Sistemas de vehículo"
                >
                    <Card
                        titulo="Mecánicas"
                        header={
                            <HeaderBtnSearch
                                link="car-system/create"
                                textBtn="Crear sistema"
                                {...this.props}
                            />
                        }
                    >   
                        <Grid hover striped data={data} loading={loader} onPageChange={listar} onSortChange={onSortChange} page={page}>
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                isKey
                                width='15%'
                                dataFormat={standardActions({ editar: "car-system", eliminar })}
                            />
                            <TableHeaderColumn
                                dataField="name"
                                dataSort
                            >
                                NOMBRE
                            </TableHeaderColumn>
                        </Grid> 
                    </Card>
                    <div className='p-3'></div>
                    <Card
                        titulo="Enderezado y pintura"
                        header={
                            <HeaderBtnSearch1
                                link="body-system/create"
                                textBtn="Crear sistema"
                                {...this.props}
                            />
                        }
                    >   
                        <Grid hover striped data={data_ep} loading={loader} onPageChange={listar_EP} onSortChange={onSortChangeEP} page={pageEP}>
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                isKey
                                width='15%'
                                dataFormat={standardActions({ editarEP: "body-system", eliminarEP })}
                            />
                            <TableHeaderColumn
                                dataField="name"
                                dataSort
                            >
                                NOMBRE
                            </TableHeaderColumn>
                        </Grid> 
                    </Card>
                </Card>
            </React.Fragment>
        )
    }
}
