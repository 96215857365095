import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { push } from "react-router-redux";
import { NotificationManager } from 'react-notifications';
import { initialize as initializeForm } from "redux-form";


const LOADER = 'LOADER_ENDEREZADO_PINTURA';
const DATA = 'DATA_ENDEREZADO_PINTURA';
const DATA_TASK = 'DATA_TASK_ENDEREZADO_PINTURA';
const ITEM_DATA = 'ITEM_ENDEREZADO_PINTURA';
const PAGE = 'PAGE_ENDEREZADO_PINTURA';
const ORDERING = 'ORDERING_ENDEREZADO_PINTURA';
const SEARCH = 'SEARCH_ENDEREZADO_PINTURA';
// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});
const setDataTask = data_task => ({
    type: DATA_TASK,
    data_task,
});


const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});
const clearSetItem = () => (dispatch) => {
    dispatch(setItem({}));
}

const leer = (id, diagnostic = false) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`my_orders_body_shop/${(id)}`).then((response) => {
        if (!diagnostic) {
            dispatch(initializeForm('DiagnosticoEnderezadoForm', response))
        }
        dispatch(setItem(response));
    }).catch(() => {
        dispatch(push('/mywork-orders'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const listarTask = (idWorkOrder, search_flow) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`my_orders_body_shop/${idWorkOrder}/list_tasks`, { search_flow }).then((response) => {
        dispatch(setDataTask(response));
    }).catch(() => {
        dispatch(push('/mywork-orders'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const listarQaOrCarWash = (idWorkOrder, search_flow) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`my_orders_body_shop/${idWorkOrder}/list_qa_or_car_wash`, { search_flow }).then((response) => {
        dispatch(setDataTask(response));
    }).catch(() => {
        dispatch(push('/mywork-orders'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarPartesActivas = (idWorkOrder, search_flow) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`my_orders_body_shop/${idWorkOrder}/list_parts_active`, { search_flow }).then((response) => {
        dispatch(initializeForm('SelectFlujosForm', response));
    }).catch(() => {
        dispatch(push('/mywork-orders'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const select_parts_tasks = (data, idWorkOrder, search_flow) => (dispatch) => {
    dispatch(setLoader(true));
    return new Promise((resolve, reject) => {
        api.post('my_orders_body_shop/select_parts_tasks', { data, idWorkOrder, search_flow }).then((data) => {
            resolve(data)
        }).catch((error) => {
            let mensaje = 'Error en la creación, vuelve a intentarlo';
            if (error) {
                if (error.detail)
                    mensaje = error.detail;
            }
            NotificationManager.error(mensaje, 'ERROR', 3000);
            reject(error)
        })
    }).finally(() => {
    });
}
const crearTask = (data, idWorkOrder, search_flow) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('my_orders_body_shop/create_tasks', { data, idWorkOrder, search_flow }).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
    }).catch((error) => {
        let mensaje = 'Error en la creación, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        dispatch(listarTask(idWorkOrder, search_flow))
    });
}
const actualizarTask = (data, idWorkOrder, search_flow) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('my_orders_body_shop/update_tasks', { data, idWorkOrder, search_flow }).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
    }).catch((error) => {
        let mensaje = 'Error en la creación, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        dispatch(listarTask(idWorkOrder, search_flow))
    });
}

const finalizarFlujo = (idWorkOrder, search_flow) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`my_orders_body_shop/${idWorkOrder}/next_flow`, { search_flow }).then(() => {
        NotificationManager.success('Flujo finalizado correctamente', 'Éxito', 3000);
        dispatch(push('/mywork-orders'));
    }).catch((error) => {
        let mensaje = 'Error al finalizar el flujo, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const accionTask = (idWorkOrder, search_flow, idTask, action_tracking) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`my_orders_body_shop/${idWorkOrder}/action_tracking_task`, { idTask, action_tracking }).then(() => {
        let texto = ''
        if (action_tracking == 'play')
            texto = "Tarea iniciada correctamente"
        if (action_tracking == 'pause')
            texto = "Tarea pausada correctamente"
        if (action_tracking == 'finalized')
            texto = "Tarea finalizada correctamente"
        NotificationManager.success(texto, 'Éxito', 3000);

    }).catch((error) => {
        let mensaje = ''
        if (action_tracking == 'play')
            mensaje = "Error al iniciar la tareas, vuelve a intentarlo"
        if (action_tracking == 'pause')
            mensaje = "Error al pausar la tareas, vuelve a intentarlo"
        if (action_tracking == 'finalized')
            texto = "Error al finalizar la tarea, vuelve a intentarlo"
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        if ((search_flow == "quality_control") || search_flow == "car_wash") {
            dispatch(listarQaOrCarWash(idWorkOrder, search_flow))
        } else {
            dispatch(listarTask(idWorkOrder, search_flow))
        }
        dispatch(leer(idWorkOrder, true))
    });
}

const pauseTimer = id => dispatch => {
    api.put(`my_orders_body_shop/${id}/pauseTimer`)
        .catch(error => {
            NotificationManager.error(error.detail, 'ERROR', 3000)
        })
}

const crearDiagnostico = (id, data, attachments = [], isCrear = false) => dispatch => {
    console.log('la info', data);
    if (data.tasks.length === 0) {
        let mensaje = 'La orden de diagnóstico no puede ir en blanco'
        NotificationManager.error(mensaje, 'ERROR', 3000)
        return
    }
    dispatch(setLoader(true))
    api.postAttachments(`my_orders_body_shop/${id}/diagnostic`, data, attachments)
        .then(() => {
            NotificationManager.success('Registro creado', 'Éxito', 3000)
            isCrear !== true && dispatch(push('/mywork-orders'))
        })
        .catch(error => {
            let mensaje = 'Error en la creación, vuelve a intentarlo'
            if (error) {
                if (error.detail)
                    mensaje = error.detail
            }
            NotificationManager.error(mensaje, 'ERROR', 3000)
        })
        .finally(() => {
            dispatch(setLoader(false))
        })
}

export const actions = {
    leer,
    listarTask,
    crearTask,
    listarPartesActivas,
    select_parts_tasks,
    finalizarFlujo,
    actualizarTask,
    clearSetItem,
    listarQaOrCarWash,
    accionTask,
    pauseTimer,
    crearDiagnostico
};


// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [DATA_TASK]: (state, { data_task }) => {
        return {
            ...state,
            data_task,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data_task: {
        results: [],
    },
    item: {},
    page: 1,
};

export default handleActions(reducers, initialState);
