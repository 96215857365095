import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { PENDING_GARAGE } from '../../../utility/constants';

const LOADER = 'LOADER_WORKORDERS_DASHBOARD';
const DATA = 'DATA_WORKORDERS_DASHBOARD';

const PDF_FACTURA = 'PDF_FACTURA';
const ITEM_ORDER = 'ORDER';


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});
const setPDF = pdf_data => ({
    type: PDF_FACTURA,
    pdf_data,
});
const setOrder = order => ({
    type: ITEM_ORDER,
    order,
});

const idOrder = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    api.get(`${'garage'}/${id}`, params).then((response) => {
        dispatch(setOrder(response));
    }).catch(() => {
        dispatch(push('/mechanic_orders'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getPDF = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.type = 'order_body_shop'
    api.get(`${'work_orders'}/${id}/${'orderClosingPDF'}/`, params).then((response) => {
        console.log(response)
        dispatch(setPDF(response));
    }).catch(() => {
        dispatch(push('/body-shop-orders'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

// ------------------------------------
// Actions
// ------------------------------------

const getDashboard = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('work_orders/getDashboard').then((response) => {
        dispatch(setData(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const getLimitOrder = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        api.get('/garage/limit_order')
            .then((data) => {
                resolve(data)
            })
            .catch((error) => {
                reject(error)
            })
            .finally(() => { });
    }).finally(() => {
    });
};
export const actions = {
    getDashboard,
    idOrder,
    getPDF,
    getLimitOrder,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PDF_FACTURA]: (state, { pdf_data }) => {
        return {
            ...state,
            pdf_data,
        };
    },
    [ITEM_ORDER]: (state, { order }) => {
        return {
            ...state,
            order,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        status_work_orders_mechanic: {},
        status_work_orders_body_shop: {},
        status_work_orders_appraisal: {},

    },
    pdf_data: {},
    order: {},
};

export default handleActions(reducers, initialState);
