import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    validate,
} from 'validate-redux-form';
import { renderTextArea, renderField } from '../../../../../Utils/renderField';
import { ButtonSalirGuardar } from '../../../../../Utils/Button'
import { validationPartetracking } from '../../../../../../../utility/validationForm'

const modalRender = ({ input, showModal }) => {
    useEffect(() => {
        if (!input.value) {
            showModal()
        }
    }, [input.value])
    return ""
}
let AddPartForm = (props) => {

    const { handleSubmit, showModal, editarNombre, editando } = props;
    let mostrarName = false
    if (editando) {
        if (editarNombre)
            mostrarName = true
    } else {
        mostrarName = true
    }

    return (
        <form onSubmit={handleSubmit} >
            <div className="row card-form">
                <h1 className="text-18 bold d-inline pl-3">{editando ? "EDITAR" : "AGREGAR"} PARTE DEL CARRO</h1>
                {mostrarName &&
                    <div className="col-12 padding-custom mb-3">
                        <label htmlFor="name">Nueva parte del carro*</label>
                        <Field name='name' component={renderField} type="text" className="form-control" maxLength={validationPartetracking.name.MaxLength} />
                    </div>
                }
                <div className="col-12 padding-custom mb-3">
                    <label htmlFor="description">Descripción*</label>
                    <Field name='description' component={renderTextArea} type="text" className="form-control" />
                </div>

                <div className="col-12 padding-custom mb-3">
                    <p className="text-right azul text-12 bold">* Campos requeridos</p>
                </div>
                <Field name="modalOpen" component={modalRender} showModal={showModal} />

            </div>
            <ButtonSalirGuardar btnText1="Cancelar"
                link={showModal}
            />
        </form>
    );
};
AddPartForm = reduxForm({
    form: 'AddPartForm',
    validate: (data) => {
        return validate(data, {
            name: validationPartetracking.name.Combine,
            description: validationPartetracking.description.Combine,
        })
    },
})(AddPartForm);

export default AddPartForm;
