import React, { Component, Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
const enderezado_y_pintura = require("assets/img/orden_trabajo/enderezado_y_pintura.svg");
const evaluo = require("assets/img/orden_trabajo/evaluo.svg");
const mecanica = require("assets/img/orden_trabajo/mecanica.svg");
import { ButtonSalir } from '../../../Utils/Button';
import OrderExtraModal from '../ExtraModalOrder'

const OrdenTrabajoSelect = (props) => {
    const [modalOpen, setmodalOpen] = useState(false)
    useEffect(() => {
        props.getLimitOrder().then((data) => {
            setmodalOpen(data.limit_order)
        });
    }, [])

    const { me = {} } = props;
    const { permissions = [] } = me || {}

    return (
        <Fragment>
            {modalOpen &&
                <OrderExtraModal required />
            }
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "70vh" }}>
                <div className="w-100">
                    <span className="text-25 negro d-block text-center mt-0 mt-sm-5 mb-0 mb-sm-3">Selecciona el tipo de orden</span>

                    <div className="w-100 h-100 d-flex justify-content-around flex-column flex-lg-row">
                        {permissions.includes('work_order_management_mechanic') &&
                            <Link to="/work-order/mechanic/create" className="flex-1 px-4 mx-0 mx-lg-3 d-flex pt-4 bg-blanco flex-column my-3 py-3  py-sm-5" style={{ boxShadow: "0px 0px 7px #00000066", borderRadius: 15, maxWidth: 450 }}>
                                <img src={mecanica} style={{ width: 45 }} className="mx-auto mb-2" alt="" />

                                <span className="text-20 negro mx-auto">MECANICAS</span>
                            </Link>
                        }
                        {permissions.includes('work_order_management_body_shop') &&
                            <Link to="/work-order/body-shop/create" className="flex-1 px-4 mx-0 mx-lg-3 d-flex pt-4 bg-blanco flex-column my-3 py-3  py-sm-5" style={{ boxShadow: "0px 0px 7px #00000066", borderRadius: 15, maxWidth: 450 }}>
                                <img src={enderezado_y_pintura} style={{ width: 45 }} className="mx-auto mb-2" alt="" />
                                <span className="text-20 negro mx-auto">ENDEREZADO Y PINTURA</span>
                            </Link>
                        }{permissions.includes('work_order_management_appraisal') &&
                            <Link to="/work-order/appraisal/create" className="flex-1 px-4 mx-0 mx-lg-3 d-flex pt-4 bg-blanco flex-column my-3 py-3  py-sm-5" style={{ boxShadow: "0px 0px 7px #00000066", borderRadius: 15, maxWidth: 450 }}>
                                <img src={evaluo} style={{ width: 45 }} className="mx-auto mb-2" alt="" />
                                <span className="text-20 negro mx-auto">AVALUO</span>
                            </Link>
                        }
                    </div>
                    <div className="w-100 mt-n4 mt-sm-0">
                        <ButtonSalir link="/work-orders" />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default OrdenTrabajoSelect
