import React, { Component, Fragment } from 'react';
import Grid from '../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../../Utils/Grid/StandardActions';
const clients_green = require('assets/img/clients_green.svg');
const clients_red = require('assets/img/clients_red.svg');
const file_invoice_solid = require('assets/img/file-invoice-solid.svg');
import moment from 'moment';
import { RenderCurrency } from '../../../Utils/renderField/renderReadField';
export default class Listar extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { onSortChange, eliminar, listar, type_client } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return (
            <Fragment>
                <div className="row mb-3 mt-3">
                    <div className="col-12 col-md-4 d-flex align-items-start">
                        <div
                            style={{
                                border: '1px solid #888888',
                                borderRadius: 15,
                                padding: '0 20px',
                            }}
                        >
                            <img src={clients_green} className="mb-3" alt="" />
                            <span className="text-18 negro mt-auto mb-2 px-3">
                                {type_client}
                            </span>
                            <span className="text-40 verde bold">
                                {data.total_type_client || 0}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 d-flex align-self-end">
                        <img
                            src={file_invoice_solid}
                            className="my-auto"
                            style={{ maxWidth: '8%' }}
                            alt=""
                        />
                        <span className="text-18 negro mt-auto mb-2 px-3">
                            Total facturado:{' '}
                        </span>
                        <span className="text-30 negro bold">
                            <RenderCurrency className={'text-30 negro bold'} value={data.total_amount_clients || 0} />
                        </span>
                    </div>
                    <div class="col-12 col-md-4 d-flex align-self-center">
                        <img src={clients_red} className="my-auto" alt="" />
                        <span className="text-18 negro mt-auto mb-2 px-3">
                            Total clientes:{' '}
                        </span>
                        <span className="text-40 rojo bold">
                            {data.total_customer || 0}
                        </span>
                    </div>
                </div>

                <Grid
                    hover
                    striped
                    data={data}
                    loading={loader}
                    onPageChange={listar}
                    onSortChange={onSortChange}
                    page={page}
                >
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width="150PX"
                        dataFormat={standardActions({
                            editar: 'client',
                            eliminar,
                            verCarro: true,
                        })}
                    />
                    <TableHeaderColumn dataField="name" dataSort>
                        CLIENTE
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="document_number" dataSort>
                        NO. IDENTIFICACIÓN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="last_order"
                        dataSort
                        dataFormat={cell =>
                            cell === 'not'
                                ? '--'
                                : moment(cell).format('DD-MM-YYYY')
                        }
                    >
                        ULTIMA ORDEN
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="email" dataSort>
                        E-MAIL
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="phone_number" dataSort>
                        TELÉFONO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="total_amount"
                        dataSort
                        dataFormat={cell => (
                            <RenderCurrency value={cell || 0} />
                        )}
                    >
                        TOTAL FACTURADO
                    </TableHeaderColumn>
                </Grid>
            </Fragment>
        );
    }
}
