import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/ordenesTrabajoAdmin';
import Select from './SelectOrdenComponent';


const ms2p = (state) => {
    return {
        ...state.dashboardWorkOrders,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Select);
