import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/bitacora';
import Listar from './Listar';


const ms2p = (state) => {
    return {
        ...state.bitacora,
    }
}

const md2p = { ...actions }

export default connect(ms2p, md2p)(Listar)
