import React, { Component } from 'react';
import { Link } from "react-router-dom";
class SendRecovery extends Component {
    render() {
        const { correo } = this.props;
        return (
            <div
                name="loginForm"
                className="form-validate mb-lg text-center"
            >
                <h5 className="text-bold mb-4 mt-n3">¡ENVIADO!</h5>

                <h6 className="text-bold mb-4 mb-4 mt-5">{correo}</h6>

                <p className="mb-4 my-5 medium">Se ha enviado enlace de recuperación de contraseña</p>
                <div className="buttons-box mt-4">
                </div>
                <div className="buttons-box mt-3">
                    <Link
                        className="btn btn-gris btn-block mr-1"
                        to="/login"
                    >
                        Regresar
                </Link>
                </div>
            </div>
        );
    }
}

export default SendRecovery;



