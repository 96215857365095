import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    validate,
} from 'validate-redux-form';

import {
    renderField,
    renderNumber,
} from '../../../Utils/renderField/renderField';

import { validationInsuranceCarrier } from '../../../../../utility/validationForm'
import { ButtonSalirGuardar } from '../../../Utils/Button'

const AseguradoraForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form">
                <div className="col-md-6 padding-custom mb-3 ">
                    <label htmlFor="name">Nombre*</label>
                    <Field name="name" component={renderField} type="text" className="form-control" maxLength={validationInsuranceCarrier.name.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="email">E-mail*</label>
                    <Field name="email" component={renderField} type="email" className="form-control" maxLength={validationInsuranceCarrier.email.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="phone_number">Teléfono*</label>
                    <Field name="phone_number" isNumericString={true} component={renderNumber} type="text" className="form-control" maxLength={validationInsuranceCarrier.phone_number.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3 d-flex">
                    <p className="text-right azul text-12 bold align-self-end ml-auto mb-0">* Campos requeridos</p>
                </div>
            </div>
            <ButtonSalirGuardar link="/insurance-carriers" />
        </form>
    );
};

export default reduxForm({
    form: 'AseguradoraForm',
    validate: (data) => {
        return validate(data, {
            name: validationInsuranceCarrier.name.Combine,
            email: validationInsuranceCarrier.email.Combine,
            phone_number: validationInsuranceCarrier.phone_number.Combine,
        })
    },
})(AseguradoraForm);
