import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logOut, getMe } from './redux/modules/cuenta/login';

// maquetado base
import SiderBar from './common/components/layout/Sidebar/SideBar';

import Navbar from './common/components/layout/Navbar/Navbar';
import { VerifyLogin } from './common/components/layout';

import { ChangePassword } from './common/components/LoginRegister';

class PrivateRouteBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleOpen: true,
        };
    }

    navToggle = () => {
        this.setState({ toggleOpen: !this.state.toggleOpen });
    };

    isAuthenticated = () => {
        const token = localStorage.getItem('token');
        const {
            getMe,
            login: { me },
        } = this.props;
        if (!!token && !!me.username) {
            return true;
        } else if (token) {
            getMe();
            return 'Verifying';
        }
        return false;
    };

    render() {
        const {
            component: Component,
            logOut,
            login: { me },
            permission = "Accept",
            ...rest
        } = this.props;
        if (me.reset_pass) {
            return (
                <ChangePassword />
            )
        }
        const isAuthenticated = this.isAuthenticated();

        return (
            <Route
                {...rest}
                render={(props) =>
                    isAuthenticated ? (
                        isAuthenticated === true ? (
                            <div>
                                <SiderBar
                                    toggleOpen={this.state.toggleOpen}
                                    navToggle={this.navToggle}
                                    logOut={logOut}
                                    user={me}
                                />
                                <main className="main-content p-0">
                                    <div className="main-navbar bg-grey-light sticky-top">
                                        <div className="pr-4">
                                            <Navbar
                                                navToggle={this.navToggle}
                                                logOut={logOut}
                                                user={me}
                                            />
                                        </div>
                                    </div>
                                    <div className="main-content-container px-3 px-sm-4 px-md-5 container-fluid py-3">
                                        {permission == 'Accept' || me.permissions.some(r => permission.includes(r)) ?
                                            <Component {...props} />
                                            :
                                            <Redirect
                                                to={{
                                                    pathname: '/',
                                                    state: { from: props.location },
                                                }}
                                            />
                                        }
                                    </div>
                                </main>
                            </div>
                        ) : (
                            <VerifyLogin />
                        )
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: props.location },
                            }}
                        />
                    )
                }
            />
        );
    }
}

const mstp = (state) => ({ ...state });

const mdtp = { logOut, getMe };

const ProtectedRoute = connect(mstp, mdtp)(PrivateRouteBase);

export default ProtectedRoute;
