import { validators, combine, optional, validatorFromFunction } from 'validate-redux-form';
const noSpace = validatorFromFunction(value => value && value.trim())
const validateYeare = (number) => validatorFromFunction(value => value < number)(number)
const numeroLetro50 = {
    Combine: combine(
        noSpace()('Campo requerido.'),
        validators.exists()('Campo requerido.'),
        validators.regex(/^[a-zA-ZÀ-ž0-9\s]+$/)('Sólo se permiten letras y números'),
        validators.length({ max: 50 })('Máximo 50 carácteres')
    ),
    MaxLength: 51,
}

const valor0 = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
        validators.length({ min: 0 })('Ingrese una cantidad.'),
    ),
    MaxLength: 51,
}

const now_year = new Date
const year = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
        validators.length({ max: 4, min: 4 })('Ingrese un año.'),
        validateYeare(now_year.getFullYear() + 1)(`El año no puede ser mayor que ${now_year.getFullYear() + 1}`),
    ),
    MaxLength: 4,
}

const numeroLetro150 = {
    Combine: combine(
        noSpace()('Campo requerido.'),
        validators.exists()('Campo requerido.'),
        validators.regex(/^[a-zA-ZÀ-ž0-9\s]+$/)('Sólo se permiten letras y números'),
        validators.length({ max: 150 })('Máximo 150 carácteres')
    ),
    MaxLength: 151,
}


const caracteres250 = {
    Combine: combine(
        noSpace()('Campo requerido.'),
        validators.exists()('Campo requerido.'),
        validators.length({ max: 250 })('Máximo 250 carácteres')
    ),
    MaxLength: 251,
}
const optional_caracteres250 = {
    Combine: (e) => (e ? optional(caracteres250.Combine)(e) : null),
    MaxLength: caracteres250.MaxLength,
}

const requerido = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
    ),
    MaxLength: undefined,
}

const username = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
        validators.regex(/^\S+$/)('No puede contener espacios'),
        validators.length({ min: 3 })('Mínimo 3 carácteres'),
        validators.length({ max: 100 })('Máximo 100 carácteres'),
    ),
    MaxLength: 101,
}

const name = {
    Combine: combine(
        noSpace()('Campo requerido.'),
        validators.exists()('Campo requerido.'),
        validators.regex(/^[a-zA-ZÀ-ž\s]+$/)('Sólo se permiten letras'),
        validators.length({ min: 3 })('Mínimo 3 carácteres'),
        validators.length({ max: 250 })('Máximo 250 carácteres'),
    ),
    MaxLength: 251,
}



const email = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
        validators.regex(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@+[a-zA-Z0-9-]{1,}\.{1}[a-zA-Z0-9-]{2,}$/)('El correo electrónico no es valido'),
        validators.length({ max: 100 })('Máximo 100 carácteres'),
    ),
    MaxLength: 101,
}
const optional_email = {
    Combine: (e) => (e ? optional(email.Combine)(e) : null),
    MaxLength: email.MaxLength,
}


const phone_number = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
        validators.regex(/^[+]?([0-9]+)$/)('Número de teléfono no válido'),
        validators.length({ min: 8 })('Mínimo 8 dígitos'),
        validators.length({ max: 15 })('Máximo 15 dígitos'),
    ),
    MaxLength: 16,
}

const tax_percentage = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
        validators.regex(/(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/)('Porcentaje no válido'),
        validators.length({ min: 4 })('Mínimo 0 dígitos'),
        validators.length({ max: 6 })('Máximo 100 dígitos'),
    ),
    MaxLength: 7,
}

const prefix = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
        validators.regex(/^[a-zA-ZÀ-ž\s]+$/)('Sólo se permiten letras'),
        validators.length({ max: 3, min: 3 })('Ingrese 3 letras.')
    ),
    MaxLength: 3,
}
const subdomain = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
        validators.regex(/^[a-zA-ZÀ-ž\s]+$/)('Sólo se permiten letras'),
        validators.length({ max: 30 })('Máximo 30 letras'),
        validators.length({ min: 3 })('Mínimo 3 letras'),
    ),
    MaxLength: 31,
}

const optional_phone_number = {
    Combine: (e) => (e ? optional(phone_number.Combine)(e) : null),
    MaxLength: phone_number.MaxLength,
}

export const validationClient = {
    name: name,

    country: requerido,
    city: caracteres250,
    address: caracteres250,

    phone_number: phone_number,
    mobil_number: optional_phone_number,
    email,
    gender: requerido,
    admission_date: requerido,
    description_reference: optional_caracteres250,
    document_number: optional_caracteres250,
}

export const validationConfiguration = {
    tax_percentage: tax_percentage,
    currency: requerido,
    name: caracteres250,
    phone_number,
    email,
    email_notifications: email,
    address: caracteres250,
    cost_hour: requerido,
    hourly_sale: requerido,
}

export const validationContactInfo = {
    name: name,
    phone_number: optional_phone_number,
    mobil_number: optional_phone_number,
    email: optional_email,
}

export const validationDiagnosticMecInfo = {
    name: name,
    description: caracteres250,
    car_system: requerido,
    system_component: requerido,
    quotation: requerido,
    user: requerido,
}

export const validationCar = {
    // fuel_type: arlex, -> en el clickup dice que es select y en la db es text
    car_registration: caracteres250,
    vin: caracteres250,
    color: caracteres250,
    year,

    car_type: requerido,
    model: requerido,
    brand: requerido,
    transmission: requerido,
}

export const validationInsuranceCarrier = {
    name: caracteres250,
    phone_number: phone_number,
    email,
}

export const validationCarSystem = {
    name: caracteres250,
}
export const validationSystemComponent = {
    name: caracteres250,
}

export const work_order = {
    client: requerido,
    car: requerido,
    repair_type: requerido,
    cone: optional_caracteres250
}

export const validationPartetracking = {
    name: caracteres250,
    description: requerido,
}

// * * * * * * * * * * * * *  * * * * * * * * * * ** * * *
export const validationEmail = {
    email,
}

export const validationAdministrador = {
    first_name: name,
    last_name: name,
    username,
    email,
    phone_number,
    role: requerido,
}

export const validationsOthers = {
    requerido,
    numeroLetro50,
    valor0,
}

export const validationGarage = {
    name: caracteres250,
    phone_number,
    email,
    country: requerido,
    city: caracteres250,
    address: caracteres250,
    subdomain,
    prefix: prefix,
    type_order_active: requerido,

}

export const validationBranchGarage = {
    name: caracteres250,
    phone_number,
    email,
    country: requerido,
    city: caracteres250,
    address: caracteres250,
}

export const validationReceiptInfo = {
    name: caracteres250,
    country: requerido,
    city: caracteres250,
    address: caracteres250,
    tax_id: caracteres250,
    phone_number: optional_phone_number,
    email: optional_email,
}

export const validationPayment = {
    reference: caracteres250,
    amount: requerido,
    payment_date: requerido,
}
