import React, {Component} from 'react';
import Card from '../../../Utils/Cards/Card'
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Listar from './Listar';
import { ACTIVE_CLIENT, INACTIVE_CLIENT, PARTIALLY_ACTIVE_CLIENT } from '../../../../../utility/constants';
import {HeaderOneFilter} from '../../../Utils/Header';
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import ExcelForm2 from '../UploadExcel/UploadExcel'
import LoadMask from "../../../Utils/LoadMask/LoadMask";

export default class TabsListar extends Component {
    state = {
        modal_open: false,
    };

    toggleModal = () => {
        if (this.state.modal_open) {
            this.setState({modal_open: this.state.modal_open});
        }
        this.setState({modal_open: !this.state.modal_open});
    };

    actionImportExcel = (data) => {
        this.props.importExcel(data, this.toggleModal)
    }

    render() {
        const {loadingFather} = this.props;

        return (
            <Card
                titulo="Clientes"
                subTitulo=""
                header={
                    <HeaderOneFilter
                        {...this.props}
                        textBtn2="Importar"
                        link="client/create"
                        textBtn="Crear cliente"
                        btn2Click={this.toggleModal}
                    />
                }
            >
                <Tabs
                    defaultActiveKey={String(ACTIVE_CLIENT)}
                    tabBarPosition="top"
                    onChange={(tab) => this.props.estadoClienteTab(tab)}
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                    activeKey={String(this.props.estado_cliente_tab)}
                >
                    <TabPane
                        tab="ACTIVOS"
                        key={ACTIVE_CLIENT}
                    >
                        <Listar {...this.props} type_client="Clientes activos: " />
                    </TabPane>
                    <TabPane
                        tab="PARCIALMENTE ACTIVOS"
                        key={PARTIALLY_ACTIVE_CLIENT}
                    >
                        <Listar {...this.props} type_client="Clientes parcialmente activos: " />
                    </TabPane>
                    <TabPane
                        tab="INACTIVOS"
                        key={INACTIVE_CLIENT}
                    >
                        <Listar {...this.props} type_client="Clientes inactivos: " />
                    </TabPane>
                </Tabs>
                <Modal
                    isOpen={this.state.modal_open}
                    className="mx-lg-auto mx-md-3 mx-2 pt-4 mt-5"
                >
                    <ModalHeader className="card p-3 px-4">
                        Importa tu excel
                    </ModalHeader>
                    <LoadMask loading={loadingFather} dark blur>
                        <ModalBody className="car mt-3">
                            <ExcelForm2
                                create={true}
                                cerrarModal={this.toggleModal}
                                onSubmit={this.actionImportExcel}
                            />
                        </ModalBody>
                    </LoadMask>
                </Modal>
            </Card>
        )
    }
}
