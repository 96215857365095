import React, { Component, Fragment } from 'react';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import Card from '../../../Utils/Cards/Card';
import Grid from "../../../Utils/Grid";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import './styleDashboard.css';
import { RenderCurrency } from '../../../Utils/renderField/renderReadField';
import { REFERENCES } from '../../../../../utility/constants'
import { Popover } from 'antd';
import Chart from 'react-apexcharts'
import {
    WORK_ORDER_MANAGEMENT,
    MY_WORK_ORDERS,
    DASHBOARD_GARAGE,
} from '../../../../../utility/constants';
import { Link, Redirect } from 'react-router-dom';
import OrderExtraModal from '../../OrdenesTrabajoAdmin/ExtraModalOrder'

export default class Dashboard extends Component {
    state = {
        modal_open: false,
    };
    componentWillMount() {
        const { listarGraph } = this.props;
        listarGraph();
    }
    constructor(props) {
        super(props);
        this.state = {
            options: {
                yaxis: [11, 22, 33],
                chart: {
                    height: 250,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {

                        offsetX: 50,
                        offsetY: 0,
                        startAngle: 0,
                        endAngle: 180,
                        hollow: {
                            margin: 5,
                            size: '30%',
                            background: 'transparent',
                            image: undefined,
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                show: false,
                            }
                        }
                    }
                },
                labels: ['Insumos', 'Compras', 'Mano de obra'],
                legend: {
                    show: true,
                    floating: true,
                    fontSize: '16px',
                    position: 'left',
                    // offsetX: -20,
                    // offsetY: 15,
                    labels: {
                        useSeriesColors: true,
                    },
                    markers: {
                        size: 0
                    },
                    formatter: function (seriesName, opts) {
                        console.log("data", opts.w.globals)
                        const a = opts.w.globals.colors[opts.seriesIndex + 3]
                        const currency = opts.w.globals.colors[6] ? opts.w.globals.colors[6] : "$"
                        return seriesName + ":  " + currency + (a ? a.toFixed(2) : 0)
                    },
                    itemMargin: {
                        vertical: 3
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            show: true
                        }
                    }
                }]
            }
        }
    }

    tamanoWith(ofertas, adjudicados) {
        if (ofertas == 0) {
            return "100%"
        }

        let porcentaje = (adjudicados * 100) / ofertas
        porcentaje = Math.round(porcentaje);
        porcentaje = `${porcentaje.toString()}%`
        return porcentaje
    }

    expandComponent = (row) => {
        let _data = row.tasks ? row.tasks.slice() : [];
        return (
            <div className="row ml-5">
                <div className="col-10 ml-5">
                    <div className="tabla-adentro-pais" style={{ width: '100%' }}>
                        <BootstrapTable
                            data={_data}
                            className="no-hover no-thead"
                        >
                            <TableHeaderColumn isKey dataField="name"
                                dataFormat={(cell, row) => {
                                    let a = ""
                                    try {
                                        let b = ""
                                        if (row.order_type == 11) {
                                            b = "Enderezado y pintura"
                                        } else if (row.order_type == 22) {
                                            b = "Mecácnica"
                                        } else if (row.order_type == 33) {
                                            b = "Avalúo"
                                        }
                                        a = b + " - " + cell
                                    } catch (error) {
                                        a = cell
                                    }
                                    return a;
                                }}
                            >
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            </div>
        )
    };

    contentMechanic(data) {
        const data_html = data.map((element, index) => <div className="d-flex flex-column" key={index}>
            <span className="bold text-16 negro d-block"> {element.system_component__name + " - " + element.system_component__car_system__name}</span>
            <span className="bold text-14 rojo39 d-block">{element.contar} {element.contar <= 1 ? "vez atendida" : "veces atendidas"}</span>
        </div>
        );
        return <div>{data_html}</div>
    }
    contentBodyShop(data) {
        const labels = {
            'not_assigned': "No asignado",
            'disarmed': "Desarmado",
            'straightened': "Enderezado",
            'enlisted': "Alistado",
            'painting': "Pintura",
            'polished': "Pulida",
            'armed': "Armado",
            'quality_control': "Control de calidad",
            'car_wash': "Lavado del vehículo",
            'to_check_in': "Por facturar",
            'finalized': "Finalizado",
        }
        const data_html = data.map((element, index) => <div className="d-flex flex-column" key={index}>
            <span className="bold text-16 negro d-block"> {labels[element.workflow] + " - " + element.name}</span>
            <span className="bold text-14 rojo39 d-block">{element.contar} {element.contar <= 1 ? "vez atendida" : "veces atendidas"}</span>
        </div>
        );
        return <div>{data_html}</div>
    }
    contentTime(data) {
        const data_html = data.map((element, index) => <div className="d-flex flex-column" key={index}>
            <span className="bold text-16 negro d-block"> {element.name}</span>
            <span className="bold text-14 rojo39 d-block">{element.time} Hrs</span>
        </div>
        );
        return <div>{data_html}</div>
    }
    contentModels(data) {
        return data.map((element, index) => <div className="d-flex flex-column" key={index}>
            <span className="bold text-16 negro d-block"> {element.model__name}</span>
            <span className="bold text-14 rojo39 d-block">{element.contar} {element.contar <= 1 ? "vez atendida" : "veces atendidas"}</span>
        </div>
        );
    }

    render() {
        const { item = {}, me } = this.props; //Constantes

        if (me.permissions.includes(DASHBOARD_GARAGE)) {
        } else if (me.permissions.includes(WORK_ORDER_MANAGEMENT)) {
            return <Redirect to="/work-orders" />
        } else if (me.permissions.includes(MY_WORK_ORDERS)) {
            return <Redirect to="/mywork-orders" />
        } else {
            return <Redirect to="/user-profile" />
        }

        const { new_list_client = { results: [], total: 0 }, most_recurring_services = {}, client_top = [], tasks_total_time = {}, cars_top = [], cars_top_max = 0, consumption_of_the_month = {}, subscription_status = {} } = item || {}

        const {
            tasks_recurring_body_shop = { total: 0, data: [] },
            tasks_recurring_mechanic = { total: 0, data: [] },
            tasks_recurring_appraisal_count = 0, tasks_count_max = 0,
        } = most_recurring_services || {}

        const {
            tasks_total_time_body_shop = { total: 0, data: [] },
            tasks_total_time_mechanic = { total: 0, data: [] },
            tasks_total_time_appraisal = 0,
        } = tasks_total_time || {}

        const {
            count_total_work_orders = 0,
            total_work_orders = 0,
            total_supplies_work_orders = 0,
            total_shopping_work_orders = 0,
            total_workforce_work_orders = 0,
            serie = false,
            currency = '$'
        } = consumption_of_the_month || {}

        const {
            available_orders = 0,
            orders_consumed = 0,
        } = subscription_status || {}



        return (
            <div className="py-4 ">
                {this.state.modal_open && <OrderExtraModal />}
                <div className="py-4">
                    <div className="row ">
                        <div className="col-lg-6 col-md-6 p-0">
                            <div className="col-lg-12 col-12 pt-3 pb-4">
                                <h2 className="text-25 medium">MES EN CURSO</h2>
                                <CardEmpty >
                                    <div className="row mx-2 my-3 d-flex flex-sm-row flex-column">
                                        <div className="flex-1 mx-1 d-flex justify-content-between align-items-center" style={{ maxWidth: 400 }}>
                                            <span className="text-16 negro bold">Total de ingresos: <RenderCurrency className="text-16 negro bold" value={total_work_orders} /></span>
                                        </div>
                                        <div className="flex-1 mx-1 d-flex justify-content-between align-items-center" style={{ maxWidth: 400 }}>
                                            <span className="text-16 negro bold">Ordenes facturadas: {count_total_work_orders} </span>
                                        </div>
                                    </div>
                                    <div className="row mx-4 my-3">
                                        {serie && <Chart options={{ ...this.state.options, colors: ['#623A6C', '#B04C7A', '#CD0A0A', total_supplies_work_orders, total_shopping_work_orders, total_workforce_work_orders, currency] }} series={serie} type="radialBar" width={400} height={250} />}
                                    </div>
                                </CardEmpty>
                            </div>
                            <div className="col-lg-12 col-12 pt-3 pb-5">
                                <h2 className="text-25 medium">MARCAS Y MODELOS</h2>
                                <CardEmpty >
                                    {cars_top.map((element) =>
                                        <div className="row mx-4 my-3" key={element.brand__name}>
                                            <div className="flex-1 mx-1">
                                                <span className="negro bold">{element.brand__name}: <span className="rojo39"> {element.contar}</span></span>
                                                <div className="bg-grisd7 text-right w-100">
                                                    <Popover placement="rightTop" content={this.contentModels(element.models)}>
                                                        <div className="bg-rojo39  py-2" style={{ width: this.tamanoWith(cars_top_max == 0 ? 10 : cars_top_max, element.contar) }} />
                                                    </Popover>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <br />
                                </CardEmpty>
                            </div>
                            <div className="col-lg-12 col-12 pt-3 pb-4">
                                <h2 className="text-25 medium">TIEMPO INVERTIDO EN PROCESOS</h2>
                                <CardEmpty >
                                    <div className="row mx-4 my-3">
                                        <Popover placement="rightTop" content={this.contentTime(tasks_total_time_mechanic.data)}>
                                            <div className="flex-1 mx-1 d-flex justify-content-between align-items-center" style={{ maxWidth: 400 }}>
                                                <span className="text-18 negro bold">Mecánica: </span>
                                                <span className="bold blanco bg-rojo0a p-2" style={{ borderRadius: 15 }}>{tasks_total_time_mechanic.total}  Hrs</span>
                                            </div>
                                        </Popover>
                                    </div>
                                    <div className="row mx-4 my-3">
                                        <Popover placement="rightTop" content={this.contentTime(tasks_total_time_body_shop.data)}>
                                            <div className="flex-1 mx-1 d-flex justify-content-between align-items-center" style={{ maxWidth: 400 }}>
                                                <span className="text-18 negro bold">Enderezado y pintura: </span>
                                                <span className="bold blanco bg-rojo0a p-2" style={{ borderRadius: 15 }}>{tasks_total_time_body_shop.total}  Hrs</span>
                                            </div>
                                        </Popover>
                                    </div>
                                    <div className="row mx-4 my-3 mb-5">
                                        <div className="flex-1 mx-1 d-flex justify-content-between align-items-center" style={{ maxWidth: 400 }}>
                                            <span className="text-18 negro bold">Avalúo: </span>
                                            <span className="bold blanco bg-rojo0a p-2" style={{ borderRadius: 15 }}>{tasks_total_time_appraisal}  Hrs</span>
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>

                            {!me.is_branch_user &&
                                <div className="col-lg-12 col-12 pt-3 pb-4">
                                    <h2 className="text-25 medium">ESTADO DE SUSCRIPCIÓN</h2>
                                    <CardEmpty >
                                        {available_orders >= 1000000 ?
                                            <Fragment>
                                                <div className="row mx-4 my-3">
                                                    <div className="flex-1 mx-1 d-flex justify-content-between align-items-center" style={{ maxWidth: 400 }}>
                                                        <span className="text-18 negro bold">Ordenes consumidas: </span>
                                                        <span className="bold blanco bg-rojo0a p-2 text-center" style={{ borderRadius: 15, minWidth: 80 }}>{orders_consumed}</span>
                                                    </div>
                                                </div>
                                                <br />
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <div className="row mx-4 my-3">
                                                    <div className="flex-1 mx-1 d-flex justify-content-between align-items-center" style={{ maxWidth: 400 }}>
                                                        <span className="text-18 negro bold">Ordenes Disponibles: </span>
                                                        <span className="bold blanco bg-rojo0a p-2 text-center" style={{ borderRadius: 15, minWidth: 80 }}>{available_orders}</span>
                                                    </div>
                                                </div>
                                                <div className="row mx-4 my-3">
                                                    <div className="flex-1 mx-1 d-flex justify-content-between align-items-center" style={{ maxWidth: 400 }}>
                                                        <span className="text-18 negro bold">Ordenes consumidas: </span>
                                                        <span className="bold blanco bg-rojo0a p-2 text-center" style={{ borderRadius: 15, minWidth: 80 }}>{orders_consumed}</span>
                                                    </div>
                                                </div>
                                                <div className="row mx-4 my-3 mb-5">
                                                    <span className="mx-1 text-18 negro bold">ORDENES RESTANTES: {available_orders - orders_consumed}</span>
                                                </div>
                                                <div className="row mx-4 my-3 mb-5 d-flex justify-content-center">
                                                    <button onClick={() => {
                                                        this.setState({ modal_open: true })
                                                    }} className="btn btn-secondary mx-1"> Comprar más ordenes</button>
                                                </div>
                                            </Fragment>
                                        }
                                    </CardEmpty>
                                </div>
                            }
                        </div>

                        <div className="col-lg-6 col-md-6 p-0">
                            <div className="col-lg-12 col-12 pt-3 pb-4">
                                <h2 className="text-25 medium">SERVICIOS MÁS RECURRENTES</h2>
                                <CardEmpty >
                                    <div className="row mx-4 my-3">
                                        <div className="flex-1 mx-1">
                                            <span className="negro bold">Mecánica: <span className="rojo39"> {tasks_recurring_mechanic.total}</span></span>
                                            <div className="bg-grisd7 text-right w-100">
                                                <Popover placement="rightTop" content={this.contentMechanic(tasks_recurring_mechanic.data)}>
                                                    <div className="bg-rojo39  py-2" style={{ width: this.tamanoWith(tasks_count_max == 0 ? 10 : tasks_count_max, tasks_recurring_mechanic.total) }} />
                                                </Popover>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-4 my-3">
                                        <div className="flex-1 mx-1">
                                            <span className="negro bold">Enderezado y pintura: <span className="rojo39">{tasks_recurring_body_shop.total}</span></span>
                                            <div className="bg-grisd7 text-right w-100">
                                                <Popover placement="rightTop" content={this.contentBodyShop(tasks_recurring_body_shop.data)}>
                                                    <div className="bg-rojo39  py-2" style={{ width: this.tamanoWith(tasks_count_max == 0 ? 10 : tasks_count_max, tasks_recurring_body_shop.total) }} />
                                                </Popover>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-4 my-3 mb-5">
                                        <div className="flex-1 mx-1">
                                            <span className="negro bold">Avalúo: <span className="rojo39">{tasks_recurring_appraisal_count}</span></span>
                                            <div className="bg-grisd7 text-right w-100">
                                                <div className="bg-rojo39  py-2" style={{ width: this.tamanoWith(tasks_count_max == 0 ? 10 : tasks_count_max, tasks_recurring_appraisal_count) }} />
                                            </div>
                                        </div>
                                    </div>
                                </CardEmpty>
                            </div>
                            <div className="col-lg-12 col-12 pt-3 pb-4">
                                <h2 className="text-25 medium">NÚMERO DE CLIENTES NUEVOS</h2>
                                <Card >
                                    <Grid className="pt-3" hover striped data={new_list_client} pagination={false} >
                                        <TableHeaderColumn
                                            dataField="reference"
                                            isKey
                                            dataFormat={(cell) => {
                                                let a = ""
                                                try {
                                                    a = REFERENCES.filter(item => item.id == cell)[0].name
                                                } catch (error) {
                                                    a = ""
                                                }
                                                return a;
                                            }}
                                        >
                                            MEDIO
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="reference__count"
                                        >
                                            TOTAL
                                        </TableHeaderColumn>
                                    </Grid>
                                    <span className="d-flex justify-content-end mr-3 ">Total: {new_list_client.total}</span>
                                </Card>
                            </div>
                            <div className="col-lg-12 col-12 pt-3 pb-4">
                                <h2 className="text-25 medium">CLIENTES TOP</h2>
                                <Card >
                                    <Grid className="pt-3" hover striped data={{ results: client_top }} pagination={false} striped
                                        expandableRow={() => true} expandComponent={this.expandComponent} className="no-hover">
                                        <TableHeaderColumn
                                            dataField="name"
                                            isKey

                                        >
                                            NOMBRE
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="work_orders__total_amount__sum"
                                            dataFormat={(cell) => <RenderCurrency value={cell} />}
                                        >
                                            TOTAL
                                        </TableHeaderColumn>
                                    </Grid>
                                </Card>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
