import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Card from '../../../Utils/Cards/Card'
import { HeaderBtnSearch } from '../../../Utils/Header'
import { Async } from "react-select";
import { PERMISOS } from '../../../../../utility/constants'
export default class Listar extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    };


    render() {
        const { listar, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return (
            <Card
                titulo="Roles"
                header={
                    <HeaderBtnSearch
                        link="role/create"
                        textBtn="Crear rol"
                        {...this.props}
                    />
                }
            >
                <Grid hover striped data={data} loading={loader} onPageChange={listar} onSortChange={onSortChange} page={page}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width="120px"
                        dataFormat={standardActions({ editar: "role", eliminar })}
                    />
                    <TableHeaderColumn
                        dataField="name"
                        dataSort
                        width="30%"
                    >
                        ROL
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        dataSort
                        dataFormat={(cell, row) => {
                            let gestiones = []
                            if (row.user_management) {
                                gestiones.push('Usuarios')
                            }

                            PERMISOS.forEach(element => {
                                if (row[element.id]) {
                                    gestiones.push(element.name)
                                }
                            });
                            return gestiones
                        }}
                    >
                        GESTIÓN
                    </TableHeaderColumn>

                </Grid>

            </Card>
        )
    }
}
