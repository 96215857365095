import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Card from '../../../Utils/Cards/Card'
import { HeaderBtnSearch } from '../../../Utils/Header'



export default class Listar extends Component {
    componentWillMount() {
        const { listar, leer_cliente, datos_cliente } = this.props;
        const { idClient = 0 } = this.props.match.params
        listar(1, idClient);
        if (Object.keys(datos_cliente.item).length === 0 || datos_cliente.item.id != idClient) {
            leer_cliente(idClient)
        }
    }

    render() {
        const { listar, onSortChange, eliminar, searchChange } = this.props; //Funciones
        const { data, loader, page, datos_cliente } = this.props; //Constantes
        const idClient = this.props.match.params.idClient
        const rutaEditar = `/client/${idClient}/car`
        return (
            <Card
                titulo="Vehículos"
                subTitulo={`${datos_cliente.item.name}`}
                header={
                    <HeaderBtnSearch
                        link={`${rutaEditar}/create`}
                        textBtn="Crear vehículo"
                        textBtn2="Salir"
                        link2="/clients"
                    //- - desactivate search
                    //     {...this.props}
                    // searchChange={(e) => {
                    //     searchChange(e, idClient)
                    // }}
                    />
                }
            >
                <Grid hover striped data={data} loading={loader}
                    onPageChange={(e) => {
                        listar(e, idClient)
                    }}
                    onSortChange={(e) => {
                        onSortChange(e, idClient)
                    }}
                    page={page}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width='15%'
                        dataFormat={standardActions({
                            editar: rutaEditar,
                            eliminar: (e) => {
                                eliminar(e, idClient)
                            },
                            ordenes_facturadas: rutaEditar,
                        })}
                    />
                    <TableHeaderColumn
                        dataField="brand"
                        dataFormat={(cell) => cell ? `${cell.name}` : ""}
                    >
                        MARCA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="model"
                        dataFormat={(cell) => cell ? `${cell.name}` : ""}
                    >
                        MODELO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="year"
                    >
                        AÑO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="vin"
                    >
                        VIN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="car_registration"
                    >
                        MATRÍCULA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="insurance_carrier"
                        dataFormat={(cell) => cell ? `${cell.name}` : ""}
                    >
                        ASEGURADORA
                    </TableHeaderColumn>

                </Grid>
            </Card>
        )
    }
}
