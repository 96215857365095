
import _ from "lodash";
export const formData = (_data) => {
    let data = _.cloneDeep(_data)
    let arrayFiles = [];
    if (data && data.files && Array.isArray(data.files)) {
        data.files.forEach((item, index) => item.file && arrayFiles.push({ "file": item.file, "name": `files-${index}` }))
        data.files.forEach(function (item) {
            delete item.file
            delete item.src
        })
    }

    if (data && data.car_inventory && data.car_inventory.photos && Array.isArray(data.car_inventory.photos)) {
        data.car_inventory.photos.forEach((item, index) => item.file && arrayFiles.push({ "file": item.file, "name": `photos-${index}` }))
        data.car_inventory.photos.forEach(function (item) {
            delete item.file
            delete item.src
        })
    }

    if ( data && data.diagnosis_photos && Array.isArray(data.diagnosis_photos))  {
        
        data.diagnosis_photos.forEach((item, index) => {
            item.file && arrayFiles.push({"file": item.file, "name": `diagnosis_photos-${index}`})})
        data.diagnosis_photos.forEach(function(item) {
            delete item.file
            delete item.src
        })
    }

    return { data, arrayFiles }
}
