import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { STATUS_ORDER_BODY_SHOP } from '../../../../utility/constants';
import { NotificationManager } from 'react-notifications';
import { push } from 'react-router-redux';
import { initialize as initializeForm, destroy } from "redux-form";

const LOADER = 'LOADER_ENDEREZADOS_PINTURAS';
const DATA = 'DATA_ENDEREZADOS_PINTURAS';
const DATA_FACTURA = 'DATA_FACTURA_ENDEREZADOS_PINTURAS';
const ITEM_DATA = 'ITEM_ENDEREZADOS_PINTURAS';
const ITEM_ORDER = 'ORDER_ENDEREZADOS_PINTURAS';
const PAGE = 'PAGE_ENDEREZADOS_PINTURAS';
const ORDERING = 'ORDERING_ENDEREZADOS_PINTURAS';
const SEARCH = 'SEARCH_ENDEREZADOS_PINTURAS';
const ESTADO_ENDEREZADO_PINTURA_TAB = 'ESTADO_ENDEREZADO_PINTURA_TAB';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setDataFactura = data_factura => ({
    type: DATA_FACTURA,
    data_factura,
});
const setData = data => ({
    type: DATA,
    data,
});
const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setOrder = order => ({
    type: ITEM_ORDER,
    order,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setEstadoEnderezadoPinturaTab = estado_enderezado_pintura_tab => ({
    type: ESTADO_ENDEREZADO_PINTURA_TAB,
    estado_enderezado_pintura_tab,
});
// ------------------------------------
// Actions
// ------------------------------------
const timeHistory = (idWorkOrder) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`my_orders_body_shop/${idWorkOrder}/time_history`).then((response) => {
        dispatch(setDataFactura(response));
    }).catch(() => {
        dispatch(push('/body-shop-orders'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`work_orders/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch((error) => {
        let mensaje = 'Error en la eliminación, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data = {}, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.putAttachments(`work_orders/${id}`, data, attachments)
        .then(() => {
            NotificationManager.success('Registro actualizado', 'Éxito', 3000);
            dispatch(leer(id))
            if (data && data.close_order) {
                dispatch(push(`/work-order/${id}/order_closing/pdf`));
            } else {
                dispatch(push('/body-shop-orders'));
            }
        })
        .catch((error) => {
            let mensaje = 'Error en la edición, vuelve a intentarlo';
            if (error) {
                if (error.detail)
                    mensaje = error.detail;
            }
            NotificationManager.error(mensaje, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.type = 'order_body_shop'
    api.get(`${'work_orders'}/${id}`, params).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('WorkOrdersForm', response));
    }).catch(() => {
        dispatch(push('/body-shop-orders'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const idOrder = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.type = 'order_mechanic'
    api.get(`${'garage'}/${id}`, params).then((response) => {
        dispatch(setOrder(response));
        // dispatch(initializeForm('WorkOrdersForm', response));
    }).catch(() => {
        dispatch(push('/mechanic_orders'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (data = {}, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments('work_orders', data, attachments)
        .then(() => {
            NotificationManager.success('Registro creado', 'Éxito', 3000);
            dispatch(push('/body-shop-orders'));
        })
        .catch((error) => {
            let mensaje = 'Error en la creación, vuelve a intentarlo';
            if (error) {
                if (error.detail)
                    mensaje = error.detail;
            }
            NotificationManager.error(mensaje, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().adminEnderezadosPinturas;
    const params = { page, status: resource.estado_enderezado_pintura_tab };

    if (resource.ordering)
        params.ordering = resource.ordering;
    if (resource.search)
        params.search = resource.search;
    params.type = 'order_body_shop'
    dispatch(setLoader(true));
    api.get('work_orders', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const estadoEnderezadoPinturaTab = estado => (dispatch) => {
    dispatch(setEstadoEnderezadoPinturaTab(estado));
    dispatch(listar());
};


const searchChange = search => (dispatch, getStore) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().adminEnderezadosPinturas.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const destroyForm = () => (dispatch) => {
    dispatch(destroy('WorkOrdersForm'));
};



export const actions = {
    listar,
    searchChange,
    onSortChange,
    estadoEnderezadoPinturaTab,
    crear,
    leer,
    editar,
    eliminar,
    destroyForm,
    timeHistory,
    idOrder,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },

    [DATA_FACTURA]: (state, { data_factura }) => {
        return {
            ...state,
            data_factura,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [ITEM_ORDER]: (state, { order }) => {
        return {
            ...state,
            order,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [ESTADO_ENDEREZADO_PINTURA_TAB]: (state, { estado_enderezado_pintura_tab }) => {
        return {
            ...state,
            estado_enderezado_pintura_tab,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    order: {},
    page: 1,
    ordering: '',
    search: '',
    data_factura: {
        results: [],
    },
    estado_enderezado_pintura_tab: STATUS_ORDER_BODY_SHOP.NOT_ASSIGNED,
};

export default handleActions(reducers, initialState);
