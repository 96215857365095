import React, { Component, Fragment } from 'react'
import Card from '../../../Utils/Cards/Card'
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Listar from './Listar'
import { ORDER_MECHANIC, ORDER_BODY_SHOP, ORDER_APPRAISAL } from '../../../../../utility/constants'
// - - - - - - - - permisos
import { MECHANICS_ORDERS, BODY_SHOP_ORDERS, APPRAISAL_ORDERS } from '../../../../../utility/constants'

const desarrollo = require("assets/img/work_orders/icons_state/desarrollo.png");
const pendientes = require("assets/img/work_orders/icons_state/pendientes.png");
const finalizados = require("assets/img/work_orders/icons_state/finalizados.png");

export default class TabListar extends Component {
    componentWillMount() {
        const { me, listar, page, dashboardMyOrders } = this.props;
        const { permissions } = me || {}
        const convert = {
            'order_body_shop': 'body_shop_orders',
            'order_mechanic': 'mechanics_orders',
            'order_appraisal': 'appraisal_orders',
        }
        if (!permissions.includes(convert[this.props.estado_mis_ordenes_tab])) {
            console.log("aaa", this.props.estado_mis_ordenes_tab, ' permissions ', permissions)
            if (permissions.includes(MECHANICS_ORDERS)) {
                this.props.estadoMisOrdenesTab(ORDER_MECHANIC)
            } else {
                if (permissions.includes(BODY_SHOP_ORDERS)) {
                    this.props.estadoMisOrdenesTab(ORDER_BODY_SHOP)
                } else {
                    if (permissions.includes(APPRAISAL_ORDERS)) {
                        this.props.estadoMisOrdenesTab(ORDER_APPRAISAL)
                    }
                }
            }
        } else {
            listar(page);
            dashboardMyOrders();
        }
    }

    render() {
        const { me, data_dashboard } = this.props; //Funciones
        const { permissions } = me || {}
        return (
            <Fragment>
                <div className="mb-1 mb-md-2">
                    <h1 className="text-45 medium d-inline my-0">Mis Ordenes </h1>
                </div>
                <div className="w-100 h-100 d-flex justify-content-between flex-column flex-lg-row">
                    <div className="px-0 d-flex flex-1 mx-0 mx-sm-4 ml-lg-0 flex-column" >
                        <div className="px-4 d-flex bg-blanco my-3 py-2" style={{ border: "1px solid #487E95", boxShadow: "0px 0px 7px #00000066", borderRadius: 15 }}>
                            <img src={desarrollo} style={{ width: 30 }} className="my-auto" alt="" />
                            <span className="text-25 negro mt-auto my-auto px-3">DESARROLLO </span>
                            <span className="text-50 bold mx-auto">{data_dashboard.total_development || 0}</span>
                        </div>
                    </div>
                    <div className="px-0 d-flex flex-1 mx-0 mx-sm-4 mx-md-4 flex-column" >
                        <div className="px-4 d-flex bg-blanco my-3 py-2" style={{ border: "1px solid #EF4339", boxShadow: "0px 0px 7px #00000066", borderRadius: 15 }}>
                            <img src={pendientes} style={{ width: 30 }} className="my-auto" alt="" />
                            <span className="text-25 negro mt-auto my-auto px-3">PENDIENTES </span>
                            <span className="text-50 bold mx-auto">{data_dashboard.total_pending || 0}</span>
                        </div>
                    </div>
                    <div className="px-0 d-flex flex-1 mx-0 mx-sm-4 mr-lg-0 flex-column" >
                        <div className="px-4 d-flex bg-blanco my-3 py-2" style={{ border: "1px solid #7EA04D", boxShadow: "0px 0px 7px #00000066", borderRadius: 15 }}>
                            <img src={finalizados} style={{ width: 30 }} className="my-auto" alt="" />
                            <span className="text-25 negro mt-auto my-auto px-3">FINALIZADOS </span>
                            <span className="text-50 bold mx-auto">{data_dashboard.total_finalized || 0}</span>
                        </div>
                    </div>
                </div>
                <Card
                >
                    <Tabs
                        tabBarPosition="top"
                        onChange={(tab) => this.props.estadoMisOrdenesTab(tab)}
                        renderTabBar={() => <ScrollableInkTabBar />}
                        renderTabContent={() => <TabContent />}
                        activeKey={String(this.props.estado_mis_ordenes_tab)}
                    >
                        {permissions.includes(MECHANICS_ORDERS) &&
                            <TabPane TabPane
                                tab="MECÁNICAS"
                                key={ORDER_MECHANIC}
                            >
                                <Listar {...this.props} />
                            </TabPane>
                        }

                        {permissions.includes(BODY_SHOP_ORDERS) &&
                            <TabPane
                                tab="ENDEREZADO Y PINTURA"
                                key={ORDER_BODY_SHOP}
                            >
                                <Listar {...this.props} />
                            </TabPane>
                        }

                        {permissions.includes(APPRAISAL_ORDERS) &&
                            <TabPane
                                tab="AVALÚO"
                                key={ORDER_APPRAISAL}
                            >
                                <Listar {...this.props} />
                            </TabPane>
                        }
                    </Tabs>
                </Card>
            </Fragment >

        )
    }
}
