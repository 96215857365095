import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { push } from "react-router-redux";
import { NotificationManager } from 'react-notifications';
import { initialize as initializeForm } from "redux-form";


const LOADER = 'LOADER_ENDEREZADO_PINTURA';
const DATA = 'DATA_ENDEREZADO_PINTURA';
const DATA_TASK = 'DATA_TASK_ENDEREZADO_PINTURA';
const ITEM_DATA = 'ITEM_ENDEREZADO_PINTURA';
const PAGE = 'PAGE_ENDEREZADO_PINTURA';
// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});
const setDataTask = data_task => ({
    type: DATA_TASK,
    data_task,
});


const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const clearSetItem = () => (dispatch) => {
    dispatch(setItem({}));
}


const flujoPorFacturar = (idWorkOrder) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`my_orders_appraisal/${idWorkOrder}/flow_to_check_in`).then(() => {
        NotificationManager.success('Flujo finalizado correctamente', 'Éxito', 3000);
        dispatch(push('/mywork-orders'));
    }).catch((error) => {
        let mensaje = 'Error al finalizar el flujo, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}
const listarTask = (idWorkOrder) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`my_orders_appraisal/${idWorkOrder}/list_tasks`).then((response) => {
        dispatch(setDataTask(response));
    }).catch(() => {
        dispatch(push('/mywork-orders'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const createOrder = (idWorkOrder, type_order) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`my_orders_appraisal/${idWorkOrder}/create_order`, { type_order }).then(() => {
        NotificationManager.success('Orden creada correctamente', 'Éxito', 3000);
    }).catch((error) => {
        let mensaje = "Error al crear la orden, vuelve a intentarlo"
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        dispatch(listarTask(idWorkOrder))
    });
}

const accionTask = (idWorkOrder, idTask, action_tracking) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`my_orders_appraisal/${idWorkOrder}/action_tracking_task`, { idTask, action_tracking }).then(() => {
        let texto = ''
        if (action_tracking == 'play')
            texto = "Tarea iniciada correctamente"
        if (action_tracking == 'pause')
            texto = "Tarea pausada correctamente"
        if (action_tracking == 'finalized')
            texto = "Tarea finalizada correctamente"
        NotificationManager.success(texto, 'Éxito', 3000);
    }).catch((error) => {
        let mensaje = ''
        console.log("action_tracking", action_tracking)
        if (action_tracking == 'play')
            mensaje = "Error al iniciar la tarea, vuelve a intentarlo"
        if (action_tracking == 'pause')
            mensaje = "Error al pausar la tarea, vuelve a intentarlo"
        if (action_tracking == 'finalized')
            texto = "Error al finalizar la tarea, vuelve a intentarlo"
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        dispatch(listarTask(idWorkOrder))
    });
}

export const actions = {
    listarTask,
    clearSetItem,
    accionTask,
    createOrder,
    flujoPorFacturar,
};


// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [DATA_TASK]: (state, { data_task }) => {
        return {
            ...state,
            data_task,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data_task: {
        results: [],
    },
    item: {},
    page: 1,
};

export default handleActions(reducers, initialState);
