import React, { Component, Fragment } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import {
    RenderCurrency,
} from '../../../Utils/renderField/renderReadField'
import moment from 'moment';
import Card from '../../../Utils/Cards/Card'
import { HeaderSearch } from '../../../Utils/Header'


export default class Listar extends Component {
    componentWillMount() {
        const { listarOrdenes, page_ordenes } = this.props;
        const { id = 0 } = this.props.match.params
        listarOrdenes(page_ordenes, id);
    }

    render() {
        const { listarOrdenes } = this.props;
        const { data_ordenes, loader, page_ordenes, total_amount } = this.props;
        const id_car = this.props.match.params.id
        return (
            <Card
                titulo="ORDENES FACTURADAS"
                header={
                    <HeaderSearch
                        search={this.props.search_ordenes}
                        searchChange={(e) => {
                            this.props.searchChangeOrdenes(e, id_car)
                        }}
                        placeholder=""
                    />
                }

            >
                <Grid hover striped data={data_ordenes} loading={loader} onPageChange={(e) => { listarOrdenes(e, id_car) }} page={page_ordenes}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width='40PX'
                        dataFormat={standardActions({ ver_factura: "back" })}
                    />
                    <TableHeaderColumn
                        dataField="client"
                        dataFormat={cell => cell.name}

                    >
                        CLIENTE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="order_number"
                    >
                        NUMERO ORDEN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="order_type"
                    >
                        TIPO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="client"
                        dataFormat={cell => cell.email}
                    >
                        E-MAIL
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="client"
                        dataFormat={cell => cell.phone_number}
                    >
                        TELÉFONO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="car"
                        dataFormat={cell => cell.name}
                    >
                        VEHÍCULO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="creation_date"
                        dataFormat={cell => moment(cell).format('DD-MM-YYYY')}
                    >
                        INGRESO
                    </TableHeaderColumn>
                    {total_amount &&
                        <TableHeaderColumn
                            dataField="total_amount"
                            dataFormat={cell => <RenderCurrency value={cell || 0} />}

                        >
                            TOTAL FACTURADO
                        </TableHeaderColumn>
                    }
                </Grid>
            </Card>
        )
    }
}
