import { createAction, handleActions } from 'redux-actions';
import { api } from 'api';
import { initialize as initializeForm, change, destroy } from 'redux-form';
import { NotificationManager } from 'react-notifications';
import { push } from 'react-router-redux';
import { SWALMOD } from '../../../utility/constants'
const warningSvg = require('assets/img/icons/warning.svg');

const SET_LOADER = 'SET_LOADER';
const SET_DATA = 'SET_DATA';
const SET_ITEM = 'SET_ITEM';

const setLoader = loader => ({
    type: SET_LOADER,
    loader: loader,
});

const setData = data => ({
    type: SET_DATA,
    data: data,
});

const setItem = item => ({
    type: SET_ITEM,
    item: item,
});

const update = data => dispatch => {
    dispatch(setLoader(true));
    api.put(`quotation/update_data`, data)
        .then(response => {
            NotificationManager.success('Enviado correctamente', 'Éxito', 3000);
        })
        .catch(error => {
            let mensaje = 'Token no valido';
            if (error) {
                if (error.detail) mensaje = error.detail;
            }
            NotificationManager.error(mensaje, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(push('/'));
            dispatch(setLoader(false));
        });
};

const validate_token = token => dispatch => {
    console.log('token', token);
    dispatch(setLoader(true));
    api.get(`quotation/validate_token`, { token })
        .then(response => {
            dispatch(setData(response));
        })
        .catch(error => {
            let mensaje = 'Token no valido';
            if (error) {
                if (error.detail) mensaje = error.detail;
            }
            NotificationManager.error(mensaje, 'ERROR', 3000);
            dispatch(push('/'));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const leer = id => dispatch => {
    dispatch(setLoader(true));
    api.get(`quotation/${id}`)
        .then(response => {
            dispatch(setData(response));
            // Se usa un formulario distinto para las cotizaciones de mecánica y de enderezado
            // Se inicializa determinado formulario en función de esa diferencia
            if (response.work_order_type == 'mechanic') {
                dispatch(initializeForm('CotizacionForm', response.results));
            }
            else {
                dispatch(initializeForm('CotizacionEnderezadoForm', response.results))
            }
        })
        .catch(() => {
            dispatch(push('/'));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const leerControlCalidad = id => dispatch => {
    dispatch(setLoader(true));
    api.get(`quotation/${id}/quality_control`)
        .then(response => {
            dispatch(initializeForm('ControlCalidadForm', response));
        })
        .catch(() => {
            dispatch(push('/'));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const actualizarControlCalidad = (id, data) => dispatch => {
    dispatch(setLoader(true));
    api.put(`quotation/${id}/update_quality_control`, data)
        .then(() => {
            NotificationManager.success('Registro actualizado', 'Éxito', 3000);
            dispatch(push('/mechanic_orders'));
        })
        .catch(error => {
            let mensaje = 'Error en la edición, vuelve a intentarlo';
            if (error) {
                if (error.detail) mensaje = error.detail;
            }
            NotificationManager.error(mensaje, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const listar = id => dispatch => {
    dispatch(setLoader(true));
    api.get(`quotation/${id}/listQuotation`)
        .then(response => {
            dispatch(setItem(response));
            dispatch(
                initializeForm('CotizacionProgresoForm', { data: response })
            );
        })
        .catch(() => {
            dispatch(push('/'));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const editar = (id, data) => (dispatch, getStore) => {
    let isMechanic = getStore().cotizaciones.data.work_order_type === 'mechanic'
    api.put(`quotation/${id}/save_quotation`, data)
        .then(() => {
            let texto = 'Registro actualizado';
            if (data.send_client) {
                texto = 'Cotización enviada';
            }
            if (data.end_flow) {
                texto = 'Cotización finalizada';
            }
            NotificationManager.success(texto, 'Éxito', 3000);
            if (data.end_flow) {
                console.log('aaa', data.end_flow);
                if (isMechanic) {
                    dispatch(push(`/mechanic_order/${id}/in_progress`));
                }
                else {
                    dispatch(push('/body-shop-orders'))
                }
            } else if (data.print_quotation) {
                dispatch(push(`/mechanic_order/${id}/quotation/pdf`));
            } else if (!data.send_client) {
                if (isMechanic) {
                    dispatch(push('/mechanic_orders'));
                }
                else {
                    dispatch(push('/body-shop-orders'))
                }
            }
            dispatch(leer(id));
        })
        .catch(error => {
            let mensaje = 'Error en la edición, vuelve a intentarlo';
            if (error) {
                if (error.detail) mensaje = error.detail;
            }
            NotificationManager.error(mensaje, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const rejectQuotation = (id) => (dispatch, getStore) => {
    SWALMOD.fire({
        title: '¿Rechazar?',
        text: 'La cotización regresará a diagnóstico',
        showCancelButton: true,
        confirmButtonText: '¡Sí, rechazar!',
        cancelButtonText: 'No',
        reverseButtons: true,
        imageUrl: warningSvg,
        imageAlt: 'Alerta',
        imageWidth: 90,
    }).then(result => {
        if (result.value) {
            dispatch(setLoader(true));
            api.put(`quotation/${id}/reject_quotation`)
            .then(() => {
                NotificationManager.success('Rechazada correctamente', 'Éxito', 3000);
                if (getStore().cotizaciones.data.work_order_type === 'mechanic') {
                    dispatch(push('/mechanic_orders'));
                } else {
                    dispatch(push('/body-shop-orders'))
                }
            })
            .catch(error => {
                let mensaje = 'Error al rechazar, vuelve a intentarlo';
                if (error) {
                    if (error.detail) mensaje = error.detail;
                }
                NotificationManager.error(mensaje, 'ERROR', 3000);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
        }
    });
};

const leerQuotation = id => dispatch => {
    dispatch(setLoader(true));
    return new Promise((resolve, reject) => {
        api.get(`quotation/${id}/print_quotation`)
            .then(response => {
                console.log('ress', response);
                resolve(response);
            })
            .catch(error => {
                dispatch(push('/mechanic_orders'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }).finally(() => {});
};

const save_quotation_mechanic = (id, data) => dispatch => {
    dispatch(setLoader(true));
    api.put(`quotation/${id}/quotation_assigned_mechanic`, data)
        .then(() => {
            NotificationManager.success('Registro actualizado', 'Éxito', 3000);
            dispatch(listar(id));
        })
        .catch(error => {
            let mensaje = 'Error en la edición, vuelve a intentarlo';
            if (error) {
                if (error.detail) mensaje = error.detail;
            }
            NotificationManager.error(mensaje, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const sendEmailPartsList = data => dispatch => {
    api.post(`quotation/send_email_parts_list`, data)
        .then(() =>
            NotificationManager.success(
                'Correo electrónico enviado satisfactoriamente.',
                'Éxito',
                3000
            )
        )
        .catch(() =>
            NotificationManager.success(
                'Verifique el correo electrónico.',
                'ERROR',
                3000
            )
        );
};

const descargarReporte = (id) => (dispatch, getStore) => {
    console.log("🚀 ~ descargarReporte ~ id:", id)
    const resource = getStore().reportes;
    let params = `api/report_excel_cotizacion/?auth_token=${localStorage.getItem("token")}`;
    params += `&id=${id}`;

    window.location.replace(params);
};

export const actions = {
    leer,
    editar,
    rejectQuotation,
    validate_token,
    update,
    listar,
    save_quotation_mechanic,
    leerControlCalidad,
    actualizarControlCalidad,
    leerQuotation,
    sendEmailPartsList,
    descargarReporte
};

export const reducers = {
    [SET_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SET_ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
};

export const initialState = {
    loader: false,
    data: [],
    item: [],
};

export default handleActions(reducers, initialState);
