import React, { Component, Fragment } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import PasoUnoForm from './PasoUnoForm';
import PasoDosForm from './PasoDosForm';
import PasoTresForm from './PasoTresForm';

export default class Crear extends Component {
    state = {
        editar: false,
        step: 1,
    };

    componentWillMount() {
        this.props.destroyForm()
        if (this.props.match.params.id) {
            this.props.leer(this.props.match.params.id);
            this.setState({ editar: true });
        }
    }

    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    };


    previousStep = () => {
        this.setState({ step: this.state.step - 1 })
    };


    create = (_data) => {
        let data = { ..._data }
        this.props.crear(data);
    }
    edit = (_data) => {
        let data = { ..._data }
        this.props.editar(this.props.match.params.id, data)
    };


    render() {
        //Value
        const { loader, item } = this.props;
        const { editar, step } = this.state
        return (
            <CardForm titulo={editar ? "EDITAR CLIENTE" : "AGREGAR CLIENTE"}>
                <div className="d-flex justify-content-center align-items-center mb-3">
                    <h5 className="text-20 text-bold">
                        {step === 1 && "DATOS PERSONALES"}
                        {step === 2 && "DATOS FACTURACIÓN"}
                        {step === 3 && "VEHÍCULOS CLIENTE"}
                    </h5>
                </div>
                <div className="d-flex justify-content-center align-items-center mb-3">
                    <div className="circulo text-bold text-center circulo circulo-activo">1</div>
                    {editar ?
                        <div className={`circulo text-bold text-center circulo ml-5 ${step > 1 && "circulo-activo"}`}>2</div>
                        :
                        <Fragment>
                            <div className={`mx-5 circulo text-bold text-center circulo ${step > 1 && "circulo-activo"}`}>2</div>
                            <div className={`circulo text-bold text-center circulo ${step > 2 && "circulo-activo"}`}>3</div>
                        </Fragment>
                    }

                    <div className="m2 position-absolute" style={{ zIndex: 70, borderTop: "solid 0px #000", width: editar ? 90 : 130, borderBottom: "solid 2px #000" }} />
                </div>
                <LoadMask loading={loader} blur>
                    {
                        editar ?
                            <Fragment>
                                {step === 1 &&
                                    <PasoUnoForm
                                        onSubmit={this.nextStep}
                                    />
                                }
                                {step === 2 && (
                                    <PasoDosForm
                                        onSubmit={this.edit}
                                        previousStep={this.previousStep}
                                        editar
                                    />
                                )}
                            </Fragment>
                            :
                            <Fragment>
                                {step === 1 &&
                                    <PasoUnoForm
                                        onSubmit={this.nextStep}
                                    />
                                }
                                {step === 2 && (
                                    <PasoDosForm
                                        onSubmit={this.nextStep}
                                        previousStep={this.previousStep}
                                        creando
                                    />
                                )}
                                {step === 3 && (
                                    <PasoTresForm
                                        onSubmit={this.create}
                                        previousStep={this.previousStep}
                                    />
                                )}
                            </Fragment>
                    }
                </LoadMask>
            </CardForm>
        )
    }
}
