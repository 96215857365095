import React, { Component, Fragment } from 'react';
import CardFormOrden from 'Utils/Cards/CardFormOrden';
import LoadMask from 'Utils/LoadMask/LoadMask';
import { StandardActionsTracking } from '../../../../Utils/Grid/StandardActionsTracking';
import {
    LABEL_STATUS_ORDER_BODY_SHOP,
    STATUS_ORDER_BODY_SHOP,
} from '../../../../../../utility/constants';
import SelectFlujosBodyShopForm from './SelectFlujos';
import ListarTasks from './ListarTasks';
import ControlCalidad from './ControlCalidad';
import CarWash from './CarWash';
import './index.css';
import Item from 'antd/lib/list/Item';
export default class Crear extends Component {
    state = {
        step: 1,
    };
    componentWillMount() {
        if (this.props.match.params.id) {
            this.props.leer(this.props.match.params.id);
        }
    }
    componentWillUnmount() {
        this.props.clearSetItem();
    }

    nextStep = () => {
        this.setState({ step: this.state.step + 1 });
    };

    previousStep = () => {
        this.setState({ step: this.state.step - 1 });
    };

    create = _data => {
        let data = { ..._data };
        this.props.crear(data);
    };

    crearTask = _data => {
        let data = { ..._data };
        let new_task = [];
        let id_task_existent = [];
        if (data && data.parts_active) {
            const parts_active = data.parts_active;
            for (const item in parts_active) {
                if (parts_active[item]) {
                    if (parts_active[item].id)
                        id_task_existent.push(parts_active[item].id);
                    else if (parts_active[item].part_identifier)
                        new_task.push(parts_active[item]);
                }
            }
        }
        const { select_parts_tasks, item } = this.props;
        const status = item.order_body_shop.status || 0;
        const search_flow =
            status != 0
                ? LABEL_STATUS_ORDER_BODY_SHOP[item.order_body_shop.status]
                      .search
                : undefined;
        select_parts_tasks(
            { id_task_existent, new_task },
            item.id,
            search_flow
        ).finally(() => {
            this.nextStep();
        });
    };

    accion_tracking(id_task, accion) {
        const { item, accionTask } = this.props;
        const status = item.order_body_shop.status || 0;
        const search_flow =
            status != 0
                ? LABEL_STATUS_ORDER_BODY_SHOP[item.order_body_shop.status]
                      .search
                : undefined;
        accionTask(item.id, search_flow, id_task, accion);
    }

    render() {
        const { loader, item, listarPartesActivas, data_task } = this.props;
        const { order_body_shop, car } = item || {};
        const { status = 0, description = '' } = order_body_shop || {};
        const title_flow =
            status != 0 ? LABEL_STATUS_ORDER_BODY_SHOP[status].label : '';
        const search_flow =
            status != 0 ? LABEL_STATUS_ORDER_BODY_SHOP[status].search : '';
        const { status_in_action = {} } = data_task || {};

        return (
            <Fragment>
                <LoadMask loading={loader} blur>
                    <CardFormOrden
                        orden={item && item.order_number}
                        timer={
                            data_task &&
                            (status == STATUS_ORDER_BODY_SHOP.QUALITY_CONTROL ||
                                status == STATUS_ORDER_BODY_SHOP.CAR_WASH) ? (
                                <div
                                    className="mt-1 mt-md-2 d-flex"
                                    style={{ height: 25 }}
                                >
                                    {StandardActionsTracking({
                                        play: status_in_action.play,
                                        no_ver_finalized: true,
                                        finalized: status_in_action.finalized,
                                        onChange:
                                            this.accion_tracking.bind(this),
                                    })(data_task.id)}
                                    <span className="text-18 ml-3 my-auto">
                                        {data_task.time}
                                    </span>
                                </div>
                            ) : (
                                ''
                            )
                        }
                    >
                        {status > STATUS_ORDER_BODY_SHOP.NOT_ASSIGNED &&
                            status < STATUS_ORDER_BODY_SHOP.QUALITY_CONTROL &&
                            item && (
                                <Fragment>
                                    <div className="row">
                                        <div className="col-6">
                                            <span className="negro bold text-25 d-block">
                                                Enderezado y pintura
                                            </span>
                                            <p className="text-16">
                                                {description}
                                            </p>
                                            <ul className="text-16">
                                                {
                                                    item.tasks.map((item, index) => {
                                                        console.log(item)
                                                        if (item.quotation !== null) {
                                                            return (
                                                                (item.quotation.approved_provider1 || item.quotation.approved_provider2 || item.quotation.approved_provider3) &&
                                                                <li key={item.id}>{`${item.quotation.replacement}: ${item.quotation.name_task}`}</li>
                                                            )
                                                        }
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="col-6">
                                            <div className="card padding-custom mb-3">
                                                <div style={{ padding: 20 }}>
                                                    <h3 className="card-title">
                                                        INFORMACIÓN DEL VEHÍCULO
                                                    </h3>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div
                                                                style={{
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                VIN:&nbsp;
                                                            </div>
                                                            {car
                                                                ? car.vin
                                                                : '---'}
                                                        </div>
                                                        <div className="row">
                                                            <div
                                                                style={{
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                MATRÍCULA:&nbsp;
                                                            </div>
                                                            {car
                                                                ? car.car_registration
                                                                : '---'}
                                                        </div>
                                                        <div className="row">
                                                            <div
                                                                style={{
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                MARCA:&nbsp;
                                                            </div>
                                                            {car
                                                                ? car.brand.name
                                                                : '---'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <span className="negro bold text-25">
                                        {title_flow}
                                    </span>
                                    {this.state.step == 1 && (
                                        <SelectFlujosBodyShopForm
                                            listarPartesActivas={
                                                listarPartesActivas
                                            }
                                            work_order_data_id={item.id}
                                            search_flow={search_flow}
                                            onSubmit={this.crearTask}
                                        />
                                    )}
                                    {this.state.step == 2 && (
                                        <ListarTasks
                                            work_order_data_id={item.id}
                                            search_flow={search_flow}
                                            previousStep={this.previousStep}
                                        />
                                    )}
                                </Fragment>
                            )}
                        {status == STATUS_ORDER_BODY_SHOP.QUALITY_CONTROL && (
                            <Fragment>
                                <div className="row">
                                    <div className="col-6">
                                        <span className="negro bold text-25 d-block">
                                            Enderezado y pintura
                                        </span>
                                        <p className="text-16">
                                            {description}
                                        </p>
                                        <ul className="text-16">
                                            {
                                                item.tasks.map((item, index) => {
                                                    if (item.quotation !== null) {
                                                        if (item.quotation.approved_provider1 || item.quotation.approved_provider2 || item.quotation.approved_provider3){
                                                            return <li key={item.id}>{`${item.quotation.replacement}: ${item.quotation.name_task}`}</li>
                                                        }
                                                    }
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="col-6">
                                        <div className="card padding-custom mb-3">
                                            <div style={{ padding: 20 }}>
                                                <h3 className="card-title">
                                                    INFORMACIÓN DEL VEHÍCULO
                                                </h3>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >
                                                            VIN:&nbsp;
                                                        </div>
                                                        {car ? car.vin : '---'}
                                                    </div>
                                                    <div className="row">
                                                        <div
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >
                                                            MATRÍCULA:&nbsp;
                                                        </div>
                                                        {car
                                                            ? car.car_registration
                                                            : '---'}
                                                    </div>
                                                    <div className="row">
                                                        <div
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >
                                                            MARCA:&nbsp;
                                                        </div>
                                                        {car
                                                            ? car.brand.name
                                                            : '---'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <span className="negro bold text-25">
                                    {title_flow}
                                </span>
                                <ControlCalidad
                                    work_order_data_id={item.id}
                                    search_flow={search_flow}
                                    status_in_action={status_in_action}
                                />
                            </Fragment>
                        )}
                        {status == STATUS_ORDER_BODY_SHOP.CAR_WASH && (
                            <Fragment>
                                <div className="row card-form justify-content-center align-items-center">
                                    <div className="card padding-custom mb-3">
                                        <div style={{ padding: 20 }}>
                                            <h3 className="card-title">
                                                INFORMACIÓN DEL VEHÍCULO
                                            </h3>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div
                                                        style={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        VIN:&nbsp;
                                                    </div>
                                                    {car ? car.vin : '---'}
                                                </div>
                                                <div className="row">
                                                    <div
                                                        style={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        MATRÍCULA:&nbsp;
                                                    </div>
                                                    {car
                                                        ? car.car_registration
                                                        : '---'}
                                                </div>
                                                <div className="row">
                                                    <div
                                                        style={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        MARCA:&nbsp;
                                                    </div>
                                                    {car
                                                        ? car.brand.name
                                                        : '---'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="negro bold text-25">
                                    {title_flow}
                                </span>
                                <CarWash
                                    work_order_data_id={item.id}
                                    search_flow={search_flow}
                                />
                            </Fragment>
                        )}
                    </CardFormOrden>
                </LoadMask>
            </Fragment>
        );
    }
}
