import React, { Component, Fragment } from 'react'
import Grid from "../../../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../../../Utils/Grid/StandardActions";
import { ButtonSalirGuardar } from '../../../../../Utils/Button'
import { StandardActionsTracking } from "../../../../../Utils/Grid/StandardActionsTracking";
import ModalAddPart from './ModalAddPart'
import Modal from "../../../../../Utils/Modal/ReactModal";
import defaultLogo from 'assets/img/place_holder/image_placeholder_small.png'
const warningSvg = require('assets/img/icons/warning.svg');
import { SWALMOD } from '../../../../../../../utility/constants'
export default class Listar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            dataInitial: {},
            editando: false,
        };
    }
    componentWillMount() {
        const { listarTask, work_order_data_id, search_flow } = this.props;
        listarTask(work_order_data_id, search_flow);
    }
    guardarNuevaParte(data) {
        this.setState({ openModal: false })
        const { crearTask, work_order_data_id, search_flow, actualizarTask } = this.props;
        if (this.state.editando) {
            this.setState({ dataInitial: {}, editando: false })
            actualizarTask(data, work_order_data_id, search_flow)
        } else {
            crearTask(data, work_order_data_id, search_flow)
        }
    }
    finalizarFlujo() {
        SWALMOD.fire({
            title: '¿Finalizar el flujo?',
            text: `¡Ya no podrás modificar el estado de tus tareas!${this.props.data_task.completed_tasks ? "." : ", aún tienes tareas sin finalizar."} `,
            showCancelButton: true,
            confirmButtonText: '¡Sí, finalizar!',
            cancelButtonText: 'No, finalizar',
            reverseButtons: true,
            imageUrl: warningSvg,
            imageAlt: 'Alerta',
            imageWidth: 90,
        }).then((result) => {
            if (result.value) {
                const { finalizarFlujo, work_order_data_id, search_flow } = this.props;
                finalizarFlujo(work_order_data_id, search_flow)
            }
        });
    }
    F_editar(id) {
        const { data_task } = this.props
        const { name = '', description = '', part_identifier = 'no' } = data_task.results.filter((item) => item.id == id)[0]
        this.setState({ dataInitial: { name, description, part_identifier, id }, openModal: true, editando: true })
    }
    accion_tracking(id_task, accion) {
        const { accionTask, work_order_data_id, search_flow } = this.props;
        accionTask(work_order_data_id, search_flow, id_task, accion)
    }
    render() {
        const { data_task, loader, previousStep } = this.props; //Constantes

        return (
            <Fragment>
                <Modal
                    show_modal={this.state.openModal}
                >
                    <div className='card-content-form2 px-0 px-0'>
                        <div className="col-12 col-lg-8 px-0">
                            <ModalAddPart
                                showModal={() => {
                                    this.setState({ openModal: false, dataInitial: {}, editando: false, })
                                }}
                                onSubmit={(e) => {
                                    this.guardarNuevaParte(e)
                                }}
                                initialValues={{ modalOpen: true, ...this.state.dataInitial }}
                                editarNombre={this.state.editando && !this.state.dataInitial.part_identifier}
                                editando={this.state.editando}
                            />
                        </div>
                    </div>
                </Modal>
                <Grid hover striped data={data_task} loading={loader} pagination={false}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width='110px'
                        dataFormat={(cell, row) => {
                            const { status_in_action = {} } = row
                            const { play = true, finalized = true } = status_in_action
                            return StandardActionsTracking({ play, finalized, onChange: this.accion_tracking.bind(this), })(cell, row)
                        }}
                    />

                    <TableHeaderColumn
                        dataField="part"
                        tdStyle={{ filter: 'none' }}
                        width='80px'
                        dataFormat={(cell, row) => <img src={cell || defaultLogo} height="30" />}
                    >
                        PARTE
                </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="name"
                        dataSort
                    >
                        NOMBRE
                </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="description"
                        dataSort
                    >
                        DESCRIPCIÓN
                </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="time"
                        dataSort
                        width='100px'
                    >
                        TIEMPO
                </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        width='90px'
                        dataFormat={standardActions({ F_editar: this.F_editar.bind(this) })}
                    >
                        ACCION
                </TableHeaderColumn>

                </Grid>
                <div className="d-flex justify-content-start mt-3">
                    <button
                        type="button"
                        className="btn btn-negro text-13 mt-3"
                        onClick={() => {
                            this.setState({ openModal: true, dataInitial: {}, editando: false })
                        }}
                    >
                        AGREGAR PARTE
                </button>
                </div>

                <ButtonSalirGuardar
                    link={previousStep}
                    btnText1="Anterior"
                    btnText2="Finalizar flujo"
                    link2={this.finalizarFlujo.bind(this)}
                />

            </Fragment >
        )
    }
}
