import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm'
import LoadMask from 'Utils/LoadMask/LoadMask'
import Formulario from './Formulario'

class Cotizacion extends Component {
  componentWillMount() {
    const id = this.props.match.params.id || 0
    this.props.leer(id)
  }

  update = (data, finalizar = true) => {
    if (finalizar) {
      this.props.editar(this.props.match.params.id, { data, end_flow: true })
    } else {
      this.props.editar(this.props.match.params.id, data)
    }
  }

  guardar = () => {
    this.update(this.props.data_cotizacion.values, false)
  }

  render() {
    const { loader, data } = this.props
    const id = this.props.match.params.id || 0
    return (
      <CardForm titulo="COTIZACIÓN" largo_contenedor="todo">
        <LoadMask loading={loader} blur>
          <Formulario onSubmit={this.update} {...this.props} data={data} guardar={this.guardar} id={id} />
        </LoadMask>
      </CardForm>
    )
  }
}

export default Cotizacion