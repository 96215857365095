import React, { Fragment, useState } from 'react'
import { reduxForm } from 'redux-form';
import { ButtonSalirGuardar } from '../../../Utils/Button'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import ModalCardForm from './ModalCardForm'
import Modal from "../../../Utils/Modal/ReactModal";
import { connect } from 'react-redux'
const alerta_img = require('assets/img/icons/warning.svg');
import { SWALMODERROR } from '../../../../../utility/constants'

let RegisterForm = (props) => {
    const { handleSubmit, previousStep, data_cars, loader, deleteCarForm } = props;
    const [openModal, setOpenModal] = useState(false)
    const [indexArray, setIndexArray] = useState(0)
    const [indexEditArray, setIndexEditArray] = useState('no')
    const guardar = (car) => {
        props.addCarForm(car)
        if (indexEditArray == 'no') {
            setIndexArray(indexArray + 1)
        } else {
            setIndexEditArray('no')
        }
        setOpenModal(false)

    }
    const eliminar = (id) => {
        setIndexArray(indexArray - 1)
        deleteCarForm(id)
    }
    const sendData = (data) => {
        data.preventDefault()
        if (data_cars.results.length > 0) {
            handleSubmit(data)
        } else {
            SWALMODERROR.fire({
                title: '¡Error!',
                text: '¡Debes de ingresar por lo menos un vehículo al cliente!',
                confirmButtonText: 'Regresar',
                reverseButtons: true,
                imageUrl: alerta_img,
                imageAlt: 'Alerta',
                imageWidth: 90,
            })
        }
    }
    return (
        <Fragment>
            <Modal
                show_modal={openModal}
            >
                <div className='card-content-form2 px-0 px-0'>
                    <div className="col-12 col-lg-8 px-0">
                        <ModalCardForm
                            showModal={() => {
                                if (indexEditArray != 'no')
                                    setIndexEditArray('no')
                                setOpenModal(false)
                            }}
                            indexArray={indexEditArray == 'no' ? indexArray : indexEditArray}
                            onSubmit={guardar}
                        />
                    </div>
                </div>
            </Modal>
            <form onSubmit={sendData} >
                <Grid className="mt-5" hover striped data={data_cars} loading={loader} pagination={false}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width='100PX'
                        dataFormat={standardActions({
                            fun_editar: (id) => {
                                setIndexEditArray(id)
                                setOpenModal(true)
                            }, eliminar
                        })}
                    />
                    <TableHeaderColumn
                        dataField="brand"
                        dataFormat={(cell) => cell ? `${cell.name}` : ""}
                    >
                        MARCA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="model"
                        dataFormat={(cell) => cell ? `${cell.name}` : ""}
                    >
                        MODELO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="year"
                    >
                        AÑO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="vin"
                    >
                        VIN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="car_registration"
                    >
                        MATRÍCULA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="insurance_carrier"
                        dataFormat={(cell) => cell ? `${cell.name}` : ""}
                    >
                        ASEGURADORA
                    </TableHeaderColumn>
                </Grid>

                <button
                    type="button"
                    className="btn btn-primary text-13 mt-3"
                    onClick={() => { setOpenModal(true) }}
                >
                    Agregar vehiculo
                </button>
                <ButtonSalirGuardar link={previousStep} btnText1="Anterior" btnText2="Guardar" />
            </form>
        </Fragment >
    );
};

RegisterForm = reduxForm({
    form: 'ClienteForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(RegisterForm);

import { actions } from '../../../../../redux/modules/clientes';
const md2p = { ...actions };
RegisterForm = connect(state => {
    const form = state.form.ClienteForm;
    let cars = [];
    if (form && form.values) {
        const values = form.values;
        if (values.cars)
            cars = values.cars
    }
    return {
        data_cars: {
            results: cars
        }
    }
}, md2p)(RegisterForm);
export default RegisterForm;
