import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    validate,
    validators,
    combine,
} from 'validate-redux-form';
import { renderPasswordField, renderNumber, renderField } from '../../Utils/renderField';
import { ButtonSalirGuardar } from '../../Utils/Button'
import { validationAdministrador } from '../../../../utility/validationForm'

const RegisterForm = (props) => {
    const { handleSubmit, previousStep } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form">

                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="proprietor.first_name">Nombre*</label>
                    <Field name="proprietor.first_name" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.first_name.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="proprietor.last_name">Apellido*</label>
                    <Field name="proprietor.last_name" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.last_name.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="proprietor.email">E-mail*</label>
                    <Field name="proprietor.email" component={renderField} type="email" className="form-control" maxLength={validationAdministrador.email.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="proprietor.phone_number">Teléfono*</label>
                    <Field name="proprietor.phone_number" component={renderNumber} type="text" className="form-control" isNumericString={true} maxLength={validationAdministrador.phone_number.MaxLength} />
                </div>

                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="proprietor.username">Username*</label>
                    <Field name="proprietor.username" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.username.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="proprietor.password">Contraseña*</label>
                    <Field name="proprietor.password" component={renderField} type="password" className="form-control" maxLength="151" />
                </div>

                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="proprietor.password_confirmation">Confirmar contraseña*</label>
                    <Field name="proprietor.password_confirmation" component={renderField} type="password" className="form-control" maxLength="151" />
                </div>
                <div className="col-md-6 padding-custom mb-3 d-flex">
                    <p className="text-right azul text-12 bold align-self-end ml-auto mb-0">* Campos requeridos</p>
                </div>
            </div>
            <ButtonSalirGuardar link={previousStep} btnText1="Anterior" btnText2="Siguiente" />
        </form>
    );
};


export default reduxForm({
    form: 'register', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: (data = {}) => {
        let errors = {};
        errors.proprietor = validate(data.proprietor || {}, {
            first_name: validationAdministrador.first_name.Combine,
            last_name: validationAdministrador.last_name.Combine,
            username: validationAdministrador.username.Combine,
            phone_number: validationAdministrador.phone_number.Combine,
            email: validationAdministrador.email.Combine,
            password: combine(
                validators.exists()('Campo requerido.'),
                validators.length({ min: 6 })('Mínimo 6 carácteres'),
                validators.length({ max: 150 })('Máximo 150 carácteres'),
            ),
            password_confirmation: combine(
                validators.exists()('Campo requerido.'),
                validators.equals(data.proprietor && data.proprietor.password)('La contraseña no coincide')
            ),
        })
        return errors
    },
})(RegisterForm);
