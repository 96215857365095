import React, { useState } from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { validate } from 'validate-redux-form';
import {
    renderField,
    renderCurrency,
    renderFieldCheck,
} from '../../../../Utils/renderField/renderField';
import { validationsOthers } from '../../../../../../utility/validationForm';
import { ButtonCotizacionEnviar } from '../../../../Utils/Button';
const warningSvg = require('assets/img/icons/warning.svg');
import { SWALMOD } from '../../../../../../utility/constants';
import Modal from '../../../../Utils/Modal/ReactModal';
import EmailModal from './EmailModal';
import { RenderDollar } from '../../../../Utils/renderField/renderReadField';

// const renderCotizacion = ({ fields, meta: { error, submitFailed }, data }) => {
const renderCotizacion = props => {
    const { data, colCostos, me } = props;

    // const sendMechanic = (data.results ? data.results[0].send_mechanic:false)
    const [total1, setTotal1] = useState(0)
    const [total2, setTotal2] = useState(0)
    const [total3, setTotal3] = useState(0)

     //probando permisos
    const { role_text = '' } = me || {};
    console.log('me formmmm', role_text);

    //permiso para editar si es un coso de taller, ESTO ES LO QUE HAY VER ODGFDG
    const isSuperiorAdmin = role_text === 'Administrador superior';
    const isSecundaryAdmin = role_text === 'Administrador secundario';
    const isMechanic = role_text === 'Mecánico Operador';
    const isCordinator = role_text === 'Coordinador Mecanica';
    const isAdministradorTotal = role_text === 'ADMINISTRADOR TOTAL';
    const isContador = role_text === 'CONTADOR';


    return (
        <div style={{ overflowX: 'auto' }}>
            <div className="table-responsive table-striped table-hover table-border-header">
                <table className="table">
                    <thead>
                        <tr className="blanco py-2 text-16 bold rounded">
                            <th
                                className="text-16 bold py-2 text-nowrap align-middle"
                                style={{ width: 90 }}
                            >
                                CANTIDAD
                            </th>
                            <th
                                className="text-16 bold py-2 text-nowrap align-middle"
                                style={{ width: 140 }}
                            >
                                REPUESTO
                            </th>
                            <th
                                className="text-16 bold py-2 text-nowrap align-middle"
                                style={{ width: 140 }}
                            >
                                NÚMERO DE PARTE
                            </th>
                            <th
                                className="text-16 bold py-2 text-nowrap align-middle"
                                style={{ width: 140 }}
                            >
                                DESCRIPCIÓN
                            </th>
                            <th
                                className="text-16 bold py-2 text-nowrap"
                                style={{ width: 150 }}
                            >
                                Proveedor #1
                            </th>
                            <th
                                className="text-16 bold py-2 text-nowrap align-middle"
                                style={{ width: 90 }}
                            >
                                ACEPTADO
                            </th>
                            <th
                                className="text-16 bold py-2 text-nowrap"
                                style={{ width: 150 }}
                            >
                                Proveedor #2
                            </th>
                            <th
                                className="text-16 bold py-2 text-nowrap align-middle"
                                style={{ width: 90 }}
                            >
                                ACEPTADO
                            </th>
                            <th
                                className="text-16 bold py-2 text-nowrap"
                                style={{ width: 150 }}
                            >
                                Proveedor #3
                            </th>
                            <th
                                className="text-16 bold py-2 text-nowrap align-middle"
                                style={{ width: 100}}
                            >
                                ACEPTADO
                            </th>
                            {colCostos && (isSuperiorAdmin || isSecundaryAdmin || isMechanic || isCordinator || isAdministradorTotal || isContador)&& (
                                <th
                                className="text-16 bold py-2 text-nowrap align-middle"
                                    style={{ width: 150 }}
                                >
                                    Costos
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {data.results &&
                            data.results.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td
                                            className="text-nowrap"
                                            style={{ textAlign: 'center' }}
                                        >
                                            {item.quantity}
                                        </td>
                                        <td>{item.replacement}</td>
                                        <td className="input-border-verde">
                                            {item.name_task}
                                        </td>
                                        <td className="input-border-verde">
                                            {item.description}
                                        </td>
                                        <td className="text-nowrap input-border-verde">
                                            <Field
                                                name={`${index}.provider1`}
                                                type="text"
                                                component={renderField}
                                                placeholder="Proveedor #1"
                                            />
                                            <Field
                                                name={`${index}.price_provider1`}
                                                component={renderCurrency}
                                                onChange={() =>{
                                                    item.approved_provider1 = false;
                                                    item.approved_provider2 = false;
                                                    item.approved_provider3 = false;
                                                }
                                                }
                                                setTotal={setTotal1}
                                                total={total1}
                                                totalTotal = {data.totalProveedor1}
                                            />
                                        </td>
                                        <td
                                            className="text-nowrap"
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Field
                                                name={`${index}.approved_provider1`}
                                                component={renderFieldCheck}
                                            />
                                        </td>
                                        <td className="text-nowrap input-border-verde">
                                            <Field
                                                name={`${index}.provider2`}
                                                type="text"
                                                component={renderField}
                                                placeholder="Proveedor #2"
                                            />
                                            <Field
                                                name={`${index}.price_provider2`}
                                                component={renderCurrency}
                                                onChange={() =>{
                                                    item.approved_provider1 = false;
                                                    item.approved_provider2 = false;
                                                    item.approved_provider3 = false;
                                                }
                                                }
                                                setTotal={setTotal2}
                                                total={total2}
                                                totalTotal = {data.totalProveedor2}
                                            />
                                        </td>
                                        <td
                                            className="text-nowrap"
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Field
                                                name={`${index}.approved_provider2`}
                                                component={renderFieldCheck}
                                            />
                                        </td>     
                                        <td className="text-nowrap input-border-verde">
                                            <Field
                                                name={`${index}.provider3`}
                                                type="text"
                                                component={renderField}
                                                placeholder="Proveedor #3"
                                            />
                                            <Field
                                                name={`${index}.price_provider3`}
                                                component={renderCurrency}
                                                onChange={() =>{
                                                    item.approved_provider1 = false;
                                                    item.approved_provider2 = false;
                                                    item.approved_provider3 = false;
                                                }
                                                }
                                                setTotal={setTotal3}
                                                total={total3}
                                                totalTotal = {data.totalProveedor3}
                                            />
                                        </td>
                                        <td
                                            className="text-nowrap"
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Field
                                                name={`${index}.approved_provider3`}
                                                component={renderFieldCheck}
                                            />
                                        </td>
                                        {colCostos && (isSuperiorAdmin || isSecundaryAdmin || isMechanic || isCordinator || isAdministradorTotal || isContador ) && (
                                            <td className="text-nowrap" style={{ textAlign: 'center' }}>
                                                <Field
                                                    name={`${index}.cost`}
                                                    type="number"
                                                    component={renderCurrency}
                                                    placeholder="Costo"
                                                    normalize={value => value === '' ? '' : parseFloat(value)}
                                                />
                                            </td>
                                        )}
                                    </tr>
                                );
                            })}
                    </tbody>
                    <br/>
                    <tfoot>
                        {/*style={{ fontWeight: "bold", fontSize: "125%", color: '#cd0a0a' }}*/}
                        <td colSpan={4}>
                            <span style={{ fontWeight: "bold", fontSize: "125%" }}>
                                TOTAL
                            </span>
                        </td>
                        <td colSpan={2}>
                            <RenderDollar value={total1!==0?total1:data.totalProveedor1 || 0} prefix={(data.data_garage?data.data_garage.currency:'') + " "} style={{ whiteSpace: 'normal', fontWeight: "bold", fontSize: "125%" }}/>
                        </td>

                        <td colSpan={2}>
                            <RenderDollar value={total2!==0?total2:data.totalProveedor2 || 0} prefix={(data.data_garage?data.data_garage.currency:'') + " "} style={{ whiteSpace: 'normal', fontWeight: "bold", fontSize: "125%" }}/>
                        </td>

                        <td colSpan={2}>
                            <RenderDollar value={total3!==0?total3:data.totalProveedor3 || 0} prefix={(data.data_garage?data.data_garage.currency:'') + " "} style={{ whiteSpace: 'normal', fontWeight: "bold", fontSize: "125%" }}/>
                        </td>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};

const CotizacionForm = props => {
    const { handleSubmit, data, guardar, rejectQuotation, id,descargarReporte,loader, me={} } = props;
    // const sendMechanic = (data.results ? data.results[0].send_mechanic:false)
    const send_client = true;
    const [openModal, setOpenModal] = useState(false);

    const [colCostos, setColCostos] = useState(true); // Variable para mostrar o no la columna de costos

    const sendEmail = (_data) => {
        let repuestos = [];
        data.forEach(e => repuestos.push({cantidad: e.quantity, repuesto: e.replacement, descripcion: e.description}));
        const datos = {
            email: _data.email,
            repuestos,
            orden: props.match.params.id || 0
        }
        props.sendEmailPartsList(datos);
        setOpenModal(false);
    };

    const btnEnviar = e => {
        SWALMOD.fire({
            title: '¿Enviar correo?',
            text: 'Se enviara el correo al cliente',
            showCancelButton: true,
            confirmButtonText: '¡Sí, enviar!',
            cancelButtonText: 'No, enviar',
            reverseButtons: true,
            imageUrl: warningSvg,
            imageAlt: 'Alerta',
            imageWidth: 90,
        }).then(result => {
            if (result.value) {
                props.onSubmit({ data: e, send_client }, false);
            }
        });
    };

    const btnFinalizar = e => {
        SWALMOD.fire({
            title: '¿Finalizar?',
            text: '¡Ya no se podra revertir esta acción!',
            showCancelButton: true,
            confirmButtonText: '¡Sí, finalizar!',
            cancelButtonText: 'No, finalizar',
            reverseButtons: true,
            imageUrl: warningSvg,
            imageAlt: 'Alerta',
            imageWidth: 90,
        }).then(result => {
            if (result.value) {
                props.onSubmit(e);
            }
        });
    };

    return (
        <React.Fragment>
            <Modal show_modal={openModal}>
                <div className="card-content-form2 px-0 px-0">
                    <div className="col-12 col-lg-8 px-0">
                        <EmailModal
                            onSubmit={sendEmail}
                            showModal={() => {
                                setOpenModal(false)
                            }}
                        />
                    </div>
                </div>
            </Modal>
            <div className='row'>
                <div className='col'>
                    <div className=' m-3 ml-5 d-flex flex-row'>
                        <button
                            onClick={() => rejectQuotation(id)}
                            type="button"
                            className="btn btn-primary mr-3"
                        >
                            Rechazar
                        </button>
                    </div>
                </div>
                <div className='col'>
                    <div className="m-3 mr-4 d-flex flex-row-reverse">
                        <button
                            onClick={() => setOpenModal(true)}
                            type="button"
                            className="btn btn-primary mr-3"
                        >
                            Enviar a proveedor
                        </button>

                        <button
                            onClick={() => setColCostos(!colCostos)}
                            type="button"
                            className="btn btn-primary mr-3"
                        >
                            {colCostos ? "Ocultar Costos" : "Mostrar Costos"}
                        </button>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row card-form">
                    <div className="col-12 padding-custom mb-3">
                        <FieldArray
                            name="quotation"
                            data={data}
                            component={renderCotizacion}
                            colCostos={colCostos}
                            me = {me}
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <div className="d-flex justify-content-end">
                            <button
                                onClick={handleSubmit(btnEnviar)}
                                className="btn btn-primary mr-3"
                            >
                                Enviar a cliente
                            </button>
                            <button
                                onClick={handleSubmit(e =>
                                    props.onSubmit(
                                        { data: e, print_quotation: true },
                                        false
                                    )
                                )}
                                className="btn btn-primary mr-3"
                            >
                                Imprimir
                            </button>
                            <button type="button" className="btn btn-primary mr-3" onClick={() =>descargarReporte(id)} disabled={loader} >Exportar</button>

                            
                        </div>
                    </div>
                </div>
                <ButtonCotizacionEnviar
                    link="/mechanic_orders"
                    link2={guardar}
                    btnText2="Guardar"
                    btnText1="Salir"
                    link3={handleSubmit(btnFinalizar)}
                />
            </form>
        </React.Fragment>
    );
};

export default reduxForm({
    form: 'CotizacionForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: data => {
        let errors = {};
        let DetalleArrayErrors = [];
        if (data && data.length) {
            data.forEach((detalle, detalleIndex) => {
                if (detalle.approved_provider1) {
                    detalle.approved_provider2 = false;
                    detalle.approved_provider3 = false;
                }
                if (detalle.approved_provider2) {
                    detalle.approved_provider1 = false;
                    detalle.approved_provider3 = false;
                }
                if (detalle.approved_provider3) {
                    detalle.approved_provider2 = false;
                    detalle.approved_provider1 = false;
                }
                DetalleArrayErrors[detalleIndex] = validate(detalle, {
                    provider1: validationsOthers.requerido.Combine,
                    provider2: validationsOthers.requerido.Combine,
                    provider3: validationsOthers.requerido.Combine,
                });
                if (
                    detalle.price_provider1 === null ||
                    detalle.price_provider1 === ''
                ) {
                    DetalleArrayErrors[detalleIndex].price_provider1 =
                        'Campo requerido.';
                }
                if (
                    detalle.price_provider2 === null ||
                    detalle.price_provider2 === ''
                ) {
                    DetalleArrayErrors[detalleIndex].price_provider2 =
                        'Campo requerido.';
                }
                if (
                    detalle.price_provider3 === null ||
                    detalle.price_provider3 === ''
                ) {
                    DetalleArrayErrors[detalleIndex].price_provider3 =
                        'Campo requerido.';
                }
            });
        }
        errors = DetalleArrayErrors;
        return {
            ...errors,
            ...validate(data, {}),
        };
    },
})(CotizacionForm);
