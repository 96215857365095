import React, { Component } from 'react';
import ChangePasswordForm from './ChangePasswordForm';
import LoadMask from "Utils/LoadMask/LoadMask";
const logo = require('assets/img/logo.png');

class ChangePassword extends Component {

    onSubmitReset = (data) => {
        this.props.resetPassword({ ...data, token: this.props.match.params.token });
    };
    render() {
        const { changePassword, loader } = this.props;
        let recover = false;
        if (this.props.match && this.props.match.params && this.props.match.params.token) {
            recover = true;
        }
        return (
            <div className="blue-gradient-bg d-flex align-items-center">
                <div className="login-wrapper flex-1 px-sm-5 px-md-0">
                    <div className="card-login ml-md-auto">
                        <div className="d-flex justify-content-center flex-column align-items-center">
                            <img src={logo} alt="Alerta" height={50} className="img-fluid" />
                        </div>
                        <div className="mx-5 my-5" style={{ borderTop: "solid 2px #323232" }} />
                        <LoadMask loading={loader} light>
                            <ChangePasswordForm onSubmit={recover ? this.onSubmitReset : changePassword} recover={recover} />
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePassword;



