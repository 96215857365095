import React, { Component, Fragment } from 'react'
import LoadMask from '../../../Utils/LoadMask/LoadMask'
import CardFormOrden from '../../../Utils/Cards/CardFormOrden'
import { StandardActionsTracking } from '../../../Utils/Grid/StandardActionsTracking'
import Formulario from './Formulario'
import { formData } from '../../OrdenesTrabajoAdmin/Crear/DatosComun/Metodos';

export default class Diagnostico extends Component {
  state = {
    fired: false
  }

  componentDidMount() {
    this.props.leer(this.props.match.params.id)
  }

  pauseTimer = ev => {
    if (this.state.fired == false) {
      this.setState({ fired: true })
      const id = this.props.match.params.id
      this.props.pauseTimer(id)
    }
  }

  componentWillUnmount() {
    //this.pauseTimer()
  }

  crear = (tasks_data, { crear = true, isCrear = false }) => {
    const { item } = this.props
    let data = item
    data.tasks = tasks_data.tasks
    data.diagnosis_photos = tasks_data.diagnosis_photos
    data.crear = crear
    if (crear) {
      // Cambiamos el estado de la orden de Diagnóstico (1) a cotización 2()
      data.order_body_shop.status = 2
    }
    else {
      data.order_body_shop.status = 1
    }

    const { arrayFiles, data: _data} = formData(data)
  
    console.log(tasks_data);

    this.props.crearDiagnostico(this.props.match.params.id, _data, arrayFiles, isCrear)
  }

  accion_tracking(id_task, accion) {
    const idWorkOrder = this.props.match.params.id || 0
    this.props.accionTask(idWorkOrder, 'diagnostic', id_task, accion)
}

  render() {
    const { loader, item } = this.props
    const { order_body_shop } = item || {}
    const { diagnostic_task } = order_body_shop || {}
    const { status_in_action = {}, time = "00:00:00", id} = diagnostic_task || {}

    return (
      <CardFormOrden
        titulo="DIAGNÓSTICO"
        orden={item && item.order_number}
        timer={diagnostic_task ?
          <div className="mt-1 mt-md-2 d-flex" style={{ height: 25 }}>
            {StandardActionsTracking({
              play: status_in_action.play,
              no_ver_finalized: true,
              finalized: status_in_action.finalized,
              onChange: this.accion_tracking.bind(this)
            })(id)
            }
              {time}
          </div>
          : ""
        }
      >
        <LoadMask loading={loader} blur>
          <Formulario
            onSubmit={this.crear}
            {...this.props}
          />
        </LoadMask>
      </CardFormOrden>
    )
  }
}
