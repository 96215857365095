import React, { Component, Fragment } from 'react';
import CardFormOrden from 'Utils/Cards/CardFormOrden';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { StandardActionsTracking } from '../../../../Utils/Grid/StandardActionsTracking';
import { ButtonSalirGuardar } from '../../../../Utils/Button';
const enderezado_y_pintura = require('assets/img/orden_trabajo/enderezado_y_pintura.svg');
const mecanica = require('assets/img/orden_trabajo/mecanica.svg');
import { LABEL_STATUS_ORDER_BODY_SHOP } from '../../../../../../utility/constants';
import { SWALMODERROR, SWALMOD } from '../../../../../../utility/constants';
const warningSvg = require('assets/img/icons/warning.svg');

export default class Tracking extends Component {
    componentWillMount() {
        const idWorkOrder = this.props.match.params.id || 0;
        const { listarTask } = this.props;
        listarTask(idWorkOrder);
    }

    accion_tracking(id_task, accion) {
        const idWorkOrder = this.props.match.params.id || 0;
        this.props.accionTask(idWorkOrder, id_task, accion);
    }
    createOrder(type_order, desactivar) {
        const idWorkOrder = this.props.match.params.id || 0;
        let texto = '';
        if (type_order == 'order_body_shop') {
            texto = 'Crear una orden de Enderezado y pintura';
        }
        if (type_order == 'order_mechanic') {
            texto = 'Crear una orden de Mecánica';
        }
        if (desactivar) {
            SWALMOD.fire({
                title: '¿Crear un orden?',
                text: texto,
                showCancelButton: true,
                confirmButtonText: '¡Sí, crear!',
                cancelButtonText: 'No, crear',
                reverseButtons: true,
                imageUrl: warningSvg,
                imageAlt: 'Alerta',
                imageWidth: 90,
            }).then(result => {
                if (result.value) {
                    this.props.createOrder(idWorkOrder, type_order);
                }
            });
        }
    }
    
    finalizarFLujo(end_flow) {
        const idWorkOrder = this.props.match.params.id || 0;
        if (end_flow) {
            SWALMOD.fire({
                title: '¿Finalizar el flujo?',
                text: '¡Ya no podrás modificar el estado de tus tareas!',
                showCancelButton: true,
                confirmButtonText: '¡Sí, finalizar!',
                cancelButtonText: 'No, finalizar',
                reverseButtons: true,
                imageUrl: warningSvg,
                imageAlt: 'Alerta',
                imageWidth: 90,
            }).then(result => {
                if (result.value) {
                    this.props.flujoPorFacturar(idWorkOrder);
                }
            });
        } else {
            SWALMODERROR.fire({
                title: '¡Error!',
                text: '¡Debes de tener la orden en estado: POR FACTURAR!',
                confirmButtonText: 'Regresar',
                reverseButtons: true,
                imageUrl: warningSvg,
                imageAlt: 'Alerta',
                imageWidth: 90,
            });
        }
    }
    render() {
        const { loader, data_task } = this.props;
        let bloquear = false;
        const {
            order_body_shop = false,
            order_mechanic = false,
            end_flow = false,
            order_number = '',
            car,
        } = data_task;
        return (
            <CardFormOrden orden={order_number}>
                <LoadMask loading={loader} blur>
                    <div className="row card-form justify-content-center align-items-center">
                        <div className="card padding-custom mb-3">
                            <div style={{ padding: 20 }}>
                                <h3 className="card-title">
                                    INFORMACIÓN DEL VEHÍCULO
                                </h3>
                                <div className="card-body">
                                    <div className="row">
                                        <div style={{ fontWeight: 'bold' }}>
                                            VIN:&nbsp;
                                        </div>
                                        {car ? car.vin : '---'}
                                    </div>
                                    <div className="row">
                                        <div style={{ fontWeight: 'bold' }}>
                                            MATRÍCULA:&nbsp;
                                        </div>
                                        {car ? car.car_registration : '---'}
                                    </div>
                                    <div className="row">
                                        <div style={{ fontWeight: 'bold' }}>
                                            MARCA:&nbsp;
                                        </div>
                                        {car ? car.brand : '---'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mb-2">
                        <h5 className="text-20 text-bold">PROCESO DE AVALÚO</h5>
                    </div>
                    <div className="row card-form">
                        <div className="col-12 mb-0 mb-md-4 mt-3">
                            <Grid
                                hover
                                striped
                                data={data_task}
                                pagination={false}
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    isKey
                                    width="110px"
                                    dataFormat={(cell, row) => {
                                        const {
                                            status_in_action = {},
                                            workflow,
                                        } = row;
                                        const { play, finalized } =
                                            status_in_action;
                                        let action = !bloquear
                                            ? StandardActionsTracking({
                                                  play,
                                                  finalized,
                                                  no_ver_play_pause: [
                                                      'a',
                                                      'b',
                                                  ].includes(workflow),
                                                  onChange:
                                                      this.accion_tracking.bind(
                                                          this
                                                      ),
                                              })(cell, row)
                                            : '';
                                        if (
                                            finalized == false &&
                                            !['h', 'j'].includes(workflow)
                                        ) {
                                            bloquear = true;
                                        }
                                        return action;
                                    }}
                                />
                                <TableHeaderColumn dataField="name" dataSort>
                                    FLUJOS
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="time"
                                    dataSort
                                    width="110px"
                                >
                                    TIEMPO
                                </TableHeaderColumn>
                            </Grid>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center mt-5 mb-3">
                        <h5 className="text-20 text-bold">
                            SELECCIONA EL TIPO DE ORDEN
                        </h5>
                    </div>
                    <div className="d-flex justify-content-between flex-column flex-sm-row">
                        <div
                            onClick={() => {
                                this.createOrder(
                                    'order_mechanic',
                                    order_mechanic === false
                                );
                            }}
                            className={`flex-1 px-4 mx-0 mx-sm-3 d-flex pt-4 bg-blanco flex-column my-3 py-3  py-sm-5 ${
                                order_mechanic === false ? 'pointer' : ''
                            }`}
                            style={{
                                boxShadow: '0px 0px 7px #00000066',
                                borderRadius: 15,
                            }}
                        >
                            <img
                                src={mecanica}
                                style={{ width: 45 }}
                                className="mx-auto mb-2"
                                alt=""
                            />
                            <span className="text-18 negro mx-auto">
                                MECANICAS
                            </span>
                            {order_mechanic !== false && (
                                <span className="text-14 negro">
                                    Estado actual:{' '}
                                    <span className="rojo">
                                        {order_mechanic || '--ERROR--'}
                                    </span>{' '}
                                </span>
                            )}
                        </div>

                        <div
                            onClick={() => {
                                this.createOrder(
                                    'order_body_shop',
                                    order_body_shop === false
                                );
                            }}
                            className={`flex-1 px-4 mx-0 mx-sm-3 d-flex pt-4 bg-blanco flex-column my-3 py-3  py-sm-5 ${
                                order_body_shop === false ? 'pointer' : ''
                            }`}
                            style={{
                                boxShadow: '0px 0px 7px #00000066',
                                borderRadius: 15,
                            }}
                        >
                            <img
                                src={enderezado_y_pintura}
                                style={{ width: 45 }}
                                className="mx-auto mb-2"
                                alt=""
                            />
                            <span className="text-18 negro mx-auto">
                                ENDEREZADO Y PINTURA
                            </span>
                            {order_body_shop !== false && (
                                <span className="text-14 negro">
                                    Estado actual:{' '}
                                    <span className="rojo">
                                        {LABEL_STATUS_ORDER_BODY_SHOP[
                                            order_body_shop
                                        ]
                                            ? LABEL_STATUS_ORDER_BODY_SHOP[
                                                  order_body_shop
                                              ].label
                                            : '--ERROR--'}
                                    </span>{' '}
                                </span>
                            )}
                        </div>
                    </div>
                    <ButtonSalirGuardar
                        link="/mywork-orders"
                        link2={() => {
                            this.finalizarFLujo(end_flow);
                        }}
                        btnText2="Finalizar"
                    />
                </LoadMask>
            </CardFormOrden>
        );
    }
}
