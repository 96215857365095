import React from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { connect } from 'react-redux';

export const RenderNumber = ({ value, decimalScale, className }) => {
    return (
        <NumberFormat
            className={className}
            decimalScale={decimalScale || 0}
            fixedDecimalScale
            value={value}
            thousandSeparator
            prefix=""
            displayType="text"
        />
    );
};

const GetRenderCurrency = ({ value, className, me }) => {
    const { currency_symbol = "$" } = me || {}
    return (
        <NumberFormat
            className={className}
            decimalScale={2}
            fixedDecimalScale
            value={value}
            thousandSeparator
            prefix={currency_symbol + " "}
            displayType="text"
        />
    );
};

const props = state => ({ me: state.login.me, })
export const RenderCurrency = connect(props)(GetRenderCurrency);

export const RenderDollar = ({ value, className, prefix, style }) => {
    return (
        <NumberFormat
            style={style}
            className={className}
            decimalScale={2}
            fixedDecimalScale
            value={value}
            thousandSeparator
            prefix={prefix || "$ "}
            displayType="text"
        />
    );
};

export const RenderDate = ({ value, className }) => {
    return <span className={className}>
        {moment(value ? value : "").format('DD-MM-YYYY')}
    </span>
};

export const RenderDateTime = ({ value, className }) => {
    if (value) {
        const fecha = new Date(value);
        return (
            <span className={className}>
                {fecha.toLocaleDateString()} {fecha.toLocaleTimeString()}
            </span>
        );
    }
    return <span className={className}>{value}</span>;
};

export const ReadFields = {
    renderCurrency: RenderCurrency,
    renderNumber: RenderNumber,
};
