import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/cotizaciones';
import Cotizacion from './Cotizacion';


const ms2p = (state) => {
    return {
        ...state.cotizaciones,
        data_Cotizacion: state.form.CotizacionForm,
        me: state.login.me,
        permissions: state.login.me.permissions
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Cotizacion);
