import { handleActions } from 'redux-actions';
import { push } from 'react-router-redux';
import { NotificationManager } from 'react-notifications';
import { api } from 'api';

const SUBMIT = 'REGISTER_SUBMIT';
const LOADER = 'REGISTER_LOADER';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

// ------------------------------------
// Actions
// ------------------------------------

const onSubmit = (data = {}, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments('garage', data, attachments)
        .then(() => {
            NotificationManager.success('Registro creado', 'Éxito', 3000);
            dispatch(push('/login'));
        })
        .catch((error) => {
            let mensaje = 'Error en la creación, vuelve a intentarlo';
            if (error) {
                if (error.detail)
                    mensaje = error.detail;
            }
            NotificationManager.error(mensaje, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const logOut = () => (dispatch) => {
    localStorage.removeItem('token');
};

export const actions = {
    onSubmit,
    logOut,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
};

export const initialState = {
    loader: false,
};

export default handleActions(reducers, initialState);
