import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/ordenesTrabajoAdmin';
import FacturacionPDF from './FacturacionPDF';


const ms2p = (state) => {
    const profile = state.login.me;
    return {
        ...state.dashboardWorkOrders,
        profile,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(FacturacionPDF);
