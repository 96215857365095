import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Formulario from './Formulario';

export default class Crear extends Component {
    componentWillMount() {
        const id = this.props.match.params.id || 0
        this.props.listar(id);
    }
    update = (data) => {
        this.props.save_quotation_mechanic(this.props.match.params.id, data)
    };

    render() {
        const { loader, item } = this.props;
        return (
            <CardForm titulo="EN PROCESO" largo_contenedor={'col-12'}>
                <LoadMask loading={loader} blur>
                    <Formulario onSubmit={this.update} {...this.props} item={item} />
                </LoadMask>
            </CardForm>
        )
    }
}
