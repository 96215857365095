import {createAction, handleActions} from 'redux-actions';
import {api} from "api";

const LOADER = 'LOADER_NOTIFICATIONS2';
const ORDERING = 'ORDERING_NOTIFICATIONS2';
const SEARCH = 'SEARCH_NOTIFICATION2';
const PAGE = 'PAGE_NOTIFICATION2';
const ITEM_DATA = 'ITEM_NOTIFICATION2';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: ITEM_DATA,
    data,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});


// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const store = getStore();
    const {search, ordering} = store.notificacionesVencidas;
    dispatch(setLoader(true));
    api.get('notification', {page, search, ordering}).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = (sort) => (dispatch, getStore) => {
    const store = getStore()
    const {ordering, page} = store.notificacionesVencidas;
    if (ordering === sort) {
        dispatch(setOrdering(`-${sort}`));
    } else {
        dispatch(setOrdering(sort));
    }
    dispatch(listar(page));
};


export const actions = {
    listar,
    searchChange,
    onSortChange,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [ORDERING]: (state, {ordering}) => {
        return {
            ...state,
            ordering,
        };
    },
    [PAGE]: (state, {page}) => {
        return {
            ...state,
            page,
        };
    },
    [ITEM_DATA]: (state, {data}) => {
        return {
            ...state,
            data,
        };
    },
    [SEARCH]: (state, {search}) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    ordering: "",
    loader: false,
    search: "",
    data: {},
    page: 1,
};

export default handleActions(reducers, initialState);
