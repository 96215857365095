import React, { useState, Fragment, useEffect } from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from 'validate-redux-form';

import {
    renderField,
    renderCurrency,
    renderFieldCheck,
    AsyncSelectField2
} from '../../../../Utils/renderField/renderField';
import { RenderCurrency } from '../../../../Utils/renderField/renderReadField';


import Grid from "../../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { Async } from "react-select";


import { validationsOthers } from '../../../../../../utility/validationForm'
import { ButtonSalirGuardar } from '../../../../Utils/Button'
import { Link } from 'react-router-dom';
import { api } from "api";


const Repuestos = ({ loader, data, setSendData, sendData, OPERADORES = [] }) => {
    const [state, setState] = useState({
        BreakLineCenter: {
            whiteSpace: 'normal',
            textAlign: 'center'
        },
        BreakLine: {
            whiteSpace: 'normal'
        },
        AlignCenter: {
            textAlign: 'center'
        }
    })
    console.log('data', data, 'Operadores', OPERADORES, )
    return (
        <Fragment>
            <Grid hover striped data={data} loading={loader} pagination={false} className="no-hover">
                <TableHeaderColumn
                    dataField="user"
                    dataSort
                    dataFormat={(...arg) => {
                        return <Field
                            name={`data[${arg[3]}].stock`}
                            component={renderFieldCheck}
                            to_block={!!data.results[arg[3]].stock}
                        />
                    }
                    }
                    thStyle={{textAlign: 'center', width:'100px'}}
                    tdStyle={{textAlign: 'center', width: '100px'}}
                >
                    STOCK
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="quantity"
                    dataSort
                    isKey
                    thStyle={state.AlignCenter}
                    tdStyle={state.BreakLineCenter}
                >
                    CANTIDAD
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="replacement"
                    dataSort
                    thStyle={state.AlignCenter}
                    tdStyle={state.BreakLineCenter}
                >
                    REPUESTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="name_task"
                    dataSort
                >
                    NÚMERO DE PARTE
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="description"
                    dataSort
                    thStyle={state.AlignCenter}
                    tdStyle={state.BreakLineCenter}
                >
                    DESCRIPCIÓN
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="observation"
                    dataSort
                    thStyle={state.AlignCenter}
                    tdStyle={state.BreakLineCenter}
                >
                    OBSERVACIONES
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="provider_true"
                    dataSort
                    width='170px'
                    thStyle={state.AlignCenter}
                    tdStyle={state.BreakLineCenter}
                >
                    PROVEEDOR
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="price_true"
                    dataSort
                    thStyle={state.AlignCenter}
                    tdStyle={state.BreakLineCenter}
                    dataFormat={(...arg) => {
                        return <Field
                            name={`data[${arg[3]}].price_true`}
                            component={renderCurrency}
                        />
                    }}
                >
                    PRECIO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="user"
                    dataSort
                    dataFormat={(...arg) => {
                        console.log('arg>>>>>>>>>>>>>>>S', ...arg)
                        return (
                        <select
                            name={`data[${arg[3]}].user`}
                            id='select_xjf'
                            className=" text-16 medium negro"
                            onChange={e => {
                                setSendData({ ...sendData, [arg[1].id]: e.target.value != 0 ? e.target.value : undefined })
                            }} >


                            {OPERADORES.map((item, index) => {
                                return (
                                    <option
                                        key={index}
                                        value={item.value}
                                        selected={
                                            item && item.value && arg[1].user.id ?
                                            item.value == arg[1].user.id
                                            :
                                            false
                                        }
                                    >
                                        {item.label}
                                    </option>
                                )
                            })}

                            {/* /Options antes*/}
                            {/* {(OPERADORES.map((item) => {
                                return (
                                    (data.results[arg[3]].user.value && item.value !== 0) ?
                                    <option
                                        key={item.value}
                                        value={item.value}
                                        selected={arg[0].id == item.value ? "selected" : undefined}
                                    >

                                        {item.label}

                                    </option>

                                    // sino
                                    :
                                    (item.value !== 0 || !data.results[arg[3]].user.value) ?
                                    <option
                                        key={item.value}
                                        value={item.value}
                                        selected={arg[0].id == item.value ? "selected" : undefined}
                                    >

                                        {item.label}

                                    </option>

                                    : ""
                            )
                            }))} */}
                        </select>
                        )
                    }}
                    thStyle={{textAlign: 'center', width:'220px'}}
                    tdStyle={{textAlign: 'center', width: '220px'}}
                >
                    MECANICO/OPERADOR
                </TableHeaderColumn>
            </Grid>
        </Fragment>
    )
}


const CotizacionForm = (props) => {
    const { handleSubmit, item, loader } = props;
    const [sendData, setSendData] = useState({})
    const [OPERADORES, setOPERADORES] = useState([])

    useEffect(() => {
        api.get("garage/my_users_mechanic/").then((data) => {
            if (data) {
                setOPERADORES([{ "label": "Sin asignar", "value": 0 }, ...data]);
            } else {
                setOPERADORES([]);
            }
        }).catch(() => {
            setOPERADORES([]);
        });
    }, [])



    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form">
                {item && OPERADORES.length > 0 && Array.isArray(item) && item.length > 0 &&
                    <div className="col-12 padding-custom mb-3 mt-4 input-border-verde">
                        <Repuestos loader={loader} data={{ 'results': item }} setSendData={setSendData} sendData={sendData} OPERADORES={OPERADORES} />
                    </div>
                }
            </div>
            <ButtonSalirGuardar link="/mechanic_orders" btnText1="Salir"
                link2={handleSubmit(values => props.onSubmit({ 'form_data': values.data, 'data_select': sendData }))}
            />
        </form>
    );
};

export default reduxForm({
    form: 'CotizacionProgresoForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(CotizacionForm);
