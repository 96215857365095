import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction } from 'validate-redux-form';
import {
    renderNumber,
    SelectField,
    renderField,
    renderFilePicker,
    renderDatePicker,
} from '../../Utils/renderField';
import { ButtonSalirGuardar } from '../../Utils/Button';
import { COUNTRIES } from '../../../../utility/countries';
import {
    validationGarage,
    validationAdministrador,
} from '../../../../utility/validationForm';
import { connect } from 'react-redux';

let RegisterForm = props => {
    const { handleSubmit, setImg, logo, enable_type_work_orders = 0 } = props;
    const TYPE_ORDER = [
        { name: 'Mecánica', id: 'mechanic' },
        { name: 'Enderezado y pintura', id: 'body_shop' },
    ];
    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form">
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="name">Nombre del taller*</label>
                    <Field
                        name="name"
                        component={renderField}
                        type="text"
                        className="form-control"
                        maxLength={validationGarage.name.MaxLength}
                    />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="address">Dirección*</label>
                    <Field
                        name="address"
                        component={renderField}
                        type="text"
                        className="form-control"
                        maxLength={validationGarage.address.MaxLength}
                    />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="city">Ciudad*</label>
                    <Field
                        name="city"
                        component={renderField}
                        type="text"
                        className="form-control"
                        maxLength={validationGarage.city.MaxLength}
                    />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="country">Pais*</label>
                    <Field
                        name="country"
                        valueKey="id"
                        labelKey="name"
                        options={COUNTRIES}
                        component={SelectField}
                    />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="phone_number">Teléfono*</label>
                    <Field
                        name="phone_number"
                        isNumericString={true}
                        component={renderNumber}
                        type="text"
                        className="form-control"
                        maxLength={validationGarage.phone_number.MaxLength}
                    />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="email">E-mail*</label>
                    <Field
                        name="email"
                        component={renderField}
                        type="email"
                        className="form-control"
                        maxLength={validationGarage.email.MaxLength}
                    />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="foundation_date">Fecha fundación</label>
                    <Field
                        name="foundation_date"
                        component={renderDatePicker}
                        className="form-control"
                    />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="subdomain">Subdominio</label>
                    <Field
                        name="subdomain"
                        component={renderField}
                        type="text"
                        className="form-control"
                        maxLength={validationGarage.subdomain.MaxLength}
                    />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="prefix">Prefijo</label>
                    <Field
                        name="prefix"
                        component={renderField}
                        type="text"
                        className="form-control"
                        maxLength={validationGarage.prefix.MaxLength}
                    />
                </div>

                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="logo">Logo taller</label>
                    <Field
                        name="logo"
                        component={renderFilePicker}
                        setFile={setImg}
                        photo={logo ? logo : null}
                    />
                </div>
                <div className="col-12 padding-custom mb-3">
                    <p className="text-right azul text-12 bold">
                        * Campos requeridos
                    </p>
                </div>
                {enable_type_work_orders > 1 ? (
                    <div className="col-md-6 padding-custom mb-3">
                        <label htmlFor="prefix">Tipos de orden activos:</label>
                        <label
                            htmlFor="prefix"
                            className="text-16 bold verde d-block mb-0"
                        >
                            - Mecánica
                        </label>
                        <label
                            htmlFor="prefix"
                            className="text-16 bold verde d-block mb-0"
                        >
                            - Enderezado y pintura
                        </label>
                        {enable_type_work_orders > 2 && (
                            <label
                                htmlFor="prefix"
                                className="text-16 bold verde d-block mb-0"
                            >
                                - Avalúo
                            </label>
                        )}
                    </div>
                ) : (
                    <div className="col-md-6 padding-custom mb-3">
                        <label htmlFor="type_order_active">Tipo de orden</label>
                        <Field
                            name="type_order_active"
                            valueKey="id"
                            labelKey="name"
                            options={TYPE_ORDER}
                            component={SelectField}
                        />
                    </div>
                )}
            </div>
            <ButtonSalirGuardar
                link="/suscriptions"
                btnText1="Regresar"
                btnText2="Siguiente"
            />
        </form>
    );
};

export const matchPassword = (pass, confirm) =>
    validatorFromFunction(value => {
        return pass === confirm;
    });

RegisterForm = reduxForm({
    form: 'register', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: data => {
        return validate(data, {
            name: validationGarage.name.Combine,
            email: validationGarage.email.Combine,
            phone_number: validationGarage.phone_number.Combine,
            country: validationGarage.country.Combine,
            city: validationGarage.city.Combine,
            address: validationGarage.address.Combine,
            subdomain: validationGarage.subdomain.Combine,
            prefix: validationGarage.prefix.Combine,
            type_order_active: validationGarage.type_order_active.Combine,
        });
    },
})(RegisterForm);

RegisterForm = connect(state => {
    const form = state.form.register;
    let logo = '';
    if (form && form.values) {
        const values = form.values;
        if (values.logo) logo = values.logo;
    }
    return {
        logo,
    };
})(RegisterForm);

export default RegisterForm;
