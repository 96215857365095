import React, { Component, Fragment } from 'react'
import moment from 'moment/moment';
import Card from '../../../../Utils/Cards/Card';
import Grid from '../../../../Utils/Grid';
import { PrintContainer, actions as printActions } from 'Utils/Print';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { RenderCurrency } from '../../../../Utils/renderField/renderReadField';
import './index.css';
const logo = require('assets/img/default-logo.png');
import { RenderDollar } from '../../../../Utils/renderField/renderReadField'

const Repuestos = ({ loader, data, data_garage }) => {
    const provider = {
        provider1: data.results[0].provider1 || "",
        provider2: data.results[0].provider2 || "",
        provider3: data.results[0].provider3 || "",
        currency: data_garage.currency,
    }

    return (
        <Fragment>
            <Grid hover striped data={data} loading={loader} pagination={false} className="no-hover">
                <TableHeaderColumn
                    dataField="quantity"
                    dataSort
                    isKey

                >
                    CANTIDAD
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="replacement"
                    dataSort
                >
                    REPUESTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="name_task"
                    dataSort
                >
                    NÚMERO DE PARTE
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="description"
                    dataSort
                >
                    DESCRIPCIÓN
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="price_provider1"
                    dataSort
                    dataFormat={(cell, row) => {
                        return(
                            <React.Fragment>
                                <span style={{ fontWeight: "bold", fontSize: "125%" }}>
                                    {row.provider1}
                                </span>
                                <br/>
                                <div style={{ marginTop: 5 }}/>
                                <RenderDollar value={cell || 0} prefix={provider.currency + " "} />
                            </React.Fragment>
                        )
                    }}
                >
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="price_provider2"
                    dataSort
                    dataFormat={(cell, row) => {
                        return(
                            <React.Fragment>
                                <span style={{ fontWeight: "bold", fontSize: "125%" }}>
                                    {row.provider2}
                                </span>
                                <br/>
                                <div style={{ marginTop: 5 }}/>
                                <RenderDollar value={cell || 0} prefix={provider.currency + " "} />
                            </React.Fragment>
                        )
                    }}
                >
                    Proveedores
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="price_provider3"
                    dataSort
                    dataFormat={(cell, row) => {
                        return(
                            <React.Fragment>
                                <span style={{ fontWeight: "bold", fontSize: "125%" }}>
                                    {row.provider3}
                                </span>
                                <br/>
                                <div style={{ marginTop: 5 }}/>
                                <RenderDollar value={cell || 0} prefix={provider.currency + " "} />
                            </React.Fragment>
                        )
                    }}
                >
                </TableHeaderColumn>
            </Grid>
            <div class="table-responsive">
                <table className="table">
                    <tr>
                        <th colSpan={4}>
                            <span style={{ fontWeight: "bold", fontSize: "125%" }}>
                                TOTAL
                            </span>
                        </th>
                        <th>
                            <RenderDollar style={{ fontWeight: "bold", fontSize: "125%" }} value={data.totalProveedor1 || 0} prefix={provider.currency + " "} />
                        </th>
                        <th>
                            <RenderDollar style={{ fontWeight: "bold", fontSize: "125%" }} value={data.totalProveedor2 || 0} prefix={provider.currency + " "} />
                        </th>
                        <th>
                            <RenderDollar style={{ fontWeight: "bold", fontSize: "125%" }} value={data.totalProveedor3 || 0} prefix={provider.currency + " "} />
                        </th>
                    </tr>
                </table>
            </div>
            
        </Fragment>
    )
}

export default class ReportImp extends Component {
    state = {
        date_after: moment().format('DD/MM/YYYY'),
        fechaHoraImpresion: moment().format('DD/MM/YYYY HH:mm:ss A'),
        name_print_component: 'cotización',
        data: {
            results: []
        },
    }

    componentWillMount() {
        const id = this.props.match.params.id || 0
        this.props.leerQuotation(id).then((response) => {
            this.setState({ data: response })
        })
    }

    imprimirBoleta = (event) => {
        this.setState({
            fechaHoraImpresion: moment().format('DD/MM/YYYY HH:mm:ss A')
        }, () => {
            printActions.print(this.state.name_print_component);
        });
    }


    render() {
        const divStyle = {
            border: "1px solid black",
            width: "100%",
        };
        const { loader } = this.props;
        const data = this.state.data

        const { data_work_orders = {}, data_garage = {}, totalProveedor1 = {}, totalProveedor2 = {}, totalProveedor3 = {} } = data || {}
        const { client = {}, order_number = "", receipt_info_garage_or_branch = {} } = data_work_orders || {}
        const { receipt_info = {} } = client || {}
        return (
            <div>
                <Card>
                    <PrintContainer name={this.state.name_print_component}>
                        <div className="col-12 p-0">
                            <div className="row contenido-impresion d-flex justify-content-center">
                                <div className="col-12 row">
                                    <div className="col-12 d-flex justify-content-end mt-0">
                                        <span>Orden: {order_number}</span>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center mt-0">
                                        <div className="col-11 pl-2">
                                            <div className="row col-12 p-0 mt-3 d-flex justify-content-center align-items-center">
                                                <img
                                                    className="d-inline-block align-top"
                                                    // src={require('../../../../../../../assets/img/logo.png')}
                                                    src={data_garage.logo ? data_garage.logo : logo}
                                                    alt="Logo"
                                                    height="100px"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 d-flex justify-content-center mt-3">
                                        <div className="col-11">
                                            <div className="row col-12 p-0 mt-2 justify-content-center align-items-start">
                                                <div className="col-12 p-0 fondo">
                                                    <div className="col-12 d-flex flex-column">
                                                        <span className="text-20 text-uppercase rojo0a text p-0 m-0">{receipt_info_garage_or_branch.name || ''}</span>
                                                        <span className="negro32 p-0 m-0">ID tributario: {receipt_info_garage_or_branch.tax_id}</span>
                                                        <span className="negro32 p-0 m-0">Telefono: {receipt_info_garage_or_branch.phone_number}</span>
                                                        <span className="negro32 p-0 m-0">Email: {receipt_info_garage_or_branch.email}</span>
                                                        <span className="negro32 p-0 m-0">{receipt_info_garage_or_branch.address} {receipt_info_garage_or_branch.city} {receipt_info_garage_or_branch.country_text} </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row col-12 p-0 mt-3">
                                                <div className="col-8 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <span className="tc1  text-center">DATOS DEL CLIENTE</span>
                                                        <table id="t01">
                                                            <tr>
                                                                <th>Nombre</th>
                                                                <td>{receipt_info.name}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>ID tributario</th>
                                                                <td>{receipt_info.tax_id}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Telefono</th>
                                                                <td>{receipt_info.phone_number || "--"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Email</th>
                                                                <td>{receipt_info.email || "--"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>País</th>
                                                                <td>{receipt_info.country_text}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Ciudad</th>
                                                                <td>{receipt_info.city}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Dirección</th>
                                                                <td>{receipt_info.address}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Fecha</th>
                                                                <td>{this.state.fechaHoraImpresion}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {data && Array.isArray(data.results) && data.results.length > 0 &&
                                        <div className="col-12 padding-custom mb-3 mt-4 input-border-verde">
                                            <Repuestos loader={loader} data_garage={data_garage} data={data} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </PrintContainer>
                </Card>
                <div className="mb-4 col-12 p-0">
                    <div className="row mb-5">
                        <div className="col-12 row">
                            <div className="col-12 d-flex justify-content-center mt-5">
                                <div className="col-9 buttons-box">
                                    <button type="button" className="btn btn-dark mr-5" onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.goBack();
                                    }}>REGRESAR</button>
                                    <button type="submit" className="btn btn-primary" onClick={this.imprimirBoleta}>IMPRIMIR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
