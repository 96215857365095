import React, { Fragment } from 'react';


function CardFormOrden(props) {
    const { orden = "12-9-2020", timer } = props
    return (
        <Fragment>
            {props.titulo &&
                <div className="mb-1 mb-md-2">
                    <h1 className="text-45 medium d-inline my-0">{props.titulo} </h1>
                </div>
            }
            <div className="mb-4 py-3 px-5 bg-white d-inline-flex flex-wrap" style={{ border: "1px solid #487E95", boxShadow: "0px 0px 7px #00000066", borderRadius: 15 }}>
                <span className="text-25 m-0 p-0">ORDEN: <span className="text-18 mr-3">{orden}</span> </span>
                {timer && timer}
            </div>
            <div className="card-small">
                <div className='card-content-form px-0 px-0'>
                    <div className="col-12 col-lg-9">
                        {props.children}
                    </div>
                </div>
            </div>
        </Fragment >

    )
}

export default CardFormOrden;
