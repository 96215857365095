import React, {Component, Fragment} from "react";
import './FileUploaderExcel.css';

const alerta_img = require('assets/img/icons/warning.svg');
import {SWALMODERROR} from '../../../../utility/constants';


const defaultProps = {
    baseColor: 'gray',
    activeColor: 'green',
    overlayColor: 'rgba(255,255,255,0.3)',
    opacity: 0
};

class FileUploaderExcel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            imageSrc: '',
            loaded: false,
            nameFile: '',
        };

        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    componentDidUpdate() {
        if (this.state.imageSrc === '' && this.props.source !== undefined && this.props.source !== null) {
            this.setState({imageSrc: this.props.source, loaded: true})
        }
    }

    componentWillMount() {
        if (this.props.img !== null && this.props.img !== undefined) {
            // setea la imágen si se le envia una
            this.setState({
                imageSrc: this.props.img,
                loaded: true,
                isImage: true,
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.img !== null && nextProps.img !== undefined) {
            // setea la imágen si se le envia una
            let temp = true;
            this.props.excel ? temp = false : temp;
            this.setState({
                imageSrc: nextProps.img,
                loaded: temp,
                isImage: true
            });
        }
    }

    onDragEnter(e) {
        this.setState({active: true});
    }

    onDragLeave(e) {
        this.setState({active: false});
    }

    onDragOver(e) {
        e.preventDefault();
    }

    onDrop(e) {
        e.preventDefault();
        this.setState({active: false});
        this.onFileChange(e, e.dataTransfer.files[0]);
    }

    isValid = (file_type) => {
        const {accept = "-*"} = this.props;
        const pattern = accept == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? accept : (accept !== "-*" ? accept.replace('/', '-') : accept);
        const accept_type = pattern.split(",")
        let aprobado = false;
        accept_type.forEach(t => {
            const regex = new RegExp(t);
            if (!!file_type.match(regex)) {
                aprobado = true;
            }
        });
        return aprobado;
    }

    onFileChange(e, file) {
        this.props.onFileChange(e, file);
        file = file || e.target.files[0];
        const imagePattern = /image-*/;

        const reader = new FileReader();
        if (file) {
            const isImage = !!file.type.match(imagePattern);
            if (!this.isValid(file.type)) {
                SWALMODERROR.fire({
                    title: '¡Información!',
                    text: '¡Selecciona un archivo de tipo EXCEL!',
                    confirmButtonText: 'Regresar',
                    reverseButtons: true,
                    imageUrl: alerta_img,
                    imageAlt: 'Alerta',
                    imageWidth: 90,
                })
                return;
            }
            let name = file.name
            if(name.length>25)
                name = file.name.substr(0, 25) + '...'
            this.setState({loaded: false, nameFile: name});

            reader.onload = (e) => {
                this.setState({
                    imageSrc: reader.result,
                    isImage,
                    loaded: true
                });
            };
            reader.readAsDataURL(file);
        }
    }

    getFileObject() {
        return this.refs.input.files[0];
    }

    getFileString() {
        return this.state.imageSrc;
    }

    render() {
        const {accept = '/*'} = this.props;
        let state = this.state,
            props = defaultProps,
            labelClass = `uploader ${state.loaded && 'loaded'}`,
            borderColor = state.active ? props.activeColor : props.baseColor,
            iconColor = state.active
                ? props.activeColor
                : (state.loaded)
                    ? props.overlayColor
                    : props.baseColor,
            hideIcon = state.loaded ? 0 : 1;

        return (
            <Fragment>
                <label
                    className={labelClass}
                    onDragEnter={this.onDragEnter}
                    onDragLeave={this.onDragLeave}
                    onDragOver={this.onDragOver}
                    onDrop={this.onDrop}
                    style={{outlineColor: this.props.borderRed? '#c4183c': borderColor}}>
                    <img
                        src={this.props.excel === true ? require('assets/img/excel-uploaded.png') : require('assets/img/uploaded.png')}
                        className={state.loaded ? 'loaded' : (this.props.excel ? 'loaded-excel' : undefined)}
                        alt={""}/>
                    <img
                        style={{color: iconColor, opacity: hideIcon}}
                        className="icon icon-upload pt-4"
                        src={this.props.excel === true ? require(`assets/img/excel.png`) : require(`assets/img/upload2.png`)}
                        alt=""/>
                    <input {...this.props.input} disabled={this.props.disabled} type="file" accept={accept}
                           onChange={this.onFileChange} ref="input"/>
                </label>
                <span className="text-12 mt-n1">{this.state.nameFile ? this.state.nameFile : ""}</span>
            </Fragment>
        );
    }
}

export default FileUploaderExcel
