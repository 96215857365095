import { connect } from 'react-redux'
import { actions } from '../../../../../redux/modules/ordenesTrabajoOperador/enderezadoPintura'
import Diagnostico from './Diagnostico'

const ms2p = state => {
  return {
    ...state.operadorEnderezadosPinturas,
    data_FormDiagnostico: state.form.DiagnosticoEnderezadoForm
  }
}

const md2p = { ...actions }

export default connect(ms2p, md2p)(Diagnostico)