import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import _ from "lodash";
import moment from 'moment';

const LOADER = 'LOADER_COLABORADORES_ORDENES';
const DATA = 'DATA_COLABORADORES_ORDENES';
const PAGE = 'PAGE_COLABORADORES_ORDENES';
const ORDERING = 'ORDERING_COLABORADORES_ORDENES';
const SEARCH = 'SEARCH_COLABORADORES_ORDENES';
const ID_COLABORADOR = 'ID_COLABORADOR_COLABORADORES_ORDENES';
const YEAR = 'YEAR_COLABORADORES_ORDENES';
const MONTH = 'MONTH_COLABORADORES_ORDENES';
const DATA_TASKS_DETAIL = 'DATA_DETALLE_DE_TAREAS';
const PAGE_TASKS = 'PAGE_TAREAS_COLABORADOR';
const ID_WORK_ORDER = 'ID_WORK_ORDER_BY_COLLABORATOR';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setDataTask = dataTasks => ({
    type: DATA_TASKS_DETAIL,
    dataTasks,
});


const setPage = page => ({
    type: PAGE,
    page,
});

const setPageTasks = page2 => ({
    type: PAGE_TASKS,
    page2,
});

const setIdWorkOrder = id_work_order => ({
    type: ID_WORK_ORDER,
    id_work_order,
});

const setIdColaborador = id_colaborador => ({
    type: ID_COLABORADOR,
    id_colaborador,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});


// ------------------------------------
// Actions
// ------------------------------------
const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().colaboradorOrdenes;
    const params = { page };

    if (resource.month)
        params.month = resource.month.value;
    if (resource.year)
        params.year = resource.year.value;
    if (resource.id_colaborador)
        params.id_colaborador = resource.id_colaborador;
    if (resource.ordering)
        params.ordering = resource.ordering;
    if (resource.search)
        params.search = resource.search;
    dispatch(setLoader(true));
    api.get('garage/collaborator_orders', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarTareas = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().colaboradorOrdenes;
    const params = { page };
    params.id_colaborador = resource.id_colaborador;
    params.id_work_order = resource.id_work_order;
    dispatch(setLoader(true));
    api.get(`work_orders/task_details`, params).then((response) => {
        dispatch(setDataTask(response));
        dispatch(setPageTasks(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch, getStore) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().colaboradorOrdenes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};
const idColaborador = id_colaborador => (dispatch, getStore) => {
    dispatch(setIdColaborador(id_colaborador));
    dispatch(listar());
};

const changeYear = year => (dispatch) => {
    dispatch({ type: YEAR, year });
    dispatch(listar())
};
const changeMonth = month => (dispatch) => {
    dispatch({ type: MONTH, month });
    dispatch(listar())
};

const initializeWorkOrderId = id_work_order => (dispatch) => {
    dispatch(setIdWorkOrder(id_work_order));
    dispatch(listarTareas());
};

const startOrderByCloseDate = (ordering) => (dispatch) => {
    dispatch(setOrdering(ordering));
};

export const actions = {
    listar,
    listarTareas,
    searchChange,
    onSortChange,
    idColaborador,
    changeMonth,
    changeYear,
    setIdColaborador,
    initializeWorkOrderId,
    startOrderByCloseDate,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [YEAR]: (state, { year }) => {
        return {
            ...state,
            year,
        };
    },
    [MONTH]: (state, { month }) => {
        return {
            ...state,
            month,
        };
    },
    [ID_COLABORADOR]: (state, { id_colaborador }) => {
        return {
            ...state,
            id_colaborador,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [DATA_TASKS_DETAIL]: (state, { dataTasks }) => {
        return {
            ...state,
            dataTasks,
        };
    },
    [PAGE_TASKS]: (state, { page2 }) => {
        return {
            ...state,
            page2,
        };
    },
    [ID_WORK_ORDER]: (state, { id_work_order }) => {
        return {
            ...state,
            id_work_order,
        };
    },

};



const meses = [
    { 'value': 1, 'label': 'Enero' },
    { 'value': 2, 'label': 'Febrero' },
    { 'value': 3, 'label': 'Marzo' },
    { 'value': 4, 'label': 'Abril' },
    { 'value': 5, 'label': 'Mayo' },
    { 'value': 6, 'label': 'Junio' },
    { 'value': 7, 'label': 'Julio' },
    { 'value': 8, 'label': 'Agosto' },
    { 'value': 9, 'label': 'Septiembre' },
    { 'value': 10, 'label': 'Octubre' },
    { 'value': 11, 'label': 'Noviembre' },
    { 'value': 12, 'label': 'Diciembre' },
]

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    dataTasks: {
        results: [],
        count: 0,
    },
    id_work_order: 0,
    page2: 1,
    id_colaborador: 0,
    page: 1,
    ordering: '',
    search: '',
    year: { 'value': moment().year(), 'label': moment().year() },
    month: meses[moment().month()],
};

export default handleActions(reducers, initialState);
