import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Formulario from './Formulario';

export default class Crear extends Component {
    state = {
        editar: false,
    };

    componentWillMount() {
        if (this.props.match.params.id) {
            this.props.leer(this.props.match.params.id);
            this.setState({ editar: true });
        }
    }
    editar = (data) => {
        let datos = { ...data }
        datos.role = datos.role.id
        this.props.editar(this.props.match.params.id, datos);
    };

    crear = (data) => {
        let datos = { ...data }
        datos.role = datos.role.id
        this.props.crear(datos);
    };

    render() {
        //Value
        const { loader, item, me } = this.props;
        return (
            <CardForm titulo={this.state.editar ? "EDITAR USUARIO" : "NUEVO USUARIO"}>
                <LoadMask loading={loader} blur>
                    {
                        this.state.editar ?
                            <Formulario
                                me={me}
                                onSubmit={this.editar}
                                item={item}
                                actualizar
                            />
                            :
                            <Formulario onSubmit={this.crear} />
                    }
                </LoadMask>
            </CardForm>
        )
    }
}
