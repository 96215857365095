import React, { Component, Fragment } from 'react';
import Grid from '../../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { ButtonSalirGuardar } from '../../../../../Utils/Button';
const warningSvg = require('assets/img/icons/warning.svg');
import { SWALMOD, SWALMODERROR } from '../../../../../../../utility/constants';
import defaultLogo from 'assets/img/place_holder/image_placeholder_small.png';
const alerta_img = require('assets/img/icons/warning.svg');

export default class Listar extends Component {
    componentWillMount() {
        const { listarQaOrCarWash, work_order_data_id, search_flow } =
            this.props;
        listarQaOrCarWash(work_order_data_id, search_flow);
    }

    errorSwal = text => {
        SWALMODERROR.fire({
            title: '¡Información!',
            text: text,
            confirmButtonText: 'Aceptar',
            reverseButtons: true,
            imageUrl: alerta_img,
            imageAlt: 'Alerta',
            imageWidth: 90,
        });
    };

    finalizarFlujo(status) {
        if (status == false) {
            SWALMOD.fire({
                title: '¿Finalizar el flujo?',
                text: '',
                showCancelButton: true,
                confirmButtonText: '¡Sí, finalizar!',
                cancelButtonText: 'No, finalizar',
                reverseButtons: true,
                imageUrl: warningSvg,
                imageAlt: 'Alerta',
                imageWidth: 90,
            }).then(result => {
                if (result.value) {
                    const { finalizarFlujo, work_order_data_id, search_flow } =
                        this.props;
                    finalizarFlujo(work_order_data_id, search_flow);
                }
            });
        } else {
            this.errorSwal('Debes detener el contador de tiempo para realizar esta acción.');
        }
    }

    render() {
        const { data_task, status_in_action } = this.props; //Constantes
        return (
            <Fragment>
                {data_task &&
                    data_task.results.map((item, index) => (
                        <Fragment key={item.name}>
                            <div className="col-12 padding-custom mb-0 mb-md-4 mt-4">
                                <span
                                    className="text-18 bold d-block"
                                    style={{
                                        borderBottom: '2px #CD0A0A solid',
                                        paddingBottom: 5,
                                    }}
                                >
                                    {item.name}
                                </span>
                            </div>
                            <Grid hover striped data={item} pagination={false}>
                                <TableHeaderColumn
                                    isKey
                                    dataField="part"
                                    tdStyle={{ filter: 'none' }}
                                    width="80px"
                                    dataFormat={(cell, row) => (
                                        <img
                                            src={cell || defaultLogo}
                                            height="30"
                                        />
                                    )}
                                >
                                    PARTE
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="name" dataSort>
                                    NOMBRE
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="description"
                                    dataSort
                                >
                                    DESCRIPCIÓN
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="time"
                                    dataSort
                                    width="90px"
                                >
                                    TIEMPO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="status"
                                    dataSort
                                    width="120px"
                                    dataFormat={(cell, row) =>
                                        cell == 40 ? (
                                            'Finalizado'
                                        ) : (
                                            <span className="rojo">
                                                {' '}
                                                No Finalizado{' '}
                                            </span>
                                        )
                                    }
                                >
                                    ESTADO
                                </TableHeaderColumn>
                            </Grid>
                        </Fragment>
                    ))}

                <ButtonSalirGuardar
                    link="/mywork-orders"
                    btnText1="Salir"
                    btnText2="Finalizar tareas"
                    link2={this.finalizarFlujo.bind(
                        this,
                        status_in_action.play
                    )}
                />
            </Fragment>
        );
    }
}
