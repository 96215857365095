import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm, change, destroy } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";


const LOADER = 'LOADER_MECANICA';
const DATA = 'DATA_MECANICA';
const DATA_TASK = 'DATA_TASK_MECANICA';
const ITEM_DATA = 'ITEM_MECANICA';
const PAGE = 'PAGE_MECANICA';
const ORDERING = 'ORDERING_MECANICA';
const SEARCH = 'SEARCH_MECANICA';
// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});
const setDataTask = data_task => ({
    type: DATA_TASK,
    data_task,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const finalizarFlujo = (idWorkOrder) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`my_orders_mechanic/${idWorkOrder}/next_flow`).then(() => {
        NotificationManager.success('Flujo finalizado correctamente', 'Éxito', 3000);
        dispatch(push('/mywork-orders'));
    }).catch((error) => {
        let mensaje = 'Error al finalizar el flujo, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const rechazarOrdenMecanica = (idWorkOrder) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`my_orders_mechanic/${idWorkOrder}/reject`).then(() => {
        NotificationManager.success('Orden rechazada correctamente', 'Éxito', 3000);
        dispatch(push('/mywork-orders'));
    }).catch((error) => {
        let mensaje = 'Error al rechazar la orden, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const rechazarTarea = (idWorkOrder, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`my_orders_mechanic/rejectTask`, { idWorkOrder, id }).then(() => {
        NotificationManager.success('Tarea rechazada correctamente', 'Éxito', 3000);
        dispatch(listarTask(idWorkOrder))
    }).catch((error) => {
        let mensaje = 'Error al rechazar la tarea, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
        dispatch(setLoader(false));
    })
}

const aprobarTarea = (idWorkOrder, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`my_orders_mechanic/approveTask`, { idWorkOrder, id }).then(() => {
        NotificationManager.success('Tarea aprobada correctamente', 'Éxito', 3000);
        dispatch(listarTask(idWorkOrder))
    }).catch((error) => {
        let mensaje = 'Error al aprobar la tarea, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
        dispatch(setLoader(false));
    })
}

const eliminarTarea = (idWorkOrder, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`my_orders_mechanic/deleteTask`, { idWorkOrder, id }).then(() => {
        NotificationManager.success('Tarea aprobada correctamente', 'Éxito', 3000);
        dispatch(listarTask(idWorkOrder))
    }).catch((error) => {
        let mensaje = 'Error al eliminar la tarea, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
        dispatch(setLoader(false));
    })
}

// Metod para actulizar las observaciones de los trabajos en proceso
const actualizarTask = (data, idWorkOrder) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('my_orders_mechanic/update_tasks', { data, idWorkOrder}).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
    }).catch((error) => {
        let mensaje = 'Error en la creación, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        dispatch(listarTask(idWorkOrder))
    });
}

const listarTask = (idWorkOrder) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`my_orders_mechanic/${idWorkOrder}/list_tasks`).then((response) => {
        dispatch(initializeForm('DiagnosticoMecanicaForm', {}))
        dispatch(setDataTask(response));
    }).catch((e) => {
        console.log("el error", e)
        dispatch(push('/mywork-orders'));
        if(e && e.handled === true) {
            let mensaje = 'Error al consultar, vuelve a intentarlo';
            if (e.detail)
                mensaje = e.detail;
            NotificationManager.info(mensaje, 'Info', 3000)
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const accionTask = (idWorkOrder, idTask, action_tracking, diagnostic = false) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`my_orders_mechanic/${idWorkOrder}/action_tracking_task`, { idTask, action_tracking }).then(() => {
        let texto = ''
        if (action_tracking == 'play')
            texto = "Tarea iniciada correctamente"
        if (action_tracking == 'pause')
            texto = "Tarea pausada correctamente"
        if (action_tracking == 'finalized')
            texto = "Tarea finalizada correctamente"
        NotificationManager.success(texto, 'Éxito', 3000);
    }).catch((error) => {
        let mensaje = ''
        console.log("action_tracking", action_tracking)
        if (action_tracking == 'play')
            mensaje = "Error al iniciar la tarea, vuelve a intentarlo"
        if (action_tracking == 'pause')
            mensaje = "Error al pausar la tarea, vuelve a intentarlo"
        if (action_tracking == 'finalized')
            texto = "Error al finalizar la tarea, vuelve a intentarlo"
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        if (diagnostic) {
            dispatch(leer(idWorkOrder, true))
        } else {
            dispatch(listarTask(idWorkOrder))
        }
    });
}

const leer = (id, diagnostic = false) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`my_orders_mechanic/${id}`).then((response) => {
        if (!diagnostic) {
            dispatch(initializeForm('DiagnosticoMecanicaForm', response));
        }
        dispatch(setItem(response));

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const pauseTimer = (id) => (dispatch) => {
    api.put(`my_orders_mechanic/${id}/pauseTimer`).then((response) => {
    }).catch(() => {
    }).finally(() => {
    });
};

const crear = (id, data, attachments = [], isQA=false, isCrear=false) => (dispatch) => {
    if (data.tasks.length === 0 && isCrear !== true){
        let mensaje = 'La orden de diagnóstico no puede ir en blanco';
        NotificationManager.error(mensaje, 'ERROR', 3000);
        return
    }
    dispatch(setLoader(true));
    api.postAttachments(`my_orders_mechanic/${id}/diagnostic`, data, attachments).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        // dispatch(destroyForm());
        if(isQA !== true) {
            isCrear !== true && dispatch(push('/mywork-orders'));
        } else {
            dispatch(initializeForm('DiagnosticoMecanicaForm', {}))
            dispatch(listarTask(id))
        }
    }).catch((error) => {
        let mensaje = 'Error en la creación, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    leer,
    crear,
    actualizarTask,
    pauseTimer,
    listarTask,
    accionTask,
    aprobarTarea,
    eliminarTarea,
    rechazarTarea,
    finalizarFlujo,
    rechazarOrdenMecanica,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_TASK]: (state, { data_task }) => {
        return {
            ...state,
            data_task,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data_task: {
        results: [],
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
