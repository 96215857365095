import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Formulario from './Formulario';

export default class Crear extends Component {
    state = {
        editar: false,
    };

    componentWillMount() {
        if (this.props.match.params.id) {
            this.props.leer(this.props.match.params.id);
            this.setState({ editar: true });
        }
    }
    actualizar = (data) => {
        this.props.editar(this.props.match.params.id, data)
    };

    render() {
        //Function
        const { crear } = this.props;
        //Value
        const { loader, item, me } = this.props;
        const {
            permissions_role = {}
        } = me || {}
        const { editar } = this.state
        return (
            <CardForm titulo={editar ? "EDITAR ROL" : "NUEVO ROL"}>
                <LoadMask loading={loader} blur>
                    {
                        editar ?
                            <Formulario
                                onSubmit={this.actualizar}
                                item={item}
                                permissions_role={permissions_role}
                            />
                            :
                            <Formulario onSubmit={crear}
                                permissions_role={permissions_role} />
                    }
                </LoadMask>
            </CardForm>
        )
    }
}
